import clsx from 'clsx'
import { Link } from 'react-router-dom'

type CustomLinkProps = {
	href: string
	text?: string
	children?: React.ReactNode
	className?: string
}

export const CustomLink: React.FC<CustomLinkProps> = ({ href, text, children, className }) => {
	return (
		<Link
			to={href}
			className={clsx(
				'font-semibold text-indigo-600 hover:text-indigo-500 hover:underline',
				className
			)}>
			{text}
			{children}
		</Link>
	)
}
