import { useEffect, useState } from 'react'
import { useAccount, useBalance } from 'wagmi'
import { writeContract, prepareWriteContract, fetchBalance } from '@wagmi/core'
import { getValidators, sendBatchValidatorCredentials } from 'services/validator-service'

import RainbowConnectBtn from './RainbowConnectBtn'

import {
	GeneratorState,
	generateValidatorKeys,
	ValidatorKeys,
	DepositResult,
	ETH_CHAIN,
	DEPOSIT_AMOUNT_WEI,
	computeDepositArgs
} from 'utils/validators'
import CompletedStepGreen from 'assets/icons/svg/completed-step-green'
import clsx from 'clsx'
import { Spinner } from './animations/spinner'
import { NETWORKS } from 'utils/networks'
import { ETH_DEPOSIT_ABI } from 'utils/ethDeposit'

const FIRST_STEP = 'allow' as const

export type StepState =
	| 'allow'
	| GeneratorState
	| 'depositing'
	| 'transaction_rejected'
	| 'balance_too_low'
	| 'unknown_error'

export type Step = {
	id: StepState
	content: JSX.Element
	action?: JSX.Element
}

type TimelineProps = {
	timeline: Step[]
	current: StepState
	restart: () => void
}

const errorStates = ['transaction_rejected', 'balance_too_low', 'unknown_error']

export function Timeline({ timeline, current, restart }: TimelineProps) {
	let currentIdx = timeline.findIndex(step => step.id == current)

	const renderErrorStates = (state: StepState) => {
		if (state == 'transaction_rejected')
			return (
				<div className="align-center flex flex-row justify-between">
					<p className="flex flex-col items-center justify-center text-lg text-red-800">
						Transaction Rejected (Are you using a hardware wallet with Metamask?)
					</p>
					<button onClick={restart} className="text-md font-semibold text-[#6340DE]">
						Restart
					</button>
				</div>
			)

		if (state == 'balance_too_low')
			return (
				<div className="align-center flex flex-row justify-between">
					<p className="flex flex-col items-center justify-center text-lg text-red-800">
						Seems like your ETH balance is too low. Please, verify that you have at least 32 ETH on
						Mainnet chain and try again
					</p>
					<button onClick={restart} className="text-md font-semibold text-[#6340DE]">
						Restart
					</button>
				</div>
			)

		if (state == 'unknown_error')
			return (
				<div className="align-center flex flex-row justify-between">
					<p className="flex flex-col items-center justify-center text-lg text-red-800">
						Our apologies, something went wrong. Please, try again later or contact us on{' '}
						<a
							href="https://discord.gg/tBQXAEYs6b"
							className="underline"
							target="_blank"
							rel="noreferrer"
						>
							our Discord
						</a>{' '}
					</p>
					<button onClick={restart} className="text-md font-semibold text-[#6340DE]">
						Restart
					</button>
				</div>
			)

		return null
	}

	return (
		<div className="w-full">
			{errorStates.includes(current) ? (
				renderErrorStates(current)
			) : (
				<ul role="list" className="space-y-4">
					{timeline
						.filter((_, i) => i <= currentIdx)
						.map((step, idx) => {
							let isComplete = idx < currentIdx || currentIdx == timeline.length - 1
							return (
								<li
									key={step.id}
									className={clsx(
										'flex items-center justify-between  px-4 py-2',
										isComplete && 'bg-[#F9FAFB]'
									)}
								>
									<div className="flex min-w-0 flex-1 justify-start">{step.content}</div>
									{isComplete ? (
										<CompletedStepGreen className="ml-3 h-6 w-6 text-white" aria-hidden="true" />
									) : (
										step.action || null
									)}
								</li>
							)
						})}
				</ul>
			)}
		</div>
	)
}

export function validatorStatusLink(publicKey: string) {
	let explorer = NETWORKS[ETH_CHAIN].beaconExplorerUrl
	let url = `${explorer}/validator/${publicKey}#deposits`
	return (
		<a className="font-medium underline" href={url} target="_blank" rel="noreferrer">
			Explorer Link
		</a>
	)
}

async function preflightAction(depositResult: DepositResult): Promise<boolean> {
	let validators = []
	for (let keySet of depositResult.values()) {
		validators.push({
			projectName: 'ethereum' as const,
			validatorCredentials: JSON.stringify(keySet.keystore),
			address: keySet.address
		})
	}

	try {
		await sendBatchValidatorCredentials({ validators })
		return true
	} catch (error) {
		console.error('fucked up sending data to the API', error)
		return false
	}
}

type EthereumDeploymentFlowProps = {
	onStateChange?: (state: StepState) => void
	withEndAction?: boolean
	onQuantityChangeCallback?: (n: number) => void
}

export default function EthereumDeploymentFlow({
	onStateChange,
	withEndAction
}: EthereumDeploymentFlowProps) {
	const { address, isConnected } = useAccount()
	let startingStep: StepState = isConnected ? 'init' : FIRST_STEP

	// only one validator at a time for now
	const quantity = 1 as const
	const [onboardingState, setOnboardingState] = useState<StepState>(startingStep)
	const [progressCount, setProgressCount] = useState<number>(0)
	const [depositPubkeys, setDepositPubkeys] = useState<string[]>([])

	const setState = (state: StepState) => {
		setOnboardingState(state)
		onStateChange?.(state)
	}

	function restart() {
		setState(startingStep)
		setProgressCount(0)
		setDepositPubkeys([])
	}

	useEffect(() => {
		if (!isConnected) {
			setOnboardingState(FIRST_STEP)
			return
		}
		if (isConnected && onboardingState == 'allow') {
			setState('init')
		}
	}, [isConnected, onboardingState])

	let chain = ETH_CHAIN
	let network = NETWORKS[chain]

	async function onClick() {
		let keys: ValidatorKeys[] = []

		if (!address) {
			throw new Error('no available address')
		}

		/* Generating validator keys */
		for await (let { state, storeCount, data, error } of generateValidatorKeys(
			quantity,
			ETH_CHAIN,
			address
		)) {
			if (storeCount) {
				setProgressCount(storeCount)
			}

			if (state != 'done') {
				setState(state)
				continue
			}

			if (!data || error) {
				setState('transaction_rejected')
				return
			}

			keys = data
		}

		if (!keys.length) {
			throw new Error('no keys')
		}

		/* Session check */
		try {
			// check the session before we do anything
			// TODO: replace with an actual session check?
			await getValidators().then(_ => console.log('session ok'))
		} catch (error) {
			console.error('failed to get validators', error)
			return
		}

		/* Deposit */
		setState('depositing')

		try {
			let keySet = keys[0]
			let balance = await fetchBalance({ address })

			if (!balance || balance.value < DEPOSIT_AMOUNT_WEI) {
				throw new Error('balance too low')
			}

			let depositArgs = computeDepositArgs(keySet)
			let depositResult: DepositResult = new Map()
			depositResult.set(depositArgs.pubkey, keySet)

			let ok = await preflightAction(depositResult)
			if (!ok) {
				throw new Error('preflight check failed')
			}

			await prepareWriteContract({
				address: network.addresses.deposit,
				abi: ETH_DEPOSIT_ABI,
				args: [
					depositArgs.pubkey,
					depositArgs.withdrawalCredentials,
					depositArgs.signature,
					depositArgs.depositDataRoot
				],
				value: DEPOSIT_AMOUNT_WEI,
				functionName: 'deposit'
			})
				.then(({ request }) => writeContract(request))
				.then(({ hash }) => {
					console.log('deposit tx hash', hash)
					setDepositPubkeys(Array.from(depositResult.keys()))
					console.log(depositResult.keys())
					setState('done')
				})
		} catch (error: any) {
			console.error('error in the deposit', error)
			if (error?.message == 'balance too low') {
				return setState('balance_too_low')
			}
			if (error?.code == 'ACTION_REJECTED') {
				return setState('transaction_rejected')
			}
			setState('unknown_error')
		}
	}

	const AwaitingAction = () => (
		<p className="text-center text-xs font-semibold text-[#6340DE]">
			Awaiting <br />
			Metamask
		</p>
	)

	const CountProgressBar = ({ count: keystoreCount }: { count: number }) => {
		let circles = Array.from({ length: keystoreCount }, (_, i) => (
			<span className="block h-2.5 w-2.5 rounded-full bg-indigo-600 hover:bg-indigo-900" key={i} />
		))

		return (
			<div>
				<p>
					Key stores generated: <b className="font-medium">{keystoreCount}</b>
				</p>
				<div className="flex space-x-1">{circles}</div>
			</div>
		)
	}

	const steps: Step[] = [
		{
			id: 'allow',
			content: (
				<div className="flex flex-col">
					{isConnected ? <RainbowConnectBtn /> : <p>Please, connect your wallet</p>}
				</div>
			),
			action: !isConnected ? <RainbowConnectBtn /> : undefined
		},
		{
			id: 'init',
			content: <p>Let's start generating your validator keys!</p>,
			action: (
				<button onClick={onClick} className="text-xs font-semibold text-[#6340DE]">
					Start
				</button>
			)
		},
		{
			id: 'switching_chain',
			content: <p>Switching to Ethereum Mainnet</p>,
			action: <AwaitingAction />
		},
		{
			id: 'encrypting',
			content: (
				<>
					<p>
						We will now generate your validator keys and encrypt them with your Metamask wallet.
						Please click the Provide button to proceed.
					</p>
				</>
			),
			action: <AwaitingAction />
		},
		{
			id: 'generating_keystore',
			content: (
				<div className="flex flex-col">
					<p>
						Generating key store for each validator. This might take up to a few minutes, depending
						of the deposit amount.
					</p>
					<CountProgressBar count={progressCount} />
				</div>
			),
			action: <Spinner className="h-5 w-5 text-[#6340DE]" />
		},
		{
			id: 'depositing',
			content: <p>Please, confirm the deposit transaction(s) in your Metamask wallet.</p>,
			action: <AwaitingAction />
		},
		{
			id: 'done',
			content: (
				<div>
					Transaction has been successfully submitted. Please, allow a couple of minutes for
					processing. After this, you will be able to see your validators here:
					<br />
					<ol className="ml-6 list-decimal">
						{depositPubkeys.map(pubkey => (
							<li key={pubkey}>{validatorStatusLink(pubkey)}</li>
						))}
					</ol>
				</div>
			),
			action: (
				<button onClick={restart} className="text-md font-semibold text-[#6340DE]">
					Restart
				</button>
			)
		}
	]

	return (
		<div>
			<Timeline restart={restart} timeline={steps} current={onboardingState} />
			{onboardingState == 'done' && withEndAction && (
				<button
					onClick={restart}
					className="text-md my-5 w-full text-center font-semibold text-[#6340DE]"
				>
					Restart
				</button>
			)}
		</div>
	)
}
