import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { Fragment, useState } from 'react'

import { PricePredictions } from 'constants/streamr'

interface PricePredictionsDropdownProps {
	onPredictionSelected: (prediction: number) => void
}

export const PricePredictionsDropdown = ({
	onPredictionSelected
}: PricePredictionsDropdownProps) => {
	const [selected, setSelected] = useState(PricePredictions[2])

	const onPredictionChange = (prediction: typeof selected) => {
		setSelected(prediction)
		onPredictionSelected(prediction.multiplier)
	}

	return (
		<Listbox value={selected} onChange={onPredictionChange}>
			{({ open }) => (
				<div className="relative">
					<Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:leading-6">
						<span className="flex items-center">
							<span className="ml-3 block truncate font-medium">{selected.title}</span>
						</span>
						<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
							<ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
						</span>
					</Listbox.Button>

					<Transition
						show={open}
						as={Fragment}
						leave="transition ease-in duration-100"
						leaveFrom="opacity-100"
						leaveTo="opacity-0">
						<Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
							{PricePredictions.map(prediction => (
								<Listbox.Option
									key={prediction.id}
									className={({ active }) =>
										clsx(
											active ? 'bg-indigo-600 text-white' : 'text-gray-900',
											'relative cursor-default select-none py-2 pl-3 pr-9'
										)
									}
									value={prediction}>
									{({ selected, active }) => (
										<>
											<div className="flex items-center">
												<span
													className={clsx(
														selected ? 'font-medium' : 'font-normal',
														'ml-3 block truncate'
													)}>
													{prediction.title}
												</span>
											</div>

											{selected ? (
												<span
													className={clsx(
														active ? 'text-white' : 'text-indigo-600',
														'absolute inset-y-0 right-0 flex items-center pr-4'
													)}>
													<CheckIcon className="h-5 w-5" aria-hidden="true" />
												</span>
											) : null}
										</>
									)}
								</Listbox.Option>
							))}
						</Listbox.Options>
					</Transition>
				</div>
			)}
		</Listbox>
	)
}
