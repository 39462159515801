import { Spinner } from 'components/animations/spinner'
import { useAppDispatch } from 'hooks'
import { useEffect, useState } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import { refreshUser } from 'slices/auth'

import NodeService from 'services/node-service'
import BillingService from 'services/billing-service'
import toast from 'react-hot-toast'
import { parseErrorResponse } from 'services/axios'

const BillingSuccess = () => {
	const [done, setDone] = useState(false)
	const dispatch = useAppDispatch()
	const [searchParams] = useSearchParams()

	const setupIntent = searchParams.get('setup_intent')
	const wallets = searchParams.get('wallets')
	const project = searchParams.get('project')

	useEffect(() => {
		// clean up from the previous onboarding steps
		window.localStorage.removeItem('wallets')
	}, [])

	useEffect(() => {
		if (!setupIntent) return

		async function doThings() {
			let triedBefore = window.localStorage.getItem('onboarding_node_deployment')
			if (!wallets || !project || triedBefore) {
				setDone(true)
				return console.log('skipping node deployment')
			}

			let addresses = wallets.split(',')

			// prevents duplicate node deployments
			window.localStorage.setItem('onboarding_node_deployment', 'triggered')

			await BillingService.updateCustomer(setupIntent as string)
			await dispatch(refreshUser())

			await NodeService.deployNode(project, addresses).catch(error => {
				let errorMessage = parseErrorResponse(error)
				toast.error(errorMessage)
			})

			setDone(true)
		}

		doThings().catch(console.error)
	}, [])

	if (done) return <Navigate to="/dashboard?from_onboarding=1" />

	return (
		<div className="flex h-96 flex-row items-center justify-center">
			<Spinner className="h-8 w-8" />
		</div>
	)
}

export { BillingSuccess }
