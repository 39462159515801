import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Spinner } from 'components/animations/spinner'
import { StripeSetupForm } from 'components/stripe/setup-form'
import { useEffect, useRef, useState } from 'react'
import { useSearchParams, Navigate } from 'react-router-dom'

import BillingService from 'services/billing-service'
import { NextButton } from './next-button'
const stripePromise = loadStripe(window.stripePK ?? 'pk_test')

const Billing = () => {
	const [isLoading, setIsLoading] = useState(true)
	const [clientSecret, setClientSecret] = useState('')

	let [query] = useSearchParams()
	let wallets = query.get('wallets') ?? undefined
	let project = query.get('project') ?? undefined
	let formRef = useRef(null)

	if (project != 'gnosis') {
		if (!wallets || !project)
			return <Navigate to="/onboarding/choose-project" />
	}

	const triggerBillingSubmit = () => {
		let form: any = formRef.current
		form?.requestSubmit()
	}

	const createCustomerAndSetupIntent = async () => {
		const { client_secret } = await BillingService.setup()
		setClientSecret(client_secret)
	}

	useEffect(() => {
		createCustomerAndSetupIntent().then(() => setIsLoading(false))
	}, [])

	useEffect(() => {
		if (wallets) {
			window.localStorage.setItem('wallets', wallets)
		}
	}, [wallets])

	if (isLoading)
		return (
			<div className="flex h-96 flex-row items-center justify-center">
				<Spinner className="h-8 w-8" />
			</div>
		)

	let options = { clientSecret }

	return (
		<div className="relative">
			<div className="onboarding-step lg:h-[612px] max-w-5xl lg:overflow-scroll lg:rounded-xl lg:border bg-white pb-20 ">
				<div className="max-w-xl px-4 pt-4 lg:px-12 lg:pt-12">
					<h3 className="text-2xl hidden lg:block">Add billing address</h3>
					<p className="mt-2 mb-8 text-sm">
						Please confirm your billing information to activate your node(s).
					</p>
					<Elements stripe={stripePromise} options={options}>
						<StripeSetupForm
							project={project}
							customSubmitButton={<div></div>}
							ref={formRef}
							wallets={wallets}
							returnUrl={`/onboarding/${project.toLowerCase()}/billing-success`}
						/>
					</Elements>
					<NextButton text="Submit" moveOn={triggerBillingSubmit} />
				</div>
			</div>
		</div>
	)
}

export { Billing }
