import { useRef } from 'react'
import { useSteps } from 'pages/onboarding'
import { NextButton } from '../next-button'
import { NodeDeployment } from 'components/node-deployment'

function StartEarning() {
	const { moveOn } = useSteps()
	let formRef = useRef(null)

	const triggerDeploy = () => {
		let form: any = formRef.current
		form?.requestSubmit()
	}

	const moveOnToBilling = (wallets: string[]) => {
		let project = 'Streamr'
		let query = `wallets=${wallets.join(',')}&project=${project}`
		moveOn(query)
	}

	return (
		<div className="relative">
			<div className="onboarding-step lg:h-[612px] max-w-5xl lg:overflow-scroll lg:rounded-xl lg:border bg-white pb-20 ">
				<div className="max-w-xl px-4 pt-4 lg:pl-12 lg:pt-12">
					<h3 className="mb-6 text-2xl hidden lg:block">Start Earning</h3>
					<h4 className="lg:mt-6 text-sm font-semibold">Setting up nodes on zonaris:</h4>
					<p className="mt-1 text-sm font-normal leading-6">
						Create the correct number of accounts in one MetaMask wallet and label each account by
						number (e.g. zonaris 1, zonaris 2, etc).
					</p>
					<p className="mt-4 text-sm leading-6">
						You can deploy up to 20 wallet addresses at a time by pasting the public wallet address
						into the designated wallet address boxes, then start earning rewards within minutes.
					</p>
					<h4 className="mt-6 text-sm font-semibold leading-6">Wallet address</h4>
					<p className="mt-1 mb-6 text-xs leading-6 text-light-gray">
						Use a wallet compatible with Polygon (MetaMask, Ledger etc). Move DATA holdings from
						other chains (e.g. Ethereum) to Polygon Mainnet and deposit to your node wallet/account
						address.
					</p>
					<NodeDeployment
						onSubmit={moveOnToBilling}
						ref={formRef}
						customDeployButton={<div></div>}
					/>
				</div>
			</div>
			<NextButton text="Deploy node" moveOn={triggerDeploy} />
		</div>
	)
}

export { StartEarning }
