import { Buffer } from 'buffer'
import { holesky, gnosis, gnosisChiado, mainnet } from 'viem/chains'
import { switchNetwork, getNetwork } from '@wagmi/core'
import { ethers } from 'ethers'

import {
	computeDepositDataRoot,
	computeDepositSigningRoot,
	computeDomain,
	DepositDataType,
	DepositMessageType,
	ENCRYPTION_PATH,
	ETH1_ADDRESS_WITHDRAWAL_PREFIX,
	FORKS
} from './ssz'

import { Keystore } from '@chainsafe/bls-keystore'
import bls from '@chainsafe/bls/herumi'
import { SupportedNetworks } from './networks'

export const MAX_DEPOSIT_LIMIT = 40 as const // arbitrary; gnosis contract supports up to 128

import {
	generateRandomSecretKey,
	deriveKeyFromEntropy,
	deriveEth2ValidatorKeys,
	IEth2ValidatorKeys
} from '@chainsafe/bls-keygen'

export const DEPOSIT_AMOUNT_GNO_GWEI = 1000000000000000000n
const DEPOSIT_AMOUNT_mGNO = 32000000000

export const DEPOSIT_AMOUNT_WEI = 32000000000000000000n
export const DEPOSIT_AMOUNT_GWEI = 32000000000

async function switchChain(project: SupportedNetworks) {
	let chainId = 0
	switch (project) {
		case 'ethereum':
			chainId = mainnet.id
			break
		case 'gnosis':
			chainId = gnosis.id
			break
		case 'chiado':
			chainId = gnosisChiado.id
			break
		case 'holesky':
			chainId = holesky.id
			break
		default:
			throw new Error('invalid project')
	}

	let { chain } = getNetwork()
	if (chain?.id === chainId) {
		return
	}
	return switchNetwork({ chainId })
}

export type ValidatorKeys = {
	signingKey: Uint8Array
	withdrawalKey: Uint8Array
	keystore: Keystore
	address: string
}

export type GeneratorState =
	| 'init'
	| 'switching_chain'
	| 'requesting_account'
	| 'encrypting'
	| 'generating_keystore'
	| 'done'

type GeneratorReturnValue = {
	state: GeneratorState
	storeCount?: number
	error?: any
	data?: ValidatorKeys[]
}

export async function* generateValidatorKeys(
	quantity: number = 1,
	network: SupportedNetworks,
	address: `0x${string}`
): AsyncGenerator<GeneratorReturnValue> {
	if (quantity > MAX_DEPOSIT_LIMIT)
		throw new Error(`quantity more than ${MAX_DEPOSIT_LIMIT} is not supported`)

	yield { state: 'init' }

	let secretKey = generateRandomSecretKey()

	yield { state: 'switching_chain' }

	await switchChain(network)

	yield { state: 'encrypting' }

	let keys: IEth2ValidatorKeys[] = []
	let path = ENCRYPTION_PATH

	for (let i = 0; i < quantity; i++) {
		// secret key from entropy and optional EIP-2334 path
		let masterSecretKey = deriveKeyFromEntropy(secretKey)
		let keySet = deriveEth2ValidatorKeys(masterSecretKey, i)
		keys.push(keySet)
	}

	let error
	let validatorKeys: ValidatorKeys[] = []

	yield { state: 'generating_keystore', storeCount: 0 }

	for (let keySet of keys) {
		let signingPK = keySet.signing
		let signingPublicKey = bls.secretKeyToPublicKey(signingPK)
		let keystore: Keystore = await Keystore.create('potatoearth', signingPK, signingPublicKey, path)

		validatorKeys.push({
			withdrawalKey: keySet.withdrawal,
			signingKey: keySet.signing,
			keystore,
			address
		})

		yield { state: 'generating_keystore', storeCount: validatorKeys.length }
	}

	yield {
		state: 'done',
		error,
		data: validatorKeys
	}
}

type PublicKey = string

export type DepositResult = Map<PublicKey, ValidatorKeys>

// toggle between 'gnosis' and 'chiado' here
export const GNOSIS_CHAIN = 'gnosis' as const

// toggle between 'ethereum' and 'holesky' here
export const ETH_CHAIN = 'ethereum' as const

export type DepositArgs = {
	pubkey: string
	withdrawalCredentials: string
	signature: string
	depositDataRoot: string
}

export function computeDepositArgs(keySet: ValidatorKeys): DepositArgs {
	let chain = ETH_CHAIN
	let version = FORKS[chain]
	let { address } = keySet

	let withdrawalCredentials = Buffer.concat([
		ETH1_ADDRESS_WITHDRAWAL_PREFIX,
		Buffer.alloc(11),
		ethers.getBytes(address)
	])

	let result: DepositResult = new Map()
	let { signingKey, withdrawalKey: _ignore } = keySet
	let publicKey = bls.secretKeyToPublicKey(signingKey)

	if (publicKey.length !== 48) {
		throw new Error('invalid public key length')
	}

	result.set(Buffer.from(publicKey).toString('hex'), keySet)

	// deposit message w/o signature
	const depositMessage: DepositMessageType = {
		pubkey: Buffer.from(publicKey),
		withdrawalCredentials,
		amount: DEPOSIT_AMOUNT_GWEI
	}

	// compute signing root on message w/o signature
	let domain = computeDomain(version)
	let signingRoot = computeDepositSigningRoot(depositMessage, domain)

	// sign
	let signature = bls.sign(signingKey, signingRoot)
	let depositData: DepositDataType = {
		...depositMessage,
		signature
	}

	// compute root on data with signature
	let depositDataRoot = computeDepositDataRoot(depositData)

	return {
		pubkey: '0x' + Buffer.from(depositData.pubkey).toString('hex'),
		withdrawalCredentials: '0x' + Buffer.from(depositData.withdrawalCredentials).toString('hex'),
		signature: '0x' + Buffer.from(depositData.signature).toString('hex'),
		depositDataRoot: '0x' + Buffer.from(depositDataRoot).toString('hex')
	}
}

type GNOResult = {
	data: string
	result: DepositResult
}

export async function computeDepositArgsGNO(keys: ValidatorKeys[]): Promise<GNOResult> {
	let chain = GNOSIS_CHAIN
	let version = FORKS[chain]
	let { address } = keys[0]

	// NOTE: withdrawal to eth1 address
	let withdrawalCredentials = Buffer.concat([
		ETH1_ADDRESS_WITHDRAWAL_PREFIX,
		Buffer.alloc(11),
		ethers.getBytes(address)
	])

	let result: DepositResult = new Map()
	let domain = computeDomain(version)

	let data = '0x'
	data += Buffer.from(withdrawalCredentials).toString('hex')

	for (let keySet of keys) {
		let { signingKey } = keySet
		let publicKey = bls.secretKeyToPublicKey(signingKey)

		if (publicKey.length !== 48) {
			throw new Error('Invalid public key length')
		}

		result.set(Buffer.from(publicKey).toString('hex'), keySet)

		// deposit message w/o signature
		let depositMessage: DepositMessageType = {
			pubkey: Buffer.from(publicKey),
			withdrawalCredentials: withdrawalCredentials,
			amount: DEPOSIT_AMOUNT_mGNO
		}

		// compute signing root on message w/o signature
		let signingRoot = computeDepositSigningRoot(depositMessage, domain)

		// sign
		let signature = bls.sign(signingKey, signingRoot)
		let depositData: DepositDataType = {
			...depositMessage,
			signature
		}

		// compute root on data with signature
		let depositDataRoot = computeDepositDataRoot(depositData)

		data += Buffer.from(publicKey).toString('hex')
		data += Buffer.from(depositData.signature).toString('hex')
		data += Buffer.from(depositDataRoot).toString('hex')
	}

	return {
		data,
		result
	}
}
