import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { Fragment, useState } from 'react'

import { AvailableCoins, CoinType } from 'constants/streamr'

interface CoinsDropdownProps {
	onCoinSelected: (coin: CoinType) => void
}

export const CoinsDropdown = ({ onCoinSelected }: CoinsDropdownProps) => {
	const [selected, setSelected] = useState(AvailableCoins[0])

	const onCoinChange = (coin: typeof selected) => {
		setSelected(coin)
		onCoinSelected(coin)
	}

	return (
		<Listbox value={selected} onChange={onCoinChange}>
			{({ open }) => (
				<>
					<div className="relative w-1/2 rounded-md">
						<Listbox.Button className="flex w-full cursor-pointer items-center justify-between rounded-md border-transparent px-4 py-2 text-left text-sm text-gray-900  ring-transparent focus:outline-none focus:ring-2 sm:text-sm sm:leading-6">
							<span className="flex items-center">
								<selected.logo className="h-5 w-5 flex-shrink-0 text-[#F65F0A]" />
								{/* <span className="ml-2 block truncate">{selected.name}</span> */}
							</span>
							<span className="flex items-center pl-2.5">
								<ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
							</span>
						</Listbox.Button>

						<Transition
							show={open}
							as={Fragment}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0">
							<Listbox.Options className="absolute z-10 mt-2 max-h-56 w-44 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
								{AvailableCoins.map(coin => (
									<Listbox.Option
										key={coin.id}
										className={({ active }) =>
											clsx(
												active ? 'bg-gray-200' : '',
												'relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900'
											)
										}
										value={coin}>
										{({ selected, active }) => (
											<>
												<div className="flex items-center">
													<coin.logo className="h-5 w-5 flex-shrink-0 text-[#F65F0A]" />
													<span
														className={clsx(
															selected ? 'font-medium' : 'font-normal',
															'ml-3 block truncate'
														)}>
														{coin.name}
													</span>
												</div>

												{selected ? (
													<span
														className={clsx(
															active ? 'text-white' : 'text-indigo-600',
															'absolute inset-y-0 right-0 flex items-center pr-4'
														)}>
														<CheckIcon className="h-5 w-5" aria-hidden="true" />
													</span>
												) : null}
											</>
										)}
									</Listbox.Option>
								))}
							</Listbox.Options>
						</Transition>
					</div>
				</>
			)}
		</Listbox>
	)
}
