type HazyProps = {
	modal?: boolean
}

export const HazyPurple = ({ modal }: HazyProps) => {
	let purpleStyles: any = {
		position: 'absolute',
		zIndex: -1,
		width: '688px',
		height: '530px',
		left: '-550px',
		top: '-30%',
		background: '#DACDFF', // purple
		filter: 'blur(81px)',
		transform: 'rotate(-29.79deg)'
	}
	let blueStyles: any = {
		zIndex: -1,
		position: 'absolute',
		width: '493px',
		height: '220px',
		left: '-444px',
		top: '10%',
		background: '#34ADEB', // blue
		filter: 'blur(100.5px)',
		transform: 'rotate(53.93deg)'
	}

	if (modal) {
		purpleStyles.top = '-65%'
		purpleStyles.left = '-490px'
		purpleStyles.filter = 'blur(58px)'

		blueStyles.top = '-35%'
		blueStyles.filter = 'blur(71px)'
		blueStyles.left = '-350px'
	}

	return (
		<>
			<div style={purpleStyles} />
			<div style={blueStyles} />
		</>
	)
}

export const HazyGreen = ({ modal }: HazyProps) => {
	let greenStyles: any = {
		position: 'fixed',
		zIndex: -1,
		width: '493px',
		height: '347px',
		right: '-380px',
		bottom: '10%',
		background: '#9FFFED', // green
		filter: 'blur(81px)'
	}

	let blueStyles: any = {
		position: 'fixed',
		zIndex: -1,
		width: '388px',
		height: '330px',
		right: '-240px',
		bottom: '-10%',
		background: '#9EB9FD', // blue
		filter: 'blur(81px)',
		transform: 'rotate(-82.36deg)'
	}

	if (modal) {
		greenStyles.top = '-65%'
		greenStyles.right = '-300px'
		greenStyles.bottom = 'unset'
		greenStyles.filter = 'blur(47px)'

		blueStyles.top = '-45%'
		blueStyles.right = '-280px'
		blueStyles.bottom = 'unset'
		blueStyles.filter = 'blur(47px)'
	}

	return (
		<>
			<div style={greenStyles} />
			<div style={blueStyles} />
		</>
	)
}
