import clsx from 'clsx'
import { SupportedProjects } from '../../constants/index'
import { getIndexForCount, prices } from 'utils/prices'
import GnosisPromoBannerSVG from './PromoBanner'

type NodePriceProps = {
	nodeCount: number
	existingNodeCount?: number
	project?: SupportedProjects
}

export const NodePrice = ({ project, nodeCount, existingNodeCount = 0 }: NodePriceProps) => {
	let highlightedIndex = getIndexForCount(project || 'streamr', nodeCount)
	let nodePrices = prices[project || 'streamr']
	let costPerNode = Number(nodePrices[highlightedIndex].price)

	if (project == 'gnosis') {
		return (
			<div className="flow-root ">
				<div className="flex items-center justify-between">
					<div className="space-y-1">
						<h2 className="text-base font-medium lg:text-xl">Node Pricing</h2>
						<p className="text-gray-500">Monthly cost for running nodes</p>
					</div>
				</div>
				<div className="my-2 -mx-4 mt-11 overflow-x-auto rounded-md border  border border-gray-300 sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
						<table className="min-w-full divide-y divide-gray-300">
							<thead>
								<tr>
									<th
										scope="col"
										className="py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-500 sm:pl-0"
									>
										Nodes
									</th>
									<th
										scope="col"
										className="py-3.5 px-3 text-right text-sm font-medium text-gray-500"
									>
										Monthly cost(per node)
									</th>
								</tr>
							</thead>
							<tbody className="divide-y divide-gray-200">
								{nodePrices.map((np, index) => (
									<tr
										key={np.range}
										className={clsx('text-gray-500', {
											'font-medium text-gray-900': index === highlightedIndex
										})}
									>
										<td className={'whitespace-nowrap py-4 pl-4 pr-3 text-sm  sm:pl-0'}>
											{np.range}
										</td>
										<td className="whitespace-nowrap py-4 px-3 text-right text-sm">
											{'€' + np.price}
										</td>
									</tr>
								))}
							</tbody>
							<tfoot>
								<tr>
									<td className="whitespace-nowrap pt-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"></td>
								</tr>
							</tfoot>
						</table>
						<GnosisPromoBannerSVG />
						<p className="mt-4 text-sm font-light italic leading-6">
							Future pricing will adapt to market changes. Get started on the Gnosis Chain with our
							simple, non-custodial, 1-click solution and benefit from APR today!
						</p>
					</div>
				</div>
			</div>
		)
	}

	if (project == 'ethereum') {
		return (
			<div className="flow-root ">
				<div className="flex items-center justify-between">
					<div className="space-y-1">
						<h2 className="text-base font-medium lg:text-xl">Node Pricing</h2>
						<p className="text-gray-500">Monthly cost for running nodes</p>
					</div>
				</div>
				<div className="my-2 -mx-4 mt-11 overflow-x-auto rounded-md border  border border-gray-300 sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
						<table className="min-w-full divide-y divide-gray-300">
							<thead>
								<tr>
									<th
										scope="col"
										className="py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-500 sm:pl-0"
									>
										Nodes
									</th>
									<th
										scope="col"
										className="py-3.5 px-3 text-right text-sm font-medium text-gray-500"
									>
										Monthly cost(per node)
									</th>
								</tr>
							</thead>
							<tbody className="divide-y divide-gray-200">
								{nodePrices.map((np, index) => (
									<tr
										key={np.range}
										className={clsx('text-gray-500', {
											'font-medium text-gray-900': index === highlightedIndex
										})}
									>
										<td className={'whitespace-nowrap py-4 pl-4 pr-3 text-sm  sm:pl-0'}>
											{np.range}
										</td>
										<td className="whitespace-nowrap py-4 px-3 text-right text-sm">
											{'€' + np.price}
										</td>
									</tr>
								))}
							</tbody>
							<tfoot>
								<tr>
									<td className="whitespace-nowrap pt-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"></td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</div>
		)
	}

	return (
		<div className="flow-root">
			<div className="flex items-center">
				<div className="space-y-1">
					<h2 className="text-base font-medium lg:text-xl">Node Pricing</h2>
					<p className="text-gray-500">Monthly cost for running nodes</p>
				</div>
			</div>
			<div className="my-2 -mx-4 mt-11 overflow-x-auto rounded-md border  border border-gray-300 sm:-mx-6 lg:-mx-8">
				<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
					<table className="min-w-full divide-y divide-gray-300">
						<thead>
							<tr>
								<th
									scope="col"
									className="py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-500 sm:pl-0"
								>
									Nodes
								</th>
								<th
									scope="col"
									className="py-3.5 px-3 text-right text-sm font-medium text-gray-500"
								>
									Monthly cost(per node)
								</th>
							</tr>
						</thead>
						<tbody className="divide-y divide-gray-200">
							{nodePrices.map((np, index) => (
								<tr
									key={np.range}
									className={clsx('text-gray-500', {
										'font-medium text-gray-900': index === highlightedIndex
									})}
								>
									<td className={'whitespace-nowrap py-4 pl-4 pr-3 text-sm  sm:pl-0'}>
										{np.range}
									</td>
									<td className="whitespace-nowrap py-4 px-3 text-right text-sm">
										{'€' + np.price}
									</td>
								</tr>
							))}
						</tbody>
						<tfoot>
							{!!existingNodeCount && (
								<tr>
									<td className="whitespace-nowrap pt-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
										New nodes: {nodeCount - existingNodeCount}
									</td>
								</tr>
							)}
							<tr>
								<td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
									Total nodes: {nodeCount}
								</td>
								<td className="whitespace-nowrap py-4 pl-4 pr-3 text-right text-sm font-medium text-gray-900 sm:pl-0">
									{'€' + (nodeCount * costPerNode).toFixed(2)}
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
		</div>
	)
}
