export const getUser = () => {
	const user = localStorage.getItem('user')

	if (!user) {
		return undefined
	}

	return JSON.parse(user) as User
}

export const setUser = (user: User) => {
	return localStorage.setItem('user', JSON.stringify(user))
}

export const clearUser = () => {
	localStorage.removeItem('user')
}
