import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit'
import authReducer from 'slices/auth'
import messageReducer from 'slices/message'
import onboardingReducer from 'slices/onboarding'

const reducer = combineReducers({
	auth: authReducer,
	message: messageReducer,
	onboarding: onboardingReducer
})

const store = configureStore({
	reducer,
	devTools: true
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>

export default store
