import { useState, forwardRef, useEffect } from 'react'
import toast from 'react-hot-toast'
import clsx from 'clsx'
import { PlusIcon } from '@heroicons/react/24/solid'
import { currentProjects } from './project-selection'

import { Button, ButtonOutline } from 'components/app/button'
import { WalletAddressField } from 'components/dashboard/wallet-address-field'

import { getRandomKey } from 'utils/string'
import { useAppDispatch } from 'hooks'
import { setWallets } from 'slices/onboarding'

type NodeDeploymentProps = {
	customDeployButton?: any
	onSubmit?: (wallets: string[]) => void
}

const NodeDeployment = forwardRef(({ customDeployButton, onSubmit }: NodeDeploymentProps, ref) => {
	let defaultFieldKey = getRandomKey('walletAddress')
	let selectedProject = currentProjects[0].id
	const dispatch = useAppDispatch()

	const [renderWalletFields, setWalletFields] = useState([defaultFieldKey])
	const [formState, setFormState] = useState<{ [id: string]: string }>({
		[defaultFieldKey]: ''
	})

	let walletAddresses = Object.values(formState).filter(v => Boolean(v))
	let validWalletAddrs = walletAddresses.length

	const addNewField = (e: any) => {
		let randomKey = getRandomKey('walletAddress')
		setWalletFields(prevState => [...prevState, randomKey])
		setTimeout(() => e.target.scrollIntoView({ behavior: 'smooth' }), 50)
	}

	useEffect(() => {
		let addresses = Object.values(formState)
		dispatch(setWallets(addresses))
	}, [formState])

	useEffect(() => {
		// if user was on billing page previously
		// we need to retrieve the wallets from local storage
		// to preserve their previous selection
		let walletsFromStorage = window.localStorage.getItem('wallets')
		if (!walletsFromStorage) {
			return
		}

		let wallets = walletsFromStorage.split(',')
		let walletFields = wallets.map(wallet => [getRandomKey('walletAddress'), wallet])

		setWalletFields(walletFields.map(([key]) => key))
		setFormState(Object.fromEntries(walletFields))
	}, [])

	const removeField = (field: string) => {
		const fields = renderWalletFields.filter(f => f !== field)
		const { [field]: removeField, ...restFormState } = formState
		setFormState(_prevState => restFormState)
		setWalletFields(fields)
	}

	const hanldeInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target
		setFormState(prevState => {
			let newFormState = { ...prevState, [name]: value }
			return newFormState
		})
	}

	const handleFormSubmission = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		if (!selectedProject) {
			return toast.error('Please choose project first!')
		}

		if (walletAddresses.length === 0) {
			return toast.error('Please fill at least one wallet address field!')
		}

		const uniqueWalletAddresses = new Set(walletAddresses)

		if (walletAddresses.length !== uniqueWalletAddresses.size) {
			return toast.error('Please remove duplicate addresses!')
		}

		onSubmit?.(walletAddresses)
	}

	const isDuplicatedAddress = (field: string) => {
		const { [field]: address, ...rest } = formState
		return !!Object.values(rest).includes(address)
	}

	const DeployButton = () => {
		return (
			<div className="ml-auto mt-10 sm:w-full lg:w-1/2">
				<Button type="submit">
					<span>
						<span>Deploy {validWalletAddrs < 2 ? 'Node' : 'Nodes'} 🚀</span>
					</span>
				</Button>
			</div>
		)
	}

	const renderButton = () => (customDeployButton ? customDeployButton : <DeployButton />)

	return (
		<form ref={ref as any} className="w-full" onSubmit={handleFormSubmission}>
			<div className="space-y-2">
				{renderWalletFields.map((field, index) => (
					<div key={field} className="flex flex-row items-center">
						<WalletAddressField
							index={index}
							field={field}
							fields={renderWalletFields}
							invalid={!(formState[field]?.length > 2 ? formState[field].startsWith('0x') : true)}
							duplicate={!!(index !== 0 && formState[field] && isDuplicatedAddress(field))}
							value={formState[field] ?? ''}
							onRemoveField={removeField}
							onChange={hanldeInputChange}
						/>
					</div>
				))}
				{renderWalletFields.length >= 20 ? null : (
					<ButtonOutline
						type="button"
						className={clsx('inline-flex w-max items-center border-none bg-none shadow-none', {
							'ml-3': renderWalletFields.length > 1
						})}
						onClick={addNewField}
					>
						<PlusIcon className="w- mr-2 h-4" />
						Add another
					</ButtonOutline>
				)}
			</div>
			{renderButton()}
		</form>
	)
})

export { NodeDeployment }
