import { ArrowLeftIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'

import { AppLayout } from 'components/app/layout'
import { EmailConfirmationForm } from 'components/auth/email-confirmation-form'
import { Container } from 'components/container'

import EmailIcon from 'assets/icons/png/email.png'

export const VerifyEmail = () => {
	return (
		<AppLayout title="Verify Email">
			<Container className="flex-col">
				<div className="mx-auto flex max-w-max flex-col items-center">
					<img src={EmailIcon} className="h-32 w-32" />
					<h2 className="text-lg font-bold">Please verify your email...</h2>
					<p className="max-w-sm text-center">
						Please verify your email. We&apos;ve sent a confirmation link to your email
					</p>
					<div className="my-6 w-full max-w-sm sm:mx-auto">
						<EmailConfirmationForm />
					</div>
					<div className="space-y-px text-center text-slate-600">
						<p>Didn&apos; receive the email?</p>
						<p>Check your &quot;Spam&quot; folder, it may been caught by a filter.</p>
						<p>If still don&apos;t see it, you can resend confirmation email.</p>
					</div>

					<Link to={'/signup'} className="mt-4 inline-flex items-center font-bold">
						<ArrowLeftIcon className="w-4" />
						<span className="ml-2">Back</span>
					</Link>
				</div>
			</Container>
		</AppLayout>
	)
}
