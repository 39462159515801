import { getRandomNum } from './number'

/**
 * Convert a string to title-case e.g. taimur -> Taimur,
 * taimur shah -> Taimur Shah
 *
 * @param text String to be title-cased
 * @param splitBy This is to split and join the string if it's concatenated with any string literal e.g. space, hyphen etc
 */
export const toTitleCase = (text: string, splitBy = ' ') => {
	if (!text) {
		return text
	}

	return text
		.trim()
		.toLowerCase()
		.split(splitBy)
		.map(s => s.charAt(0).toUpperCase() + s.substring(1))
		.join(splitBy)
}

/**
 * Takes a string and returns initial letter/s
 * @param text String to be split ang get initials from
 * @param splitBy Delimeter to split the text
 */
export const getWordsInitials = (text: string, splitBy = ' ') => {
	if (!text) {
		return text
	}

	text = text
		.trim()
		.toLowerCase()
		.split(splitBy)
		.map(s => s.charAt(0).toUpperCase())
		.join('')

	return text
}

/**
 * Returns a string prefixed with a randomly generated number
 * @param prefix A valid string
 */
export const getRandomKey = (prefix = ''): string => {
	const randPart = getRandomNum()
	return `${prefix}${randPart}`
}

export const getNumLocaleString = (number: number, decimals: number) => {
	return Number(number.toFixed(decimals)).toLocaleString()
}
