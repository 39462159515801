import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { Link } from 'react-router-dom'

import { Spinner } from 'components/animations/spinner'
import { Button, ButtonOutline } from 'components/app/button'
import { CsvDownloadModal } from 'components/csv-download-modal'
import { AppHeader } from 'components/app/header'
import { AppLayout } from 'components/app/layout'
import { Modal } from 'components/app/modal'
import { SidebarLayout } from 'components/app/sidebar-layout'
import nodeService from 'services/node-service'
import validatorService, { Validator } from 'services/validator-service'
import { getNumLocaleString } from 'utils/string'

import StreamrLogo from 'assets/logos/svg/streamr'
import SvgGnosisLogo from 'assets/logos/svg/gnosis'
import { renderLogo } from 'components/dashboard/supported-networks-card'
import { EthLikeProject } from 'constants/index'
import { NETWORKS } from 'utils/networks'
import { ETH_CHAIN, GNOSIS_CHAIN } from 'utils/validators'

export function validatorStatusLink(publicKey: string, project: EthLikeProject) {
	let chain = project == 'gnosis' ? GNOSIS_CHAIN : ETH_CHAIN
	let explorer = NETWORKS[chain].beaconExplorerUrl
	let url = `${explorer}/validator/${publicKey}#deposits`
	return (
		<a className="underline" href={url} target="_blank" rel="noreferrer">
			Node Url
		</a>
	)
}

export const ListNodes = () => {
	const [isLoading, setLoading] = useState(true)
	const [deployments, setDeployments] = useState<Deployment[]>([])
	const [validators, setValidators] = useState<Validator[]>([])

	useEffect(() => {
		Promise.all([validatorService.getValidators(), nodeService.getDeployments()])
			.then(([validators, nodes]) => {
				setValidators(validators.data)
				setDeployments(nodes.data)
			})
			.finally(() => {
				setLoading(false)
			})
	}, [])

	const handleRemoveDeployment = (id: string) => {
		const filteredDeployments = deployments.filter(d => d.id !== id)
		setDeployments(filteredDeployments)
	}

	return (
		<AppLayout renderHeader={false} title="Nodes">
			<SidebarLayout>
				<div className="hidden border-b md:block">
					<AppHeader auth={true}>
						<div className="flex items-center gap-6">
							<Link to={'/nodes/create'}>
								<Button className="inline-flex w-40 items-center">
									<PlusIcon className="mr-2 h-6 w-6" />
									Create node
								</Button>
							</Link>
							<Link to={'/faqs'}>
								<ButtonOutline className="flex w-40 items-center">
									<ExclamationCircleIcon className="mr-2 h-6 w-6" />
									How to use?
								</ButtonOutline>
							</Link>
						</div>
					</AppHeader>
				</div>
				<div className="py-8">
					<div className="px-4 sm:px-6 md:px-8">
						<div className="space-y-10">
							{isLoading ? (
								<div className="flex h-96 items-center justify-center">
									<Spinner className="h-8 w-8" />
								</div>
							) : (
								<DeployedNodes
									validators={validators}
									deployments={deployments}
									onDeleteDeployment={handleRemoveDeployment}
								/>
							)}
						</div>
					</div>
				</div>
			</SidebarLayout>
		</AppLayout>
	)
}

const TABLE_HEADERS = ['Project', 'Nodes', 'Rewards', 'Status', 'Link', '']

type DeployedNodesProps = {
	onDeleteDeployment: (deploymentId: string) => void
	deployments: Deployment[]
	validators: Validator[]
}

const DeployedNodes = ({ deployments, validators, onDeleteDeployment }: DeployedNodesProps) => {
	const [isDeleting, setDeleting] = useState(false)
	const [deploymentToBeDeleted, setDeploymentToBeDeleted] = useState<string | undefined>()
	const [isRewardsModalOpen, setRewardsModalOpen] = useState(false)

	const handleDeleteDeployment = () => {
		setDeleting(true)

		nodeService
			.deleteDeployment(deploymentToBeDeleted as string)
			.then(_res => {
				onDeleteDeployment(deploymentToBeDeleted as string)
				setDeploymentToBeDeleted(undefined)
			})
			.catch(() => {
				toast.error('Something went wrong, please try again.')
			})
			.finally(() => {
				setDeleting(false)
			})
	}

	const renderStatus = (status: DeploymentStatus | Validator['status']): 'Online' | 'Pending' => {
		if (status == 'Created' || status == 'active_online') return 'Online'
		return 'Pending'
	}

	return (
		<div>
			<Link className="md:hidden" to="/nodes/create">
				<Button className="inline-flex items-center lg:w-40">
					<PlusIcon className="mr-2 h-6 w-6" />
					Create node
				</Button>
			</Link>
			{deploymentToBeDeleted && (
				<Modal title="Confirmation" noDefaultClose={true}>
					<div className="m-2">
						<p className="my-4">Are you sure you want to delete? </p>
						<div className="flex items-center justify-between space-x-4">
							<ButtonOutline
								className={clsx({
									'pointer-events-none bg-gray-400': isDeleting
								})}
								onClick={() => setDeploymentToBeDeleted(undefined)}
							>
								Cancel
							</ButtonOutline>
							<Button
								onClick={handleDeleteDeployment}
								className={clsx('inline-flex items-center', {
									'pointer-events-none opacity-80': isDeleting
								})}
							>
								{isDeleting ? (
									<>
										<Spinner className="h-5 w-5" />
										<span className={'ml-2 animate-pulse'}>deleting...</span>
									</>
								) : (
									<span>Confirm</span>
								)}
							</Button>
						</div>
					</div>
				</Modal>
			)}
			<div className="mt-8 flow-root rounded-xl border md:mt-0">
				<div className="flex justify-between p-6">
					<div >
						<h2 className="text-base font-medium xl:text-xl">Deployed Nodes</h2>
						<p className="text-sm">Your deployed and running nodes.</p>
					</div>
					<button type="button" onClick={() => setRewardsModalOpen(true)}>
						Download CSV report
					</button>
					{ isRewardsModalOpen && <CsvDownloadModal onClose={() => setRewardsModalOpen(false)} /> }
				</div>
				<div className="overflow-x-auto px-6 sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full  border-t py-2 align-middle sm:px-6 lg:px-8">
						<table className="min-w-full divide-y divide-gray-300">
							<thead>
								<tr>
									{TABLE_HEADERS.map((th, index) => (
										<th
											key={'th_' + index}
											scope="col"
											className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-light text-gray-500"
										>
											{th}
										</th>
									))}
								</tr>
							</thead>
							<tbody className="divide-y divide-gray-200 bg-white">
								{validators.map(validator => {
									return (
										<tr key={validator.id} className="leading-5">
											<td className="whitespace-nowrap px-2 py-6 font-medium ">
												<div className="flex items-center">
													{renderLogo(validator.project_name)}
													<p className="text-sm capitalize text-gray-900 lg:text-base">
														&nbsp;{validator.project_name}
													</p>
												</div>
											</td>
											<td className="whitespace-nowrap py-2 pl-4 pr-3 text-gray-900">1</td>
											<td className="whitespace-nowrap px-2 py-2 text-gray-900">
												{validator.balance ? validator.balance - 32 : 0}
											</td>
											<td className="whitespace-nowrap px-2 py-2 text-sm capitalize text-gray-900 lg:text-base">
												{renderStatus(validator.status)}
											</td>
											<td className="whitespace-nowrap px-2 py-2 text-gray-900">
												{validatorStatusLink(validator.public_key, validator.project_name)}
											</td>
											<td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right font-medium"></td>
										</tr>
									)
								})}
								{deployments.map(deployment => {
									let nodes = Object.values(deployment.metadata)
									return (
										<tr key={deployment.id} className="leading-5">
											<td className="whitespace-nowrap px-2 py-6 font-medium ">
												<div className="flex items-center">
													<StreamrLogo className="mr-2 h-5 w-5 text-[#F65F0A]" />
													<p className="text-sm text-gray-900 lg:text-base">
														{deployment.project_name}
													</p>
												</div>
											</td>
											<td className="whitespace-nowrap py-2 pl-4 pr-3 text-gray-900">
												{nodes.length}
											</td>
											<td className="whitespace-nowrap px-2 py-2 text-gray-900">
												{getNumLocaleString(deployment.rewards, 2)}
											</td>
											<td className="whitespace-nowrap px-2 py-2 text-sm capitalize text-gray-900 lg:text-base">
												{renderStatus(deployment.status)}
											</td>
											<td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right font-medium"></td>
											<td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right font-medium">
												<TrashIcon
													onClick={() => setDeploymentToBeDeleted(deployment.id)}
													className="h-5 w-5 cursor-pointer text-gray-700 hover:text-red-500"
												/>
											</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	)
}

const dummyStats: Partial<StatItem>[] = [
	{
		id: 1,
		title: 'Total Nodes',
		nodesCount: 1000,
		type: 'extra'
	},
	{
		id: 2,
		title: 'Uptime',
		nodesCount: 1000,
		type: 'extra'
	},
	{
		id: 3,
		title: "Today's revenue",
		earnings: 22.33,
		change: 1.24,
		changeType: 'decrease',
		percentage: 3.4
	},
	{
		id: 4,
		title: 'Net Worth',
		earnings: 100.24,
		change: 87.24,
		changeType: 'increase',
		percentage: 3.4
	},
	{
		id: 5,
		title: 'Total Rewards',
		earnings: 96,
		change: 10.24,
		changeType: 'increase',
		percentage: 3.4
	}
]

type StatsCardProps = {
	stats?: StatItem[]
}

type StatItem = {
	id: number
	title: string
	earnings: number
	change: number
	percentage: number
	nodesCount: number
	uptime: number
	type: 'extra'
	changeType?: 'increase' | 'decrease'
}

export const StatsCard = ({ stats }: StatsCardProps) => {
	return (
		<div className="rounded-lg border sm:p-4 lg:p-8">
			<dl className="grid grid-cols-1 gap-16 sm:grid-cols-5">
				{(stats ?? dummyStats).map((stat, index) => (
					<div
						key={stat.id}
						className={clsx('flex flex-col justify-center gap-y-2 border-gray-900/10 pl-6 pr-12', {
							'border-r': index !== 4
						})}
					>
						<dt className="leading-normal text-gray-800">{stat.title}</dt>
						<dd className="text-3xl font-medium tracking-tight text-gray-900">
							{stat.type === 'extra' ? stat.nodesCount || stat.uptime : `€${stat.earnings}`}
						</dd>
						{!stat.type && (
							<dt className="flex items-center space-x-2 leading-normal text-gray-800">
								<span>
									{stat.changeType === 'decrease' ? '-' : ''}€{stat.change}
								</span>
								<span
									className={clsx('flex items-center', {
										'text-red-500': stat.changeType === 'decrease',
										'text-green-500': stat.changeType === 'increase'
									})}
								>
									<ChevronDownIcon
										className={clsx('mr-px h-4 w-4', {
											'rotate-180 ': stat.changeType === 'increase'
										})}
									/>
									{stat.percentage}%
								</span>
							</dt>
						)}
					</div>
				))}
			</dl>
		</div>
	)
}
