import EyeIcon from 'assets/icons/png/eye.png'

export const AuthSidePanel = () => {
	return (
		<div className="flex h-[42rem] w-full rounded-l-2xl bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))]  from-[#7B5EE2] to-[#7FDEF9] text-white">
			<div className="-mt-16 ml-24 flex max-w-md flex-col justify-center space-y-2 p-4">
				<img src={EyeIcon} className="h-32 w-32" />
				<h2 className="text-6xl">Deploy nodes in one-click & earn crypto</h2>
				<p className="text-lg">with zonaris</p>
			</div>
		</div>
	)
}
