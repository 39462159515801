import { rest } from 'msw'

export const handlers = [
	rest.post('/login', (req, res, ctx) => {
		return res(
			// Respond with a 200 status code
			ctx.status(200),
			ctx.json({
				name: 'Admin',
				email: 'admin@zonaris.com',
				token:
					'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkFkbWluIiwiaWF0IjoxNTE2MjM5MDIyfQ.1Jy-gGjEJVq6me2f6YMguG5Pgjtmgkw1E7Qucttkbbs'
			})
		)
	}),

	rest.post('/register', (req, res, ctx) => {
		return res(
			// Respond with a 200 status code
			ctx.status(200)
		)
	}),

	rest.get('/user', (req, res, ctx) => {
		// Check if the user is authenticated in this session
		const isAuthenticated = sessionStorage.getItem('is-authenticated')

		if (!isAuthenticated) {
			// If not authenticated, respond with a 403 error
			return res(
				ctx.status(403),
				ctx.json({
					errorMessage: 'Not authorized'
				})
			)
		}

		// If authenticated, return a mocked user details
		return res(
			ctx.status(200),
			ctx.json({
				email: 'admin@zonaris.com',
				name: 'Admin'
			})
		)
	})
]
