import { Link } from 'react-router-dom'
import CurrentStepIcon from 'assets/icons/svg/current-step'
import CompletedStepIcon from 'assets/icons/svg/completed-step'
import NextStepIcon from 'assets/icons/svg/next-step'
import clsx from 'clsx'

type StepsProps = {
	steps: any[]
	className?: string
}

export default function Steps({ steps, className }: StepsProps) {
	return (
			<nav className={clsx("flex justify-center bg-white p-4 lg:bg-none lg:p-4 rounded-lg border lg:border-none mx-4 lg:mx-0", className)} aria-label="Progress">
				<ol role="list" className="lg:space-y-6">
					{steps
						.filter(step => !step.hidden)
						.map(step => (
							<li key={step.heading}>
								{step.status === 'complete' ? (
									<Link to={step.href} className="hidden lg:block group">
										<span className="flex items-center">
											<span className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center">
												<CompletedStepIcon />
											</span>
											<div className="flex flex-col">
												<span className="ml-3 text-sm font-medium text-black">{step.heading}</span>
												<span className="ml-3 text-sm text-black">{step.subheading}</span>
											</div>
										</span>
									</Link>
								) : step.status === 'current' ? (
									<Link to={step.href} className="flex items-start" aria-current="step">
										<span
											className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center"
											aria-hidden="true"
										>
											<CurrentStepIcon />
										</span>
										<div className="flex flex-col">
											<span className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
												{step.heading}
											</span>
											<span className="ml-3 text-sm text-gray-500 group-hover:text-gray-900">
												{step.subheading}
											</span>
										</div>
									</Link>
								) : (
									<Link to={step.href} className="hidden lg:block group">
										<div className="flex items-start">
											<div
												className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center"
												aria-hidden="true"
											>
												<NextStepIcon />
											</div>
											<div className="flex flex-col">
												<span className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
													{step.heading}
												</span>
												<span className="ml-3 text-sm text-gray-500 group-hover:text-gray-900">
													{step.subheading}
												</span>
											</div>
										</div>
									</Link>
								)}
							</li>
						))}
				</ol>
			</nav>
	)
}
