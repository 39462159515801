import { ArrowRightIcon } from '@heroicons/react/24/outline'

type NextButtonProps = {
	moveOn: () => void
	text?: string
}

function NextButton({ moveOn, text = 'Next' }: NextButtonProps) {
	return (
		<button
			type="button"
			onClick={moveOn}
			className="absolute bottom-5 lg:bottom-auto lg:top-[524px] right-6 flex rounded-lg border border-transparent bg-[#6340DE] py-3 px-4 text-sm font-medium text-white shadow-sm hover:bg-[#5630de] focus:outline-none focus:ring-2 focus:ring-[#6d4de2] focus:ring-offset-2"
		>
			{text}
			{text == 'Next' && <ArrowRightIcon className="ml-2 h-5 w-5" />}
		</button>
	)
}

export { NextButton }
