import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { FormError } from 'components/app/form-error'
import { Input } from 'components/app/input'
import { AppLayout } from 'components/app/layout'
import { CustomLink } from 'components/app/link'
import { AuthSidePanel } from 'components/auth/side-panel'
import { useAppDispatch, useAppSelector } from 'hooks'
import { Navigate } from 'react-router-dom'
import { login } from 'slices/auth'
import { clearMessage } from 'slices/message'

const schema = yup.object().shape({
	email: yup.string().email().required('Email is required'),
	password: yup.string().required('Password is required')
})

export const Login = () => {
	const dispatch = useAppDispatch()

	const user = useAppSelector(state => state.auth.user)
	const message = useAppSelector(state => state.message.message)

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: yupResolver(schema)
	})

	const [isLoading, setLoading] = useState(false)

	useEffect(() => {
		return () => {
			dispatch(clearMessage())
		}
	}, [])

	const handleFormSubmit = (data: any) => {
		const { email, password } = data
		setLoading(true)

		dispatch(login({ email, password }))
			.unwrap()
			.catch(() => {
				setLoading(false)
			})
	}

	if (user?.jwt && user?.deployments?.number_of_nodes === 0) {
		return <Navigate to={'/home'} />
	}

	if (user?.jwt && user?.deployments?.number_of_nodes > 0) {
		return <Navigate to={'/dashboard'} />
	}

	return (
		<AppLayout title="Login">
			<div className="mt-8 flex w-full flex-row items-start sm:justify-center">
				<div className="w-full px-6 md:w-2/3 md:px-0">
					<div className="space-y-1 sm:mx-auto sm:w-full sm:max-w-md">
						<h2 className="mt-6 text-3xl font-medium tracking-tight text-gray-900">
							Log in to your account
						</h2>
						<p className="text-slate-400">Welcome back! Please enter your details</p>
					</div>

					<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
						<form className="space-y-6" onSubmit={handleSubmit(handleFormSubmit)}>
							<Input
								type="email"
								autoComplete="true"
								register={register}
								name="email"
								placeholder="Enter your email"
								errors={errors}
								labelText="Email"
							/>

							<Input
								name="password"
								labelText="Password"
								register={register}
								errors={errors}
								placeholder="Enter your password"
								type="password"
								autoCapitalize="false"
							/>

							<div>
								<CustomLink href="/reset-password">Forgot your password?</CustomLink>
							</div>

							<div className="space-y-3">
								<Button type="submit" disabled={isLoading}>
									{isLoading ? (
										<>
											<Spinner className="h-5 w-5" />
											<span className={'mx-auto animate-pulse'}>Please wait...</span>
										</>
									) : (
										<span>Sign In</span>
									)}
								</Button>
							</div>
						</form>
						<p className="mt-10 text-center">
							Don&apos;t have an account? <CustomLink href="/signup">Sign-Up</CustomLink>
						</p>
						{message && <FormError message={message} />}
					</div>
				</div>
				<div className="hidden w-1/2 md:block">
					<AuthSidePanel />
				</div>
			</div>
		</AppLayout>
	)
}
