import { InputHTMLAttributes } from 'react'
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	labelText?: string
	name: string
	index?: string
	register?: UseFormRegister<FieldValues>
	errors?: FieldErrors<FieldValues>
	renderLabel?: boolean
}

export const Input = ({
	labelText,
	index,
	name,
	register,
	errors,
	renderLabel = true,
	...props
}: InputProps) => {
	const errorText = errors?.[name]?.message as string

	return (
		<div>
			{renderLabel && labelText && (
				<label htmlFor={name} className="block text-sm font-semibold text-slate-900">
					{labelText}
				</label>
			)}
			<div className="mt-1">
				<input
					{...props}
					{...(register?.(name) ?? {})}
					id={index}
					className="block w-full appearance-none rounded-md border border-gray-300 p-3 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
				/>
			</div>
			{errorText && <p className="ml-px mt-px text-sm text-red-600">{errorText}</p>}
		</div>
	)
}
