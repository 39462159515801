export default function SvgMetamasLogo(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M23.814 1L14.4355 7.95778L16.1773 3.85286L23.814 1Z"
				fill="#E17726"
				stroke="#E17726"
				strokeWidth="0.518412"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.18652 1L11.4796 8.02363L9.82321 3.85286L2.18652 1Z"
				fill="#E27625"
				stroke="#E27625"
				strokeWidth="0.518412"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M20.4421 17.1287L17.9473 20.9516L23.2857 22.4198L24.8114 17.2131L20.4421 17.1287Z"
				fill="#E27625"
				stroke="#E27625"
				strokeWidth="0.518412"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1.19727 17.2128L2.71366 22.4196L8.0521 20.9514L5.56652 17.1284L1.19727 17.2128Z"
				fill="#E27625"
				stroke="#E27625"
				strokeWidth="0.518412"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.76003 10.6692L6.27246 12.92L11.5643 13.1556L11.3853 7.4585L7.76003 10.6692Z"
				fill="#E27625"
				stroke="#E27625"
				strokeWidth="0.518412"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18.2398 10.6691L14.558 7.39258L14.4355 13.1555L19.7273 12.9199L18.2398 10.6691Z"
				fill="#E27625"
				stroke="#E27625"
				strokeWidth="0.518412"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.05176 20.9519L11.2444 19.3973L8.4942 17.251L8.05176 20.9519Z"
				fill="#E27625"
				stroke="#E27625"
				strokeWidth="0.518412"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.7549 19.3973L17.9475 20.9519L17.5051 17.251L14.7549 19.3973Z"
				fill="#E27625"
				stroke="#E27625"
				strokeWidth="0.518412"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17.9475 20.9516L14.7549 19.397L15.009 21.4784L14.9812 22.354L17.9475 20.9516Z"
				fill="#D5BFB2"
				stroke="#D5BFB2"
				strokeWidth="0.518412"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.05176 20.9516L11.0181 22.354L10.9995 21.4784L11.2444 19.397L8.05176 20.9516Z"
				fill="#D5BFB2"
				stroke="#D5BFB2"
				strokeWidth="0.518412"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.0745 15.8759L8.41895 15.0949L10.2926 14.2378L11.0745 15.8759Z"
				fill="#233447"
				stroke="#233447"
				strokeWidth="0.518412"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.9248 15.8756L15.7067 14.2375L17.5897 15.0946L14.9248 15.8756Z"
				fill="#233447"
				stroke="#233447"
				strokeWidth="0.518412"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.05225 20.952L8.51324 17.1287L5.56641 17.2131L8.05225 20.952Z"
				fill="#CC6228"
				stroke="#CC6228"
				strokeWidth="0.518412"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17.4863 17.1287L17.9473 20.952L20.4424 17.2131L17.4863 17.1287Z"
				fill="#CC6228"
				stroke="#CC6228"
				strokeWidth="0.518412"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M19.7273 12.9199L14.4355 13.1555L14.9252 15.8756L15.707 14.2378L17.5897 15.0947L19.7273 12.9199Z"
				fill="#CC6228"
				stroke="#CC6228"
				strokeWidth="0.518412"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.41941 15.0947L10.2928 14.2378L11.0746 15.8756L11.5643 13.1555L6.27246 12.9199L8.41941 15.0947Z"
				fill="#CC6228"
				stroke="#CC6228"
				strokeWidth="0.518412"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.27246 12.9199L8.49453 17.2509L8.41941 15.0947L6.27246 12.9199Z"
				fill="#E27525"
				stroke="#E27525"
				strokeWidth="0.518412"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17.5893 15.0947L17.5049 17.2509L19.727 12.9199L17.5893 15.0947Z"
				fill="#E27525"
				stroke="#E27525"
				strokeWidth="0.518412"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.5639 13.1555L11.0742 15.8756L11.6956 19.0863L11.8273 14.8592L11.5639 13.1555Z"
				fill="#E27525"
				stroke="#E27525"
				strokeWidth="0.518412"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.4357 13.1553L14.1816 14.8497L14.3041 19.0861L14.9254 15.8754L14.4357 13.1553Z"
				fill="#E27525"
				stroke="#E27525"
				strokeWidth="0.518412"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.9251 15.8756L14.3037 19.0863L14.7554 19.397L17.5051 17.251L17.5895 15.0947L14.9251 15.8756Z"
				fill="#F5841F"
				stroke="#F5841F"
				strokeWidth="0.518412"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.41895 15.0947L8.49407 17.251L11.2438 19.397L11.6955 19.0863L11.0741 15.8756L8.41895 15.0947Z"
				fill="#F5841F"
				stroke="#F5841F"
				strokeWidth="0.518412"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.9817 22.3535L15.0095 21.478L14.7749 21.2711H11.2246L10.9992 21.478L11.0178 22.3535L8.05176 20.9512L9.08773 21.7979L11.1968 23.2578H14.8027L16.9118 21.7979L17.9477 20.9512L14.9817 22.3535Z"
				fill="#C0AC9D"
				stroke="#C0AC9D"
				strokeWidth="0.518412"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.755 19.3971L14.3034 19.0864H11.6955L11.2439 19.3971L10.999 21.4782L11.2244 21.2714H14.7745L15.0091 21.4782L14.755 19.3971Z"
				fill="#161616"
				stroke="#161616"
				strokeWidth="0.518412"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M24.2085 8.41038L24.9996 4.56885L23.8134 1L14.7549 7.72314L18.2393 10.6697L23.1632 12.1109L24.2465 10.8394L23.7753 10.5009L24.5284 9.8127L23.9543 9.36103L24.7074 8.78693L24.2085 8.41038Z"
				fill="#763E1A"
				stroke="#763E1A"
				strokeWidth="0.518412"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1 4.5691L1.8004 8.41063L1.29215 8.78717L2.04524 9.36127L1.47115 9.81294L2.22424 10.5011L1.7531 10.8396L2.83636 12.1112L7.76023 10.6699L11.2447 7.72338L2.18622 1.00024L1 4.5691Z"
				fill="#763E1A"
				stroke="#763E1A"
				strokeWidth="0.518412"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M23.1633 12.1105L18.2394 10.6692L19.7271 12.9201L17.5049 17.2514L20.4421 17.2133H24.8114L23.1633 12.1105Z"
				fill="#F5841F"
				stroke="#F5841F"
				strokeWidth="0.518412"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.75995 10.6694L2.83608 12.1107L1.19727 17.2136H5.56652L8.4945 17.2516L6.27231 12.9204L7.75995 10.6694Z"
				fill="#F5841F"
				stroke="#F5841F"
				strokeWidth="0.518412"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.4352 13.1556L14.7552 7.72286L16.1769 3.85278H9.82227L11.244 7.72286L11.5639 13.1556L11.6864 14.8685L11.6956 19.0864H14.3035L14.3128 14.8685L14.4352 13.1556Z"
				fill="#F5841F"
				stroke="#F5841F"
				strokeWidth="0.518412"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
