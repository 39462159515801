import { getUser } from 'utils/local-storage'

export const getAuthHeader = () => {
	const user = getUser()
	return user?.jwt ? { Authorization: 'Bearer ' + user.jwt } : {}
}

export const getAuthHeader2 = (): string => {
	const user = getUser()
	return user?.jwt ? 'Bearer ' + user.jwt : ''
}
