import { CheckCircleIcon } from '@heroicons/react/24/solid'
import cond from 'cond-construct'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Link, Navigate, useSearchParams } from 'react-router-dom'

import { AppLayout } from 'components/app/layout'
import { Container } from 'components/container'
import { useAppDispatch, useAppSelector } from 'hooks'
import authService from 'services/auth-service'
import { confirm } from 'slices/auth'
import { clearMessage } from 'slices/message'

import HourGlassIcon from 'assets/icons/png/hourglass.png'
import { EmailConfirmationForm } from 'components/auth/email-confirmation-form'

type ConfirmationState = 'loading' | 'failed' | 'completed'

export const AccountConfirmation = () => {
	const dispatch = useAppDispatch()

	const [params] = useSearchParams()
	const confirmationToken = params.get('token') as string

	const { message } = useAppSelector(state => state.message)
	const { user } = useAppSelector(state => state.auth)

	const [confirmationState, setConfirmationState] = useState<ConfirmationState>('loading')

	let isConfirmed = confirmationState == 'completed'

	// clean up any pending message
	useEffect(() => {
		return () => {
			dispatch(clearMessage())
		}
	}, [])

	useEffect(() => {
		if (!isConfirmed && confirmationToken) {
			dispatch(confirm({ token: confirmationToken }))
				.unwrap()
				.then(() => {
					setConfirmationState('completed')
				})
				.catch(() => {
					setConfirmationState('failed')
				})
		}
	}, [confirmationToken, isConfirmed])


	const renderContent = () => {
		switch (confirmationState) {
			case 'failed':
				return <ConfirmationFailed message={message as string} />
			case 'completed':
				return <ConfirmationCompleted />
			default:
				return <ConfirmationInProgress />
		}
	}

	if (user?.jwt || isConfirmed) {
		return <Navigate to={'/home'} replace={true} />
	}

	return (
		<AppLayout title="Confirmation">
			<Container className="flex-col">
				<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
					<>{renderContent()}</>
				</div>
			</Container>
		</AppLayout>
	)
}

const ConfirmationInProgress = () => {
	return (
		<div className="flex flex-col items-center justify-center space-y-2 text-slate-900">
			<img src={HourGlassIcon} className="h-32 w-32" />
			<p className="text-2xl font-bold">Taking you to zonaris!</p>
			<p className="text-2xl font-bold">Please wait loading...</p>
		</div>
	)
}

const ConfirmationCompleted = () => {
	return (
		<div className="flex flex-col items-center justify-center space-y-4">
			<CheckCircleIcon className="h-12 w-12 text-green-500" />
			<p>Your account has been confirmed!</p>
			<Link
				className="rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
				to="/login">
				Continue Login
			</Link>
		</div>
	)
}

type ConfirmationFailedProps = {
	message?: string
}

const ConfirmationFailed = ({ message }: ConfirmationFailedProps) => {
	const [isSentConfirmLink, setSentConfirmLink] = useState(false)

	const submitResendConfirmationForm = (data: any) => {
		const { email } = data

		authService
			.resendConfirmation(email as string)
			.then(data => {
				setSentConfirmLink(true)
			})
			.catch(error => {
				toast.error(error.response.data.message)
			})
	}

	if (isSentConfirmLink) {
		return <Navigate to={'/register/verify-email'} />
	}

	return (
		<div className="flex flex-col items-center justify-center space-y-2">
			<img src={HourGlassIcon} className="h-32 w-32" />
			<p className="text-2xl font-bold">Oops! That link has expired!</p>
			<p className="max-w-sm text-center text-sm">
				The links are for one-time use and expire after 24 hours. You can get a fresh new link
			</p>
			<div className="mt-8 w-full max-w-sm sm:mx-auto">
				<EmailConfirmationForm onFormSubmit={submitResendConfirmationForm} />
			</div>
		</div>
	)
}
