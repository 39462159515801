export const projects = [
	'gnosis',
	'streamr',
	'ethereum'
] as const

export type SupportedProjects = typeof projects[number]

const ethLikeChains = [
	'ethereum',
	'gnosis'
] as const

export type EthLikeProject = typeof ethLikeChains[number]
