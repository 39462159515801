import React from 'react'

import { useDocumentTitle } from 'hooks/useDocumentTitle'
import { useAppSelector } from 'hooks'

import { AppHeader } from 'components/app/header'
import { HazyGreen, HazyPurple } from 'components/hazy-color-blobs'
import { Navigate } from 'react-router-dom'

interface OnboardingLayoutProps {
	children: React.ReactNode
	title?: string
}

const OnboardingLayout: React.FC<OnboardingLayoutProps> = ({ children, title }) => {
	const auth = useAppSelector(state => state.auth)

	const shouldNotBeHere =
		!auth.user?.jwt ||
		auth.user?.deployments?.number_of_nodes > 0 ||
		auth.user?.billing_setup_complete

	if (shouldNotBeHere) {
		return <Navigate to="/" />
	}

	const docTitleText = title ? `${title} | zonaris` : 'zonaris - home'

	useDocumentTitle(docTitleText)

	return (
		<div className="relative bg-white lg:bg-transparent">
			<HazyPurple />
			<AppHeader />
			{children}
			<HazyGreen />
		</div>
	)
}

export { OnboardingLayout }
