import React from 'react'

import { CoinsDropdown } from 'components/dropdowns/coins-selection'
import { CoinType } from 'constants/streamr'

interface CoinSelectionProps {
	onCoinInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
	onCoinSelected: (coin: CoinType) => void
}

export const CoinSelection = ({ onCoinInputChange, onCoinSelected }: CoinSelectionProps) => {
	return (
		<div className="relative rounded-md shadow-sm">
			<div className="absolute inset-y-0 left-0 flex items-center border-r">
				<label htmlFor="projectName" className="sr-only">
					Coin
				</label>
				<CoinsDropdown onCoinSelected={onCoinSelected} />
			</div>
			<input
				type="number"
				id="coins"
				name="coins"
				onChange={onCoinInputChange}
				className="block w-full rounded-md border-gray-300 py-3 pl-24 focus:border-indigo-500 focus:ring-indigo-500"
				placeholder="Enter coins"
			/>
		</div>
	)
}
