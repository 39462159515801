import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { PlusIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import { useAppSelector } from 'hooks'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { Link, useNavigate } from 'react-router-dom'

import { Spinner } from 'components/animations/spinner'
import { Button, ButtonOutline } from 'components/app/button'
import { AppHeader } from 'components/app/header'
import { AppLayout } from 'components/app/layout'
import { NodePrice } from 'components/app/node-price'
import { SidebarLayout } from 'components/app/sidebar-layout'
import { WalletAddressField } from 'components/dashboard/wallet-address-field'
import { currentProjects, SelectedProject, ProjectsDropdown } from 'components/project-selection'
import { parseErrorResponse } from 'services/axios'
import nodeService from 'services/node-service'
import { getRandomKey } from 'utils/string'
import { buildBillingURL } from 'utils/onboarding'

import { FirstSteps } from 'components/onboarding/gnosis/getting-started'
import { FirstSteps as FirstStepsEth } from 'components/onboarding/ethereum/getting-started'
import GnosisDeploymentFlow from 'components/gnosis-deployment-flow'
import EthereumDeploymentFlow from 'components/ethereum-deployment-flow'
import { SvgGnosisLogoLarge as GnosisLogo } from 'assets/logos/svg/gnosis'
import { SvgStreamrLogoLarge as StreamrLogo } from 'assets/logos/svg/streamr'
import EthLogo from 'assets/logos/svg/ethereum'
import { SupportedProjects } from 'constants/index'
import WagmiRainbowProvider from 'components/WagmiRainbowProvider'

export const CreateNode = () => {
	let defaultProject = currentProjects.find(({disabled}) => !disabled) || currentProjects[0]
	let user = useAppSelector(state => state.auth.user)

	const navigate = useNavigate()
	const defaultFieldKey = getRandomKey('walletAddress')

	const [isLoading, setLoading] = useState(false)
	const [nodeCount, setNodeCount] = useState<number>(0)
	const [selectedProject, setSelectedProject] = useState<SelectedProject>(defaultProject.id)
	const [renderWalletFields, setWalletFields] = useState([defaultFieldKey])
	const [formState, setFormState] = useState<{ [id: string]: string }>({
		[defaultFieldKey]: ''
	})

	let walletAddresses = Object.values(formState).filter(Boolean)
	let validWalletAddrs = walletAddresses.length
	let existingNodeCount = user?.deployments.number_of_nodes ?? 0

	useEffect(() => {
		if (selectedProject == 'Streamr') {
			setNodeCount(validWalletAddrs + existingNodeCount)
		} else {
			setNodeCount(1) // default for Gnosis
		}
	}, [selectedProject, validWalletAddrs, existingNodeCount])

	const addNewField = () => {
		let randomKey = getRandomKey('walletAddress')
		setWalletFields(prevState => [...prevState, randomKey])
	}

	const removeField = (field: string) => {
		const fields = renderWalletFields.filter(f => f !== field)
		const { [field]: removeField, ...restFormState } = formState
		setFormState(_prevState => restFormState)
		setWalletFields(fields)
	}

	const hanldeInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target
		setFormState(prevState => ({ ...prevState, [name]: value }))
	}

	const handleFormSubmission = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		if (selectedProject.toLowerCase() != 'streamr') return // no-op

		if (!selectedProject) {
			return toast.error('Please choose project first!')
		}

		if (walletAddresses.length === 0) {
			return toast.error('Please fill at least one wallet address field!')
		}

		const uniqueWalletAddresses = new Set(walletAddresses)

		if (walletAddresses.length !== uniqueWalletAddresses.size) {
			return toast.error('Please remove duplicate addresses!')
		}

		setLoading(true)

		nodeService
			.deployNode(selectedProject, walletAddresses)
			.then(_data => {
				navigate('/nodes')
			})
			.catch(error => {
				let errorMessage = parseErrorResponse(error)
				if (errorMessage === 'billing_setup_incomplete') {
					let url = buildBillingURL({
						node_count: validWalletAddrs,
						wallets: walletAddresses,
						project: selectedProject
					})
					return navigate(url)
				}
				toast.error(errorMessage)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const isDuplicatedAddress = (field: string) => {
		const { [field]: address, ...rest } = formState
		return !!Object.values(rest).includes(address)
	}

	const renderProjectFields = () => {
		switch (selectedProject.toLowerCase()) {
			case 'streamr':
				return renderStreamrFields()
			case 'gnosis':
				return renderGnosisFields()
			case 'ethereum':
				return renderEthereumFields()
			default:
				return null
		}
	}

	const renderGnosisFields = () => {
		return (
			<div>
				<Disclosure>
					{({ open }) => (
						<div className="border-b">
							<Disclosure.Button className="flex w-full items-center justify-between rounded-t-lg px-8 py-6 text-left text-xl text-slate-900 hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
								<div className="flex flex-row items-center justify-start">
									<GnosisLogo />
									<p className="ml-5 flex flex-col">
										<span>How to start validating?</span>
										<span className="text-xs font-light">Gnosis (GNO)</span>
									</p>
								</div>
								<ChevronDownIcon
									className={`${open ? 'rotate-180 transform' : ''} h-8 w-8 text-slate-500`}
								/>
							</Disclosure.Button>
							<Disclosure.Panel className="px-4 pb-2 pt-4">
								<p className="font-medium">Getting started checklist</p>
								<FirstSteps />
							</Disclosure.Panel>
						</div>
					)}
				</Disclosure>

				<div className="px-8 py-6">
					<WagmiRainbowProvider>
						<GnosisDeploymentFlow
							onQuantityChangeCallback={(n: number) => setNodeCount(n)}
							withEndAction
						/>
					</WagmiRainbowProvider>
				</div>
			</div>
		)
	}

	const renderEthereumFields = () => {
		return (
			<div>
				<Disclosure>
					{({ open }) => (
						<div className="border-b">
							<Disclosure.Button className="flex w-full items-center justify-between rounded-t-lg px-8 py-6 text-left text-xl text-slate-900 hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
								<div className="flex flex-row items-center justify-start">
									<EthLogo width={54} />
									<p className="ml-5 flex flex-col">
										<span>How to start validating?</span>
										<span className="text-xs font-light">Ethereum (ETH)</span>
									</p>
								</div>
								<ChevronDownIcon
									className={`${open ? 'rotate-180 transform' : ''} h-8 w-8 text-slate-500`}
								/>
							</Disclosure.Button>
							<Disclosure.Panel className="px-4 pb-2 pt-4">
								<p className="font-medium">Getting started checklist</p>
								<FirstStepsEth />
							</Disclosure.Panel>
						</div>
					)}
				</Disclosure>
				<div className="px-8 py-6">
					<WagmiRainbowProvider>
						<EthereumDeploymentFlow
							onQuantityChangeCallback={(n: number) => setNodeCount(n)}
							withEndAction
						/>
					</WagmiRainbowProvider>
				</div>
			</div>
		)
	}

	const renderStreamrFields = () => {
		return (
			<div className="flex w-full flex-col justify-between px-8 py-6">
				<p className="flex items-center">
					Wallet Address
					<ExclamationCircleIcon className="ml-2 h-5 w-5 text-gray-400" />
				</p>
				<p className="text-gray-400 sm:text-sm">
					To stake DATA and receive rewards, use a wallet compatible with Polygon such as Ledger,
					Trezor or MetaMask. Move DATA holdings from other chains (e.g. Ethereum) to Polygon
					Mainnet and deposit to your node wallet/account address.
				</p>
				<div className="space-y-2">
					{renderWalletFields.map((field, index) => (
						<div key={field}>
							<WalletAddressField
								index={index}
								field={field}
								fields={renderWalletFields}
								invalid={!(formState[field]?.length > 2 ? formState[field].startsWith('0x') : true)}
								duplicate={!!(index !== 0 && formState[field] && isDuplicatedAddress(field))}
								value={formState[field] ?? ''}
								onRemoveField={removeField}
								onChange={hanldeInputChange}
							/>
						</div>
					))}
					<div className="flex flex-row items-center justify-between">
						{renderWalletFields.length >= 20 ? null : (
							<ButtonOutline
								type="button"
								className={clsx('w-max border-none bg-none shadow-none', {
									'ml-3': renderWalletFields.length > 1
								})}
								onClick={addNewField}
							>
								<PlusIcon className="w- mr-2 h-4" />
								Add another
							</ButtonOutline>
						)}
						<Button type="submit" className="w-max">
							{isLoading ? (
								<>
									<Spinner className="h-5 w-5" />
									<span className={'mx-auto ml-2 animate-pulse'}>wait...</span>
								</>
							) : (
								<span>Deploy {validWalletAddrs < 2 ? 'Node' : 'Nodes'} 🚀</span>
							)}
						</Button>
					</div>
				</div>
			</div>
		)
	}

	return (
		<AppLayout renderHeader={false} title="Create Nodes">
			<SidebarLayout>
				<div className="hidden border-b md:block">
					<AppHeader auth={true}>
						<div className="flex items-center gap-6">
							<Link to="/nodes/create">
								<Button className="pointer-events-none inline-flex w-40 items-center bg-[#6340DE] text-white opacity-50">
									<PlusIcon className="mr-2 h-6 w-6" />
									Create node
								</Button>
							</Link>
							<Link to={'/faqs'}>
								<ButtonOutline className="flex w-40 items-center">
									<ExclamationCircleIcon className="mr-2 h-6 w-6" />
									How to use?
								</ButtonOutline>
							</Link>
						</div>
					</AppHeader>
				</div>
				<div className="py-8">
					<div className="flex flex-col px-4 sm:px-6 md:px-8 lg:flex-row lg:space-x-8">
						<div className="space-y-8 lg:w-[60%]">
							<div className="rounded-md bg-white px-6 py-4">
								<h2 className="font-medium lg:text-xl">Create new validator node</h2>
								<div className="space-y-8 divide-y divide-gray-200">
									<p>Please select a project from the dropdown menu below</p>
								</div>
							</div>

							<div className="rounded-md border border-gray-300 bg-white px-8 pb-8 pt-6">
								<div className="space-y-4">
									<p>Choose a project</p>
									<ProjectsDropdown
										onProjectSelected={projectName => setSelectedProject(projectName)}
									/>
								</div>
							</div>
							<div className="rounded-md border border-gray-300 bg-white">
								<form
									className="flex flex-col items-center xl:flex-row xl:items-start xl:space-x-10 xl:divide-x xl:divide-gray-300"
									onSubmit={handleFormSubmission}
								>
									<div className="w-full">{renderProjectFields()}</div>
								</form>
							</div>
							{selectedProject == 'Streamr' && (
								<div className="w-full rounded-md border border border-gray-300 bg-white">
									<StartValidatingInStreamr />
								</div>
							)}
						</div>
						<div className="mt-6 w-full space-y-6 lg:mt-0 lg:w-[35%]">
							<div className="w-full bg-white px-6 py-4">
								{selectedProject != 'Ethereum' && (
									<NodePrice
										project={selectedProject.toLowerCase() as SupportedProjects}
										nodeCount={nodeCount}
										existingNodeCount={existingNodeCount}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</SidebarLayout>
		</AppLayout>
	)
}

const StartValidatingInStreamr = () => {
	return (
		<Disclosure>
			{({ open }) => (
				<div className="border-b">
					<Disclosure.Button className="flex w-full items-center justify-between rounded-t-lg px-8 py-6 text-left text-xl font-medium text-slate-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
						<div className="flex flex-row items-center justify-start">
							<StreamrLogo />
							<p className="ml-5 flex flex-col">
								<span>How to start Streamr Nodes?</span>
								<span className="text-xs font-light">Streamr (DATA)</span>
							</p>
						</div>
						<ChevronDownIcon
							className={`${open ? 'rotate-180 transform' : ''} h-8 w-8 text-slate-500`}
						/>
					</Disclosure.Button>
					<Disclosure.Panel className="px-4 pb-2 pt-4">
						<ul className="ml-4 list-disc space-y-2">
							<ListHeading>1. Minimum and maximum staking requirements:</ListHeading>
							<ListItem>
								To run a Streamr node, you need to stake at least 0 DATA tokens per node, with a
								maximum of 20,000 DATA tokens per node.
							</ListItem>
							<ListHeading>2. Acquiring DATA tokens:</ListHeading>
							<ListItem>
								DATA tokens can be purchased from various centralized and decentralized exchanges.
							</ListItem>
							<ListItem>
								he most popular place to buy DATA is on Binance, which also has the most daily
								liquidity.
							</ListItem>
							<ListHeading>3. Calculating earnings:</ListHeading>
							<ListItem>
								Use the zonaris Community Staking Calculator to determine how much DATA you will
								earn.
							</ListItem>
							<ListHeading>4. Staking chain:</ListHeading>
							<ListItem>Staking is currently only available on the Polygon Chain.</ListItem>
							<ListItem>To stake, transfer your DATA tokens to a Polygon wallet/account.</ListItem>
							<ListHeading>5. Adding DATA tokens to your wallet:</ListHeading>
							<ListItem>
								Paste the following address into the &quot;import tokens&quot; section. DATA token
								contract address: 0x3a9A81d576d83FF21f26f325066054540720fC34 (Polygonscan link).
							</ListItem>
							<ListItem>Make sure you transfer only on the Polygon chain.</ListItem>
							<ListHeading>6. Creating separate wallets for multiple nodes:</ListHeading>
							<ListItem>
								It is recommended to create a separate MetaMask account for each node if you are
								setting up multiple nodes.
							</ListItem>
							<ListItem>
								For example, if you are setting up 10 nodes, you will need to create 10 wallet
								addresses/accounts.
							</ListItem>
							<ListItem>
								Each node needs one beneficiary address where rewards will be distributed monthly by
								Streamr.
							</ListItem>
							<ListHeading>7. Setting up a MetaMask account for each node:</ListHeading>
							<ListItem>
								Create a new MetaMask account by selecting “Create Account” on the top right icon in
								MetaMask.
							</ListItem>
							<ListItem>
								Import the token using the same Polygon Contract for DATA:
								0x3a9A81d576d83FF21f26f325066054540720fC34.
							</ListItem>
							<ListItem>
								Rename each account to “zonaris Node 1”, “zonaris Node 2”, etc. to keep track of
								them or call them the node name provided by Streamr which is visible in the
								dashboard.
							</ListItem>
							<ListHeading>8. Setting up nodes on zonaris:</ListHeading>
							<ListItem>
								Create the correct number of accounts in one MetaMask wallet and label each account
								by number (e.g. zonaris 1, zonaris 2, etc.).
							</ListItem>
							<ListItem>
								Set up an account with Zonaris and create nodes in just one-click.
							</ListItem>
							<ListItem>
								You can deploy up to 20 wallet addresses at a time by pasting the public wallet
								address into the designated wallet address boxes, then start earning rewards within
								minutes.
							</ListItem>
							<ListHeading>9. Adding new nodes:</ListHeading>
							<ListItem>You can add new nodes anytime.</ListItem>
							<ListItem>
								Most users deploy new nodes every month with their monthly earnings
							</ListItem>
						</ul>
					</Disclosure.Panel>
				</div>
			)}
		</Disclosure>
	)
}

type ListHeadingProps = {
	children?: React.ReactNode
}

type ListItemProps = {
	children?: React.ReactNode
}

const ListHeading = ({ children }: ListHeadingProps) => (
	<p className="text-lg font-medium">{children}</p>
)

const ListItem = ({ children }: ListItemProps) => <li className="ml-8 font-light">{children}</li>
