import * as React from 'react'

function SvgBillingIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="h-6 w-6"
			{...props}>
			<path
				d="M4.16797 2.49984C4.16797 2.0396 4.54105 1.6665 5.0013 1.6665H15.0013C15.4616 1.6665 15.8346 2.0396 15.8346 2.49984V18.3332L12.918 16.2498L10.0013 18.3332L7.08464 16.2498L4.16797 18.3332V2.49984Z"
				stroke="currentColor"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.5 9.16675H12.5"
				stroke="currentColor"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.5 12.5H12.5"
				stroke="currentColor"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.5 5.83325H12.5"
				stroke="currentColor"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}

export default SvgBillingIcon
