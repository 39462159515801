import { useSteps } from 'pages/onboarding'

import { Button } from 'components/app/button'
import MetamaskLogo from 'assets/logos/svg/metamask'

export function FirstSteps() {
	return (
		<section className='font-light pb-6'>
			<p className="mt-4 text-sm leading-6">
				Welcome to Gnosis staking by running validator nodes for which you will be rewarded with GNO
				tokens. Your participation will be securing and decentralising the Gnosis chain network.{' '}
			</p>
			<p className="mt-2 text-sm leading-6">
				The process with zonaris is simple and completely{' '}
				<span className="font-medium">Non-Custodial.</span>{' '}
				You are fully in charge of your validator nodes and staking rewards are going
				directly to your MetaMask wallet. We only provide the simple process and infrastructure
				required to make it as easy as possible.
			</p>
			<p className="mt-2 text-sm leading-6">
				In order to get started, you’ll first need to make sure you have the proper setup to start
				staking on the Gnosis Chain. Please review the information and checklist below to ensure a
				smooth experience:
			</p>
			<ul className="ml-6 mt-6 list-disc space-y-4">
				<li>
					<h4 className="text-sm font-medium leading-6">Metamask wallet extension installed.</h4>
					<p className="text-sm leading-6">
						In case you don’t, here’s a quick guide to get you setup - {' '}
						<a
							href="https://support.metamask.io/hc/en-us/articles/360015489531"
							target="_blank"
							rel="noreferrer"
							className="text-[#6340DE] underline"
						>
							link
						</a>
					</p>
				</li>
				<li>
					<p className="text-sm leading-6">
						<span className="font-medium">At least 1 GNO in your wallet </span>
						(the minimum/max number of tokens required to run a Gnosis Chain validator is 1 GNO.) If
						you’d like to stake more than 1 GNO/Run a validator, you’ll have to repeat this process
						for each one. This is one of the best/easiest places to acquire your GNO from -&nbsp;
						<a
							className="text-[#6340DE] underline"
							href="https://buyxdai.com"
							target="_blank"
							rel="noreferrer"
						>
							Gnosis Chain xDAI Providers Aggregator.
						</a>
					</p>
					<p className="mt-2 text-sm leading-6">
						If you’d like to stake more than 1 GNO/Run a validator, you will have to go through the
						Node creation process for each one separately from the zonaris dashboard. We will be
						adjusting your monthly billing/discounts based on the active number of Gnosis validator
						nodes.
					</p>
				</li>
				<li>
					<p className="text-sm leading-6">
						<span className="font-medium">A few XDAI in your Metamask wallet</span> on the Gnosis
						Chain network to process the transaction. To acquire XDAI tokens please visit&nbsp;
						<a
							className="text-[#6340DE] underline"
							href="https://buyxdai.com"
							target="_blank"
							rel="noreferrer"
						>
							Gnosis Chain xDAI Providers Aggregator.
						</a>
					</p>
				</li>
			</ul>
		</section>
	)
}

function GettingStarted() {
	const { moveOn } = useSteps()
	return (
		<div className="relative">
			<div className="onboarding-step max-w-5xl overflow-y-scroll bg-white pb-20 lg:h-[612px] lg:rounded-xl lg:border ">
				<div className="max-w-3xl px-4 pt-4 lg:px-12 lg:pt-12">
					<h3 className="mb-6 hidden text-2xl font-medium lg:block">Getting Started</h3>
					<FirstSteps />
				</div>
			</div>
			<div className="mt-3 flex justify-end">
				<Button onClick={() => moveOn()} type="button" className="max-w-max">
					Connect your wallet &nbsp; <MetamaskLogo />
				</Button>
			</div>
		</div>
	)
}

export { GettingStarted }
