import axios from 'services/axios'

const getUser = () => {
	return axios.get('/users/whoami')
}

const userService = {
	getUser
}

export default userService
