import axios from 'axios'
import { clearUser, setUser } from 'utils/local-storage'

const register = (user: RegisterUser) => {
	return axios.post('/users/register', {
		user
	})
}

const login = (email: string, password: string) => {
	return axios
		.post('/users/log_in', {
			user: { email, password }
		})
		.then(response => {
			if (response.data.jwt) {
				setUser({
					email: response.data?.user?.email,
					nickname: response.data?.user?.nickname,
					jwt: response.data?.jwt,
					billing_setup_complete: response.data?.account?.billing_setup_complete,
					trial_applied: response.data?.account?.trial_applied,
					stripe_id: response.data?.account?.stripe_id,
					deployments: response.data?.deployments
				})
			}

			return response.data
		})
}

const confirm = (token: string) => {
	return axios
		.post('/users/confirm/token', {
			token
		})
		.then(response => {
			return response.data
		})
}

const requestResetPassword = async (email: string) => {
	const response = await axios.post('/users/reset_password', {
		user: { email }
	})

	return response.data
}

const resetPassword = async (token: string, password: string, passwordConfirmation: string) => {
	const response = await axios.put(`/users/reset_password/${token}`, {
		user: { password, password_confirmation: passwordConfirmation }
	})

	return response.data
}

const resendConfirmation = (email: string) => {
	return axios
		.post('/users/confirm', {
			user: {
				email
			}
		})
		.then(response => {
			return response.data
		})
}

const logout = () => {
	clearUser()
}

const authService = {
	register,
	login,
	confirm,
	requestResetPassword,
	resetPassword,
	resendConfirmation,
	logout
}

export default authService
