import { AddressElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { forwardRef, useState } from 'react'

import { Button } from 'components/app/button'
import { XCircleIcon } from '@heroicons/react/20/solid'

type SetupProps = {
	returnUrl?: string
	wallets?: string
	project?: string
	customSubmitButton?: React.ReactNode
}

const Alert = ({ error }: { error: string }) => (
	<div className="rounded-md bg-red-50 p-4">
		<div className="flex">
			<div className="flex-shrink-0">
				<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
			</div>
			<div className="ml-3">
				<h3 className="text-sm font-medium text-red-800">
					There were some errors with your submission
				</h3>
				<div className="mt-2 text-sm text-red-700">{error}</div>
			</div>
		</div>
	</div>
)

const StripeSetupForm = forwardRef(({ returnUrl, project, wallets, customSubmitButton }: SetupProps, ref) => {
	const stripe = useStripe()
	const elements = useElements()

	const [errorMessage, setErrorMessage] = useState<undefined | string>(undefined)

	const handleSubmit = async (event: { preventDefault: () => void }) => {
		event.preventDefault()

		if (!stripe || !elements) {
			return
		}

		let returnTo = `${window.location.origin}`

		if (returnUrl) returnTo += returnUrl
		else returnTo += '/billing/successful'

		if (project && wallets) {
			returnTo += `?project=${project}&wallets=${wallets}`
		}

		const { error } = await stripe.confirmSetup({
			elements,
			confirmParams: {
				return_url: returnTo
			},
			redirect: 'always'
		})

		if (error) {
			console.log('error', error)
			setErrorMessage(error.message)
		}
	}

	return (
		<form ref={ref as any} onSubmit={handleSubmit}>
			<AddressElement options={{ mode: 'billing' }} />
			<PaymentElement className="mt-2" />
			{customSubmitButton ? (
				customSubmitButton
			) : (
				<Button
					type="submit"
					disabled={!stripe}
					className="float-right my-4 mt-8 w-20 bg-slate-600 px-6 hover:bg-slate-700 focus:ring-slate-500"
				>
					Submit
				</Button>
			)}
			{errorMessage && <Alert error={errorMessage} />}
		</form>
	)
})

export { StripeSetupForm }
