import { XCircleIcon } from '@heroicons/react/20/solid'

type FormErrorProps = {
	message: string | ResponseError[]
}

export const FormError = ({ message }: FormErrorProps) => {
	const errorMessage = Array.isArray(message)
		? `There were ${message.length} errors with your submission`
		: message
	return (
		<div className="mt-2 rounded-md bg-red-50 p-4">
			<div className="flex">
				<div className="flex-shrink-0">
					<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
				</div>
				<div className="ml-3">
					<h3 className="text-sm font-medium text-red-800">{errorMessage}</h3>
					{Array.isArray(message) && (
						<div className="mt-2 text-sm text-red-700">
							<ul role="list" className="list-disc space-y-1 pl-5">
								{message.map((error, index) => (
									<li key={index}>{error.source + ' ' + error.detail}</li>
								))}
							</ul>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}
