import { useSteps } from 'pages/onboarding'

import { SkipStepButton } from '../skip-step-button'
import { NextButton } from '../next-button'
import { EarningsCalculator } from 'components/dashboard/earnings-calculator-card'

function AcquiringTokens() {
	const { moveOn } = useSteps()
	return (
		<div className="relative">
			<div className="onboarding-step lg:h-[612px] max-w-5xl lg:overflow-scroll lg:rounded-xl lg:border bg-white pb-20 ">
				<div className="max-w-xl px-4 pt-4 lg:px-12 lg:pt-12">
					<h3 className="mb-6 text-2xl font-medium hidden lg:block">Acquiring Tokens</h3>
					<SkipStepButton text="Already have DATA?" />
					<p className="mt-6 text-sm leading-6">
						DATA tokens can be purchased from various centralized and decentralized exchanges. The
						most popular place to buy DATA is on Binance, which also has the most daily liquidity.
					</p>
					<p className="mt-3 text-sm leading-6">
						To start a Streamr node, you need to stake a minimum of 0 DATA tokens per node, with a
						maximum of 20,000 DATA tokens per node.
					</p>
					<p className="mt-3 text-sm leading-6">
						Staking is currently only available on the Polygon Chain. To stake, transfer your DATA
						tokens to a Polygon wallet/account.
					</p>
					<p className="mt-3 mb-6 text-sm font-semibold leading-6">
						Use the zonaris Community Staking Calculator to determine how much DATA you will earn.
					</p>
					<EarningsCalculator light />
				</div>
			</div>
			<NextButton moveOn={() => moveOn()} />
		</div>
	)
}

export { AcquiringTokens }
