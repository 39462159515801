import clsx from 'clsx'

import { MinusCircleIcon } from '@heroicons/react/24/outline'

interface WalletAddressFieldProps {
	index: number
	field: string
	value: string
	invalid: boolean
	duplicate: boolean
	fields: string[]
	onRemoveField: (field: string) => void
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const WalletAddressField = ({
	index,
	field,
	value,
	invalid,
	duplicate,
	fields,
	onChange,
	onRemoveField
}: WalletAddressFieldProps) => {
	const hasMoreThanOneFields = fields.length > 1

	return (
		<div className="w-full">
			<div className="relative mt-2 rounded-md shadow-sm">
				{hasMoreThanOneFields && (
					<p className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
						{index + 1}.
					</p>
				)}
				<input
					type="text"
					id={field}
					onChange={onChange}
					required
					value={value}
					name={field}
					className={clsx(
						'block w-full rounded-md border-0 py-3 text-gray-900 ring-1',
						'ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2',
						'focus:ring-inset  sm:text-sm sm:leading-6',
						invalid || duplicate
							? 'ring-red-500 focus:ring-red-600'
							: 'ring-gray-300 focus:ring-indigo-600',
						hasMoreThanOneFields ? 'px-8' : 'px-4'
					)}
					placeholder="Example (0x12454.....)"
				/>
				{hasMoreThanOneFields && (
					<div
						className="absolute inset-y-0 right-0 flex items-center pr-3"
						onClick={() => onRemoveField(field)}
					>
						<MinusCircleIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
					</div>
				)}
			</div>
			{invalid && (
				<p className="text-xs text-red-500">
					Wallet address must start with <span className="font-semibold">0x</span>
				</p>
			)}
			{duplicate && <p className="text-xs text-red-500">Duplicate wallet address found</p>}
		</div>
	)
}
