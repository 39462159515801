import StreamrLogo from 'assets/logos/svg/streamr'

export type CoinType = {
	id: string
	namePrimary: string
	name: string
	logo: (prop: React.SVGProps<SVGSVGElement>) => JSX.Element
}

export const AvailableCoins: CoinType[] = [
	{
		id: 'DATA',
		namePrimary: 'STREAMR',
		name: 'Streamr/DATA',
		logo: StreamrLogo
	}
]

export const PricePredictions = [
	{ id: '50PercLower', title: 'Super Bearish (-50%)', multiplier: 0.5 },
	{ id: '25PercLower', title: 'Bearish (-25%)', multiplier: 0.75 },
	{ id: 'current', title: 'Current', multiplier: 1 },
	{ id: '50PercHigher', title: 'Bullish (+50%)', multiplier: 1.5 },
	{ id: '100PercHigher', title: 'Very Bullish (+100%)', multiplier: 2 },
	{ id: '500PercHigher', title: 'Super Bullish (+500%)', multiplier: 5 },
	{ id: '1000PercHigher', title: 'Moon (+1000%)', multiplier: 10 }
]
