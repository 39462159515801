import * as React from 'react'

function SvgDashboardIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="h-6 w-6"
			{...props}>
			<path
				d="M10.1125 10.8161C9.21078 10.8161 8.47982 11.5471 8.47982 12.4488C8.47982 13.3505 9.21078 14.0814 10.1125 14.0814C11.0142 14.0814 11.7451 13.3505 11.7451 12.4488C11.7451 11.5471 11.0142 10.8161 10.1125 10.8161ZM10.1125 10.8161V8.36716M10.1125 5.10185V5.91818M3.99004 11.6325H4.80635M5.62268 7.55083L6.1999 8.12806M15.4186 11.6325H16.2349M14.1941 8.12802L14.7713 7.55075M10.1125 3.06104C5.60402 3.06104 1.94922 6.71585 1.94922 11.2243C1.94922 13.359 2.64858 15.484 3.99004 16.9386H16.2349C17.5764 15.484 18.2757 13.359 18.2757 11.2243C18.2757 6.71585 14.6209 3.06104 10.1125 3.06104Z"
				stroke="currentColor"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.1125 10.8161C9.21078 10.8161 8.47982 11.5471 8.47982 12.4488C8.47982 13.3505 9.21078 14.0814 10.1125 14.0814C11.0142 14.0814 11.7451 13.3505 11.7451 12.4488C11.7451 11.5471 11.0142 10.8161 10.1125 10.8161ZM10.1125 10.8161V8.36716M10.1125 5.10185V5.91818M3.99004 11.6325H4.80635M5.62268 7.55083L6.1999 8.12806M15.4186 11.6325H16.2349M14.1941 8.12802L14.7713 7.55075M10.1125 3.06104C5.60402 3.06104 1.94922 6.71585 1.94922 11.2243C1.94922 13.359 2.64858 15.484 3.99004 16.9386H16.2349C17.5764 15.484 18.2757 13.359 18.2757 11.2243C18.2757 6.71585 14.6209 3.06104 10.1125 3.06104Z"
				stroke="currentColor"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_152_10783"
					x1="1.94922"
					y1="3.06104"
					x2="15.6455"
					y2="19.1743"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#7634F9" />
					<stop offset="0.5" stopColor="#3AA6DC" />
					<stop offset="1" stopColor="#10F3C8" />
				</linearGradient>
			</defs>
		</svg>
	)
}

export default SvgDashboardIcon
