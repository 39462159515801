import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type MessageState = {
	message?: string | ResponseError[]
}

const initialState: MessageState = {}

const messageSlice = createSlice({
	name: 'message',
	initialState,
	reducers: {
		setMessage: (state: MessageState, action: PayloadAction<string>) => {
			return {
				...state,
				message: action.payload
			}
		},
		clearMessage: () => {
			return { message: undefined }
		}
	}
})

const { reducer, actions } = messageSlice

export const { setMessage, clearMessage } = actions

export default reducer
