import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Fragment } from 'react'

interface ModalProps {
	onClose?: () => void
	title: string
	className?: string
	defaultStyle?: boolean
	children?: React.ReactNode
	noDefaultClose?: boolean
}

export const Modal: React.FC<ModalProps> = ({ children, title, onClose, noDefaultClose }) => {
	return (
		<Transition.Root show={true} as={Fragment}>
			<Dialog as="div" className="relative z-10 " onClose={() => onClose?.()}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0">
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>
				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="p-4 text-center flex min-h-full items-end justify-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
							<Dialog.Panel className="sm:item-center relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6 lg:w-[600px]">
								<div className="mt-4 flex justify-start sm:flex-auto">
								{title && <h1 className=" ml-2 text-2xl font-semibold leading-6 text-gray-900">{title}</h1> }
									{!noDefaultClose && (
										<div onClick={onClose}>
											<XMarkIcon className="absolute right-4 top-4 h-8  w-7 cursor-pointer text-black" />
										</div>
									)}
								</div>
								{children}
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
