import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import cond from 'cond-construct'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { TrashIcon } from '@heroicons/react/20/solid'
import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { Confirm } from 'components/app/confirm'
import { AppHeader } from 'components/app/header'
import { AppLayout } from 'components/app/layout'
import { SidebarLayout } from 'components/app/sidebar-layout'
import { StripeSetupForm } from 'components/stripe/setup-form'
import { NodePrice } from 'components/app/node-price'
import BillingService from 'services/billing-service'

const stripePromise = loadStripe(window.stripePK ?? 'pk_test')

const BillingCompleted = ({
	billingDetails,
	createCustomerAndSetupIntent,
	setClientSecret,
	setBillingInformation
}: BillingCompletedProps) => {
	const [billingMethods, setBillingMethods] = useState(billingDetails)
	const [showConfirmation, setShowConfirmation] = useState(false)

	const handleBillingMethodDelete = async (paymentMethodId: string) => {
		await BillingService.detach(paymentMethodId)
		const newBillingMethods = billingMethods.filter((el: any) => el.id !== paymentMethodId)
		setBillingMethods(newBillingMethods)
	}

	const handleBillingUpdate = (event: any) => {
		event.preventDefault()
		createCustomerAndSetupIntent(event, setClientSecret)
		setBillingInformation(null)
	}

	return (
		<div className="mt-6 max-w-5xl">
			<Button
				type="submit"
				onClick={handleBillingUpdate}
				className="my-4 mt-8 w-1/3 bg-slate-600 px-6 hover:bg-slate-700 focus:ring-slate-500"
			>
				Add new payment method
			</Button>
			{billingMethods.map((billing: any, index: any) => {
				let { billing_details, card, id } = billing
				let { address } = billing_details

				return (
					<div key={index} className={index == 0 ? 'bg-gray-100 p-4' : 'p-4'}>
						<p className="mb-2 font-bold">Billing Address</p>
						<dl className="grid grid-cols-1 gap-x-2 gap-y-2 sm:grid-cols-2">
							<div key="city" className="sm:col-span-1">
								<dt className="text-sm font-medium text-gray-500">City</dt>
								<dd className="mt-1 text-sm text-gray-900">{address.city}</dd>
							</div>
							<div key="line1" className="sm:col-span-1">
								<dt className="text-sm font-medium text-gray-500">Address 1</dt>
								<dd className="mt-1 text-sm text-gray-900">{address.line1}</dd>
							</div>
							{address.line2 ? (
								<div key="line2" className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500">Address 2</dt>
									<dd className="mt-1 text-sm text-gray-900">{address.line2}</dd>
								</div>
							) : null}
							<div key="postal" className="sm:col-span-1">
								<dt className="text-sm font-medium text-gray-500">Postal code</dt>
								<dd className="mt-1 text-sm text-gray-900">{address.postal_code}</dd>
							</div>
						</dl>
						<p className="mb-2 mt-4 font-bold">Payment Method</p>
						<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
							<div key="last4" className="sm:col-span-1">
								<dt className="text-sm font-medium text-gray-500">Last 4 digits</dt>
								<dd className="mt-1 text-sm text-gray-900">{card.last4}</dd>
							</div>
							<div key="expiration" className="sm:col-span-1">
								<dt className="text-sm font-medium text-gray-500">Expires</dt>
								<dd className="mt-1 text-sm text-gray-900">
									{card.exp_month}/{card.exp_year}
								</dd>
							</div>
						</dl>
						{index > 0 ? (
							<div className="text-right">
								<button
									type="button"
									onClick={() => setShowConfirmation(true)}
									className="inline-flex items-center rounded-md border border-transparent bg-red-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
								>
									<TrashIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
									Delete
								</button>
								<Confirm
									open={showConfirmation}
									setOpen={setShowConfirmation}
									title="Are you sure?"
									body="You are about to delete a payment method. Please confirm your action."
									fun={() => handleBillingMethodDelete(id)}
								/>
							</div>
						) : null}
					</div>
				)
			})}
		</div>
	)
}

const BillingDetailsForm = ({
	stripePromise,
	clientSecret,
	nodeCount,
	project,
	wallets
}: BillingDetailsFormProps) => {
	let options = { clientSecret }

	return (
		<div className="flex flex-col flex-col-reverse lg:flex-row lg:justify-between">
			<div className="lg:mr-20 lg:flex-1">
				<p className="font-semibold">Billing method</p>
				<p className="text-gray-500">Please add your Credit Card details</p>
				<Elements stripe={stripePromise} options={options}>
					<StripeSetupForm wallets={wallets} project={project} />
				</Elements>
			</div>
			<div className="lg:w-1/3">{/* <NodePrice nodeCount={nodeCount ?? 1} /> */}</div>
		</div>
	)
}

type BillingDetailsFormProps = {
	stripePromise?: any
	clientSecret?: string
	nodeCount?: number
	project?: string
	wallets?: string
}

type BillingCompletedProps = {
	billingDetails?: any
	setBillingInformation?: any
	setClientSecret?: any
	createCustomerAndSetupIntent?: any
}

export const Billing = () => {
	const [isLoading, setIsLoading] = useState(true)
	const [clientSecret, setClientSecret] = useState('')
	const [billingInformation, setBillingInformation] = useState<null | any>()
	let [query] = useSearchParams()
	let nodeCount = query.get('node_count') ?? undefined
	let project = query.get('project') ?? undefined
	let wallets = query.get('wallets') ?? undefined

	const createCustomerAndSetupIntent = async () => {
		const { client_secret } = await BillingService.setup()
		setClientSecret(client_secret)
	}

	useEffect(() => {
		async function fetchBillingInfo() {
			let billing = await BillingService.getBilling()
			if (billing?.data) {
				return setBillingInformation(billing?.data)
			}

			// create intent if there's no billing
			createCustomerAndSetupIntent()
		}

		setTimeout(() => {
			fetchBillingInfo().then(() => setIsLoading(false))
		}, 100)
	}, [])

	const renderContent = cond([
		[
			isLoading,
			() => (
				<div className="flex h-96 flex-row items-center justify-center">
					<Spinner className="h-8 w-8" />
				</div>
			)
		],
		[
			!!billingInformation,
			() => (
				<BillingCompleted
					billingDetails={billingInformation}
					setBillingInformation={setBillingInformation}
					setClientSecret={setClientSecret}
					createCustomerAndSetupIntent={() => createCustomerAndSetupIntent()}
				/>
			)
		],
		[
			!!clientSecret,
			() => (
				<BillingDetailsForm
					project={project}
					wallets={wallets}
					nodeCount={nodeCount ? Number(nodeCount) : undefined}
					stripePromise={stripePromise}
					clientSecret={clientSecret as string}
				/>
			)
		]
	])

	return (
		<AppLayout renderHeader={false}>
			<SidebarLayout>
				<div className="hidden md:block">
					<AppHeader auth={true}>
						<h1 className="text-2xl font-semibold text-gray-900">Billing</h1>
					</AppHeader>
				</div>
				<div className="py-6">
					<div className="px-4 sm:px-6 md:px-8">
						<div className="py-4">
							<>{renderContent}</>
						</div>
					</div>
				</div>
			</SidebarLayout>
		</AppLayout>
	)
}
