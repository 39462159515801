import { ArrowLeftIcon } from '@heroicons/react/24/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { Link, useSearchParams } from 'react-router-dom'
import * as yup from 'yup'

import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { Input } from 'components/app/input'
import { AppLayout } from 'components/app/layout'
import { CustomLink } from 'components/app/link'
import authService from 'services/auth-service'
import { parseErrorResponse } from 'services/axios'

import CircleCheckIcon from 'assets/icons/png/circle-check.png'
import EmailIcon from 'assets/icons/png/email.png'
import LockWithEyeIcon from 'assets/icons/png/lock-with-eye.png'

const resetRequestSchema = yup.object().shape({
	email: yup.string().email().required('Please use correct email')
})

const newPasswordSchema = yup.object().shape({
	password: yup
		.string()
		.min(12, 'Password must be at least 12 characters')
		.max(32, 'Password must be at most 32 characters')
		.required(),
	passwordConfirmation: yup
		.string()
		.oneOf([yup.ref('password'), null], 'Those passwords didn’t match. Try again.')
})

export const ResetPassword = () => {
	const [params] = useSearchParams()
	const token = params.get('token')

	return (
		<AppLayout title="Reset Password">
			<div className="mt-8 flex w-full flex-row items-start sm:justify-center">
				<div className="w-full px-6 md:w-2/3 md:px-0">
					<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
						{token ? <CreateNewPasswordForm token={token} /> : <RequestResetPassword />}
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

const RequestResetPassword = () => {
	const [isLoading, setLoading] = useState(false)
	const [message, setMessage] = useState()
	const [email, setEmail] = useState()
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: yupResolver(resetRequestSchema)
	})

	const handleFormSubmit = (data: any) => {
		const { email } = data

		setEmail(email)
		setLoading(true)

		authService
			.requestResetPassword(email)
			.then(data => {
				setMessage(data.message)
			})
			.catch(error => {
				toast.error(error.response.data.message)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	if (message) {
		return <ResetRequestSuccessMessage message={message} email={email} />
	}

	return (
		<div className="space-y-4">
			<div className="flex flex-col space-y-3  text-center sm:mx-auto sm:w-full sm:max-w-md">
				<img className="mx-auto h-28 w-28" src={LockWithEyeIcon} />
				<h2 className="text-3xl font-bold tracking-tight text-gray-900">Forgot your password?</h2>
				<p className="text-lg">No worries, we&apos;ll send you reset instructions.</p>
			</div>
			<form className="space-y-6" onSubmit={handleSubmit(handleFormSubmit)}>
				<Input
					labelText="Email"
					register={register}
					name="email"
					type="email"
					placeholder="Enter your email"
					errors={errors}
				/>
				<Button type="submit">
					{isLoading ? (
						<>
							<Spinner className="h-5 w-5" />
							<span className={'mx-auto animate-pulse'}>Please wait...</span>
						</>
					) : (
						<span>Reset Password</span>
					)}
				</Button>
			</form>
			<div className="text-center">
				<p className="mt-10 text-center">
					Don&apos;t have an account? <CustomLink href="/signup">Sign-Up</CustomLink>
				</p>
				<BackToLoginLink />
			</div>
		</div>
	)
}

type CreateNewPasswordFormProps = {
	token?: string | null
}

const CreateNewPasswordForm = ({ token }: CreateNewPasswordFormProps) => {
	const [isLoading, setLoading] = useState(false)
	const [message, setMessage] = useState()

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: yupResolver(newPasswordSchema)
	})

	const handleFormSubmit = (data: any) => {
		const { password, passwordConfirmation } = data

		setLoading(true)

		authService
			.resetPassword(token as string, password, passwordConfirmation)
			.then(data => {
				setMessage(data.message)
			})
			.catch(error => {
				const errorMessage = parseErrorResponse(error)
				toast.error(errorMessage)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	if (message) {
		return <SetNewPasswordSuccessMessage message={message} />
	}

	return (
		<div className="space-y-4">
			<div className="flex flex-col space-y-4 text-center sm:mx-auto sm:w-full sm:max-w-md">
				<img className="mx-auto h-28 w-28" src={LockWithEyeIcon} />
				<h2 className="text-3xl font-bold tracking-tight text-gray-900">Set new password</h2>
				<div className="text-left text-gray-500">
					<p>Password requirements:</p>
					<ul className="my-2 ml-8 max-w-xs list-disc">
						<li>At least 12 characters</li>
						<li>No parts of your username</li>
						<li>Your password cannot be any of your last passwords</li>
					</ul>
				</div>
			</div>
			<form className="space-y-6" onSubmit={handleSubmit(handleFormSubmit)}>
				<Input
					name="password"
					type="password"
					labelText="New Password*"
					placeholder={'Create a password'}
					errors={errors}
					register={register}
				/>
				<Input
					name="passwordConfirmation"
					type="password"
					placeholder={'Repeat a password'}
					labelText="Repeat Password*"
					register={register}
					errors={errors}
				/>
				<Button type="submit">
					{isLoading ? (
						<>
							<Spinner className="h-5 w-5" />
							<span className={'mx-auto animate-pulse'}>Set new password...</span>
						</>
					) : (
						<span>Reset Password</span>
					)}
				</Button>
			</form>
			<div className="text-center">
				<BackToLoginLink />
			</div>
		</div>
	)
}

type SuccessMessageProps = {
	message: string
	email?: string
}

const SetNewPasswordSuccessMessage = ({ message }: SuccessMessageProps) => {
	return (
		<div className="flex flex-col space-y-4  text-center sm:mx-auto sm:w-full sm:max-w-md">
			<img className="mx-auto h-28 w-28" src={CircleCheckIcon} />
			<h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">Password reset</h2>
			<div>
				<p className="text-lg">Your password has been successfully reset.</p>
				<p>Click below to log in.</p>
			</div>

			<div className="text-center">
				<BackToLoginLink />
			</div>
		</div>
	)
}

const ResetRequestSuccessMessage = ({ message, email }: SuccessMessageProps) => {
	return (
		<div className="flex flex-col space-y-4  text-center sm:mx-auto sm:w-full sm:max-w-md">
			<img className="mx-auto h-28 w-28" src={EmailIcon} />
			<h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">Check your email</h2>
			<p className="text-lg">We sent a password reset link to {email}</p>
			<Link to="/reset-password">
				<Button className="mx-auto max-w-xs bg-gray-900 text-white">
					Resend password reset link{' '}
				</Button>
			</Link>
			<div className="text-center">
				<BackToLoginLink />
			</div>
		</div>
	)
}

const BackToLoginLink = () => {
	return (
		<Link to={'/login'} className="mt-4 inline-flex items-center font-bold">
			<ArrowLeftIcon className="w-4" />
			<span className="ml-2">Back to login</span>
		</Link>
	)
}
