import { ProjectsDropdown } from 'components/project-selection'
import { useSteps } from 'pages/onboarding'
import { NextButton } from './next-button'
import { SupportedProjects } from 'constants/index'

function ChooseProject() {
	const { moveOn, setProject, project } = useSteps()
	return (
		<div className="relative">
			<div className="onboarding-step max-w-5xl bg-white pb-20 lg:h-[612px] lg:overflow-scroll lg:rounded-xl lg:border ">
				<div className="max-w-xl space-y-6 px-4 pt-4 lg:px-12 lg:pt-12">
					<h3 className="mb-6 hidden text-2xl font-medium lg:block">Choose Project</h3>
					<p className="text-md leading-8">Please select a project from the list below:</p>
					<ProjectsDropdown
						defaultProject={project}
						onProjectSelected={project => setProject(project.toLowerCase() as SupportedProjects)}
					/>
				</div>
			</div>
			<NextButton moveOn={() => project && moveOn()} />
		</div>
	)
}

export { ChooseProject }
