import axios from 'services/axios'

export type TimeUnit = 'day' | 'week' | 'month'

type EpochMilliseconds = number

type HistoricalRewardsParams = {
	from: EpochMilliseconds
	to: EpochMilliseconds
	by: TimeUnit
	chain?: string
}

export type Rewards = {
	address: string
	return: number
	timeAggregation: TimeUnit
	timeEnd: string
	timeStart: string
}

type HistoricalRewardsResponse = {
	returns: Rewards[]
}

export const getHistoricalRewards = ({
	from,
	to,
	by,
	chain = 'ethereum'
}: HistoricalRewardsParams): Promise<HistoricalRewardsResponse> => {
	return axios.get(`/reporting/${chain}/historical?from=${from}&to=${to}&by=${by}`)
	.then(res => res.data)
}

export default {
	getHistoricalRewards
}
