import GnosisDeploymentFlow, { StepState } from "components/gnosis-deployment-flow"
import { NextButton } from "../next-button"

import { useSteps } from 'pages/onboarding'
import { useAppSelector } from 'hooks'
import { useState } from "react"

function DeployValidator() {
	const user = useAppSelector(state => state.auth.user)
	const { moveOn } = useSteps()
	const [onboardingState, setOnboardingState] = useState<StepState>('init')
	return (
		<div className="relative">
			<div className="onboarding-step max-w-5xl bg-white pb-20 lg:h-[612px] lg:rounded-xl lg:border ">
				<div className="px-4 pt-4 lg:px-12 lg:pt-12">
					<h3 className="mb-6 hidden text-2xl font-medium lg:block">Deploying Validator</h3>
					<GnosisDeploymentFlow onStateChange={setOnboardingState} />
				</div>
			</div>
			{onboardingState == 'done' && <NextButton moveOn={() => moveOn('project=gnosis')} />}
		</div>
	)
}

export { DeployValidator }
