import { useState } from 'react'
import { Button } from './app/button'
import { Modal } from './app/modal'

import { getHistoricalRewards } from 'services/reporting-service'
import { Spinner } from './animations/spinner'

export const CsvDownloadModal = ({ onClose }: { onClose: () => void }) => {
	const [loading, setLoading] = useState(false)
	const [from, setFrom] = useState('')
	const [to, setTo] = useState('')

	const downloadReport = () => {
		if (!from || !to) return

		let dateFrom = Date.parse(from)
		let dateTo = Date.parse(to)
		if (isNaN(Date.parse(from)) || isNaN(Date.parse(to))) return

		setLoading(true)
		getHistoricalRewards({ from: dateFrom, to: dateTo, by: 'day' })
			.then(({ returns }) => {
				let csvContent = 'data:text/csv;charset=utf-8,from,to,address,return,timeUnit\n'
				for (let row of returns) {
					let start = new Date(row.timeStart).toDateString()
					let end = new Date(row.timeEnd).toDateString()
					csvContent += `${start},${end},${row.address},${row.return},${row.timeAggregation}\n`
				}
				let encodedUri = encodeURI(csvContent)
				let link = document.createElement('a')
				link.setAttribute('href', encodedUri)
				link.setAttribute('download', `rewards.csv`)
				document.body.appendChild(link)
				link.click()
				onClose()
			})
			.finally(() => {
				setLoading(false)
			})
	}

	return (
		<Modal title="Download CSV Report">
			<div className="space-y-4 p-2">
				<div className="space-y-2">
					<label htmlFor="from">From</label>
					<input
						onChange={e => setFrom(e.target.value)}
						type="date"
						name="from"
						id="from"
						className="w-full"
					/>
				</div>
				<div className="space-y-2">
					<label htmlFor="to">To</label>
					<input
						onChange={e => setTo(e.target.value)}
						type="date"
						name="to"
						id="to"
						className="w-full"
					/>
				</div>
				<Button
					disabled={loading}
					className="w-full"
					type="button"
					onClick={(_: any) => downloadReport()}
				>
					{loading ? <Spinner className="h-5 w-5" /> : <>Download CSV</>}
				</Button>
			</div>
		</Modal>
	)
}
