export default function SvgGnosisLogo(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="h-6 w-6"
			{...props}
		>
			<path
				d="M3.95054 10.5442C3.95054 9.81814 4.18955 9.15786 4.60273 8.63428L8.97658 13.0061C8.4439 13.4193 7.78261 13.6583 7.06561 13.6583C5.33795 13.6573 3.94143 12.2618 3.95054 10.5442ZM16.9607 13.6481C18.6793 13.6573 20.0758 12.2607 20.0758 10.5341C20.0758 9.81814 19.8368 9.15684 19.4236 8.62416L15.0497 12.996C15.5733 13.4091 16.2346 13.6481 16.9607 13.6481ZM20.3421 7.72387C20.9852 8.49555 21.371 9.46875 21.371 10.5523C21.371 12.9859 19.3953 14.9606 16.9607 14.9606C15.8863 14.9606 14.9029 14.5748 14.1312 13.9318L12.0086 16.0534L9.88599 13.9318C9.11431 14.5748 8.14009 14.9606 7.0565 14.9606C4.62196 14.9606 2.64619 12.9859 2.64619 10.5523C2.64619 9.47787 3.03203 8.49454 3.67509 7.72387L2.68264 6.73244L1.73678 5.78657C0.633951 7.60437 0 9.72598 0 12.0046C0 18.6357 5.37441 24 11.9995 24C18.6236 24 23.999 18.6266 23.999 12.0046C24.0172 9.71788 23.3833 7.59627 22.2804 5.78657L20.3421 7.72387ZM11.9995 14.2173L2.48011 4.70197C2.74644 4.36171 3.03101 4.03156 3.32571 3.71965C5.51213 1.43297 8.59075 0 12.0086 0C15.4264 0 18.5132 1.43297 20.6915 3.71965C20.9943 4.02244 21.2799 4.36272 21.5188 4.69285L11.9995 14.2173ZM4.63007 4.61995L12.0076 11.9954L19.3851 4.61995C17.4286 2.64517 14.8188 1.56158 12.0076 1.56158C9.19634 1.56158 6.59674 2.64517 4.63007 4.61995Z"
				fill="#133629"
			/>
		</svg>
	)
}

export function SvgGnosisLogoLarge(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_1845_8226)">
				<path
					d="M8.88872 23.7246C8.88872 22.0908 9.42649 20.6052 10.3561 19.4271L20.1973 29.2638C18.9988 30.1934 17.5109 30.7312 15.8976 30.7312C12.0104 30.7289 8.86822 27.5891 8.88872 23.7246ZM38.1616 30.7083C42.0284 30.7289 45.1705 27.5866 45.1705 23.7018C45.1705 22.0908 44.6327 20.6029 43.7032 19.4044L33.8619 29.241C35.0399 30.1705 36.5278 30.7083 38.1616 30.7083ZM45.7697 17.3787C47.2166 19.115 48.0848 21.3047 48.0848 23.7428C48.0848 29.2182 43.6394 33.6614 38.1616 33.6614C35.7441 33.6614 33.5315 32.7932 31.7952 31.3465L27.0194 36.1201L22.2435 31.3465C20.5072 32.7932 18.3152 33.6614 15.8771 33.6614C10.3994 33.6614 5.95392 29.2182 5.95392 23.7428C5.95392 21.3252 6.82206 19.1127 8.26895 17.3787L6.03595 15.148L3.90776 13.0198C1.42639 17.1098 0 21.8835 0 27.0103C0 41.9303 12.0924 54 26.9988 54C41.903 54 53.9978 41.9099 53.9978 27.0103C54.0388 21.8652 52.6124 17.0916 50.1309 13.0198L45.7697 17.3787ZM26.9988 31.9889L5.58024 10.5794C6.17949 9.81384 6.81978 9.07102 7.48285 8.36921C12.4023 3.22419 19.3292 0 27.0194 0C34.7095 0 41.6546 3.22419 46.5559 8.36921C47.2372 9.0505 47.8798 9.81612 48.4174 10.5589L26.9988 31.9889ZM10.4177 10.3949L27.0172 26.9897L43.6165 10.3949C39.2143 5.95163 33.3423 3.51356 27.0172 3.51356C20.6918 3.51356 14.8427 5.95163 10.4177 10.3949Z"
					fill="#133629"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1845_8226">
					<rect width="54" height="54" fill="white" />
				</clipPath>
			</defs>
		</svg>
	)
}
