import { Outlet, useOutletContext } from 'react-router-dom'
import { useAppSelector } from 'hooks'
import { useOnboardingFlow, Steps as StepsType } from 'hooks/useOnboardingFlow'
import { useState, useEffect } from 'react'

import Steps from 'components/onboarding/steps'
import EyeIcon from 'assets/icons/png/eye.png'
import DiscordLogo from 'assets/logos/png/discord.png'
import { VideoTutorial } from 'components/video-tutorial'
import { OnboardingLayout } from 'components/onboarding/layout'
import { NodePrices } from 'components/onboarding/node-prices'

import { projects, SupportedProjects } from 'constants/index'
import WagmiRainbowProvider from 'components/WagmiRainbowProvider'

type ContextType = {
	steps: StepsType
	moveOn: (query?: string) => void
	index: number
	setProject: (project: SupportedProjects) => void
	project: SupportedProjects
}

const JoinDiscordLink = () => {
	return (
		<p className="my-5 flex items-center justify-around text-sm font-medium">
			<img src={DiscordLogo} className="h-7 w-7" />
			<span>
				<a
					className="underline"
					href="https://discord.gg/tBQXAEYs6b"
					target="_blank"
					rel="noreferrer"
				>
					Join our Discord
				</a>{' '}
				for help and support
			</span>
		</p>
	)
}

export const useSteps = () => useOutletContext<ContextType>()

function Onboarding() {
	const user = useAppSelector(state => state.auth?.user)
	const wallets = useAppSelector(state => state.onboarding.wallets)

	const { moveOn, setCurrent, getProjectSteps } = useOnboardingFlow()
	const [steps, setSteps] = useState<StepsType>([])
	const [project, setProject] = useState<SupportedProjects>(projects[0])

	useEffect(() => {
		if (project) {
			setSteps(getProjectSteps(project))
		}
	}, [project])

	let index = steps.findIndex(step => step.status === 'current')

	const moveForward = (query: string) => {
		if (index < 0) return
		moveOn(steps, index, query)
		setSteps(steps)
	}

	useEffect(() => {
		// set project based on url (if any)
		let path = window.location.pathname
		let projectFromPath = path.split('/')[2] as SupportedProjects
		if (projects.includes(projectFromPath)) {
			setProject(projectFromPath)
		}
	}, [window.location.pathname])

	useEffect(() => {
		// set current step based on url
		let path = window.location.pathname
		let index = steps.findIndex(step => step.href == path)
		let newSteps = getProjectSteps(project)
		setSteps(setCurrent(newSteps, index))
	}, [window.location.pathname])

	let greeting = 'Welcome to zonaris!'
	if (user?.nickname) {
		let firstName = user.nickname.split(' ')[0]
		greeting = `Welcome to zonaris, ${firstName}!`
	}

	let showNodePrices =
		(project == 'streamr' && [3, 4].includes(index)) ||
		(project == 'gnosis' && [1, 2, 3, 4].includes(index))

	return (
		<OnboardingLayout title="Home">
			<section className="relative mx-auto max-w-[1140px] pt-10">
				<div className="flex items-center justify-start">
					<img src={EyeIcon} className="h-20 w-20" />
					<h2 className="ml-6 text-4xl font-medium text-slate-700">{greeting}</h2>
				</div>
				<div className="mt-6 flex flex-col lg:flex-row">
					<Steps className="lg:hidden" steps={steps} />
					<main className="relative max-w-5xl flex-1 ">
						<WagmiRainbowProvider>
							<Outlet context={{ steps, moveOn: moveForward, index, setProject, project }} />
						</WagmiRainbowProvider>
					</main>
					<aside className="w-1/3 px-4 lg:px-8">
						<Steps className="hidden lg:block" steps={steps} />
						{showNodePrices && (
							<NodePrices project={project} count={wallets.length} className="mt-10" />
						)}
						{showNodePrices && project == 'gnosis' && (
							<p className="mt-4 text-sm font-light italic leading-6">
								Join our Beta launch at an exclusive price of just{' '}
								<span className="font-semibold">€1</span> per validator, limited to our first 100
								validators! Future pricing will adapt to market changes. Get started on the Gnosis
								Chain with our simple, non-custodial, 1-click solution and benefit from APR today!
							</p>
						)}
						{project == 'streamr' && (
							<VideoTutorial index={index} className={`${showNodePrices ? 'mt-6' : 'mt-20'}`} />
						)}
						{index != 0 && <JoinDiscordLink />}
					</aside>
				</div>
			</section>
		</OnboardingLayout>
	)
}

export { Onboarding }
