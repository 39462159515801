import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'

import { ProtectedRoute } from 'components/auth/protected-route'
import { useAppSelector } from 'hooks'
import { PageNotFound } from 'pages/404'
import { AccountConfirmation } from 'pages/auth/confirmation'
import { Login } from 'pages/auth/login'
import { Register } from 'pages/auth/register'
import { ResetPassword } from 'pages/auth/reset-password'
import { VerifyEmail } from 'pages/auth/verify-email'
import { Billing } from 'pages/billing'
import { BillingSuccessful } from 'pages/billing/billing-successful'
import { StakingCalculator } from 'pages/calculator'
import { EmbeddedStakingCalculator } from 'pages/calculator/embedded'
import { FAQs } from 'pages/faqs'
import { Dashboard } from 'pages/home/dashboard'
import { Onboarding } from 'pages/onboarding'
import { CreateNode } from 'pages/nodes/create'
import { ListNodes } from 'pages/nodes/list'
import { Reports } from 'pages/reports'

import { AcquiringTokens } from 'components/onboarding/streamr/acquiring-tokens'
import { CreateWallet } from 'components/onboarding/streamr/wallet'
import { StartEarning } from 'components/onboarding/streamr/start-earning'
import { Billing as OnboardingBilling } from 'components/onboarding/billing'
import { BillingSuccess } from 'components/onboarding/billing-success'
import { ChooseProject } from 'components/onboarding/choose-project'
import { GettingStarted } from 'components/onboarding/gnosis/getting-started'
import { DeployValidator } from 'components/onboarding/gnosis/deploy-validator'
import { GettingStarted as EthGettingStarted } from 'components/onboarding/ethereum/getting-started'
import { DeployValidator as EthDeployValidator} from 'components/onboarding/ethereum/deploy-validator'

export const Routing = () => {
	const auth = useAppSelector(state => state.auth)

	const redirectToOnboarding =
		auth.user?.jwt &&
		auth.user?.deployments?.number_of_nodes === 0 &&
		!auth.user?.billing_setup_complete

	const redirectToDashboard = auth.user?.jwt
		&& auth.user?.billing_setup_complete

	return (
		<BrowserRouter>
			<Routes>
				<Route path="login" element={<Login />} />
				<Route path="signup" element={<Register />} />
				<Route path="register/confirmation" element={<AccountConfirmation />} />
				<Route path="register/verify-email" element={<VerifyEmail />} />
				<Route path="reset-password" element={<ResetPassword />} />
				<Route path="billing/successful" element={<BillingSuccessful />} />

				<Route
					path="/onboarding"
					element={
						<ProtectedRoute user={auth.user}>
							<Onboarding />
						</ProtectedRoute>
					}
				>
					<Route path="choose-project" element={<ChooseProject />} />
					<Route path="streamr">
						<Route path="get-tokens" element={<AcquiringTokens />} />
						<Route path="wallet" element={<CreateWallet />} />
						<Route path="start-earning" element={<StartEarning />} />
						<Route path="add-billing" element={<OnboardingBilling />} />
						<Route path="billing-success" element={<BillingSuccess />} />
					</Route>
					<Route path="gnosis">
						<Route path="getting-started" element={<GettingStarted />} />
						<Route path="deploy-validator" element={<DeployValidator />} />
						<Route path="add-billing" element={<OnboardingBilling />} />
						<Route path="billing-success" element={<BillingSuccess />} />
					</Route>
					<Route path="ethereum">
						<Route path="getting-started" element={<EthGettingStarted />} />
						<Route path="deploy-validator" element={<EthDeployValidator />} />
					</Route>
				</Route>

				<Route
					path="/"
					element={
						redirectToOnboarding ? (
							<Navigate to="/onboarding/choose-project" />
						) : redirectToDashboard ? (
							<ProtectedRoute user={auth.user}>
								<Dashboard />
							</ProtectedRoute>
						) : (
							<Login />
						)
					}
				/>
				<Route
					path="home"
					element={
						redirectToOnboarding ? (
							<Navigate to="/onboarding/choose-project" />
						) : redirectToDashboard ? (
							<ProtectedRoute user={auth.user}>
								<Dashboard />
							</ProtectedRoute>
						) : (
							<Login />
						)
					}
				/>
				<Route
					path="billing"
					element={
						<ProtectedRoute user={auth.user}>
							<Billing />
						</ProtectedRoute>
					}
				/>
				<Route
					path="dashboard"
					element={
						<ProtectedRoute user={auth.user}>
							<Dashboard />
						</ProtectedRoute>
					}
				/>
				<Route
					path="reports"
					element={
						<ProtectedRoute user={auth.user}>
							<Reports />
						</ProtectedRoute>
					}
				/>
				<Route
					path="nodes"
					element={
						<ProtectedRoute user={auth.user}>
							<ListNodes />
						</ProtectedRoute>
					}
				/>
				<Route
					path="nodes/create"
					element={
						<ProtectedRoute user={auth.user}>
							<CreateNode />
						</ProtectedRoute>
					}
				/>
				<Route path="/calculator" element={<StakingCalculator />} />
				<Route path="/embedded/calculator" element={<EmbeddedStakingCalculator />} />

				<Route path="/faqs" element={<FAQs />} />

				<Route path="*" element={<PageNotFound />} />
			</Routes>
		</BrowserRouter>
	)
}
