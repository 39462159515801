import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AuthService from 'services/auth-service'
import UserService from 'services/user-service'
import { parseErrorResponse } from 'services/axios'
import { getUser, setUser } from 'utils/local-storage'
import { setMessage } from './message'

type AuthState = {
	user?: User
}

const user = getUser()
const initialState: AuthState = user && user.jwt ? { user } : { user: undefined }

export const register = createAsyncThunk(
	'auth/register',
	async ({ user }: { user: RegisterUser }, thunkAPI) => {
		try {
			const response = await AuthService.register(user)
			thunkAPI.dispatch(setMessage(response.data.message))
			return response.data
		} catch (error: any) {
			console.log(error)
			let message = parseErrorResponse(error)

			thunkAPI.dispatch(setMessage(message))
			return thunkAPI.rejectWithValue(false)
		}
	}
)

export const refreshUser = createAsyncThunk(
	'auth/refreshUser',
	async (_, thunkAPI) => {
		try {
			const { data } = await UserService.getUser()
			const user = convertToUser(data.data) as User
			return { user }
		} catch (error: any) {
			const message = parseErrorResponse(error)

			thunkAPI.dispatch(setMessage(message))
			return thunkAPI.rejectWithValue(false)
		}
	}
)

export const login = createAsyncThunk(
	'auth/login',
	async ({ email, password }: { email: string; password: string }, thunkAPI) => {
		try {
			const response = await AuthService.login(email, password)
			const user = convertToUser(response) as User
			// store user
			return { user }
		} catch (error: any) {
			const message = parseErrorResponse(error)

			thunkAPI.dispatch(setMessage(message))
			return thunkAPI.rejectWithValue(false)
		}
	}
)

export const confirm = createAsyncThunk(
	'auth/confirm',
	async ({ token }: { token: string }, thunkAPI) => {
		try {
			const response = await AuthService.confirm(token)
			// store user
			const user = convertToUser(response) as User
			setUser(user)

			return { user }
		} catch (error: any) {
			const message = parseErrorResponse(error)

			thunkAPI.dispatch(setMessage(message))
			return thunkAPI.rejectWithValue(false)
		}
	}
)

export const logout = createAsyncThunk('auth/logout', async () => {
	AuthService.logout()
})

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		resetAuth: () => initialState
	},
	extraReducers: builder => {
		builder.addCase(register.fulfilled, (state: AuthState, action: PayloadAction<any>) => {}),
			builder.addCase(register.rejected, (state: AuthState, action: PayloadAction<any>) => {}),
			builder.addCase(
				login.fulfilled,
				(state: AuthState, action: PayloadAction<{ user: User }>) => {
					state.user = action.payload.user
				}
			),
			builder.addCase(login.rejected, (state: AuthState, action: PayloadAction<any>) => {
				state.user = undefined
			}),
			builder.addCase(
				refreshUser.fulfilled,
				(state: AuthState, action: PayloadAction<{ user: User }>) => {
					let billing_setup_complete = action.payload.user.billing_setup_complete
					if (state.user) {
						// mutate select fields
						state.user.billing_setup_complete = billing_setup_complete
					}
				}
			),
			builder.addCase(refreshUser.rejected, (state: AuthState, action: PayloadAction<any>) => {
				state.user = undefined
			}),
			builder.addCase(logout.fulfilled, (state: AuthState, action: PayloadAction<any>) => {
				state.user = undefined
			}),
			builder.addCase(
				confirm.fulfilled,
				(state: AuthState, action: PayloadAction<{ user: User }>) => {
					state.user = action.payload.user
				}
			)
	}
})

const convertToUser = (res: any) => {
	return {
		email: res.user?.email,
		nickname: res.user?.nickname,
		jwt: res.jwt,
		billing_setup_complete: res.account?.billing_setup_complete,
		trial_applied: res.account?.trial_applied,
		stripe_id: res.account?.stripe_id,
		deployments: res.deployments
	}
}

const { reducer } = authSlice

export default reducer
