// stolen from https://github.com/gnosischain/gbc-deposit-ui/blob/main/src/constants.js
const NETWORKS = {
  gnosis: {
    forkVersion: "00000064",
    chainId: 100,
    name: 'Gnosis Chain',
    symbol: 'XDAI',
    chainName: 'Gnosis Chain',
    rpcUrl: 'https://rpc.gnosischain.com',
    blockExplorerUrl: 'https://gnosisscan.io/',
    beaconExplorerUrl: 'https://gnosischa.in',
    addresses: {
      token: '0x9C58BAcC331c9aa871AFD802DB6379a98e80CEdb',
      deposit: '0x0B98057eA310F4d31F2a452B414647007d1645d9',
      dappnodeDeposit: '0x6C68322cf55f5f025F2aebd93a28761182d077c3',
    },
    depositStartBlockNumber: 22673201,
  },
  chiado: {
    forkVersion: "0000006f",
    chainId: 10200,
    name: 'Chiado',
    symbol: 'CHI',
    chainName: 'Chiado Testnet',
    rpcUrl: 'https://rpc.chiadochain.net',
    blockExplorerUrl: 'https://blockscout.com/gnosis/chiado',
    beaconExplorerUrl: 'https://beacon.chiadochain.net',
    addresses: {
      token: '0x19C653Da7c37c66208fbfbE8908A5051B57b4C70',
      deposit: '0xb97036A26259B7147018913bD58a774cf91acf25',
    },
    depositStartBlockNumber: 0,
  },
	ethereum: {
    addresses: {
      deposit: '0x00000000219ab540356cBB839Cbe05303d7705Fa',
			token: ''
    },
    depositStartBlockNumber: 0,
    beaconExplorerUrl: 'https://beaconcha.in',
	},
	holesky: {
		addresses: {
			deposit: '0x4242424242424242424242424242424242424242',
			token: ''
		},
    beaconExplorerUrl: 'https://holesky.beaconcha.in',
	}
} as const

const METAMASK_CHAIN_CONFIGS = {
	gnosis: {
		chainId: '0x64',
		rpcUrls: ['https://rpc.gnosischain.com'],
		chainName: 'Gnosis Chain',
		blockExplorerUrls: ['https://blockscout.com/xdai/mainnet/'],
		nativeCurrency: {
			name: 'xDai',
			symbol: 'xDai',
			decimals: 18
		}
	},
	ethereum: {
		chainId: '0x1',
		rpcUrls: ['https://cloudflare-eth.com'],
		chainName: 'Holesky Chain',
		blockExplorerUrls: ['https://beaconcha.in'],
		nativeCurrency: {
			name: 'Ethereum (ETH)',
			symbol: 'ETH',
			decimals: 18
		}
	},
	holesky: {
		chainId: '0x4268' ,
		rpcUrls: ['https://holesky.rpc.thirdweb.com'],
		chainName: 'Holesky Chain',
		blockExplorerUrls: ['https://holesky.beaconcha.in'],
		nativeCurrency: {
			name: 'Testnet ETH',
			symbol: 'ETH',
			decimals: 18
		}
	},
	chiado: {
		chainId: '0x27d8',
		rpcUrls: ['https://rpc.gnosischain.com'],
		chainName: 'Chiado Chain',
		blockExplorerUrls: ['https://blockscout.com/xdai/mainnet/'],
		nativeCurrency: {
			name: 'xDai',
			symbol: 'xDai',
			decimals: 18
		}
	}
} as const

export type SupportedNetworks = keyof typeof NETWORKS

export {
  NETWORKS,
	METAMASK_CHAIN_CONFIGS
}
