import React from 'react'

import { useDocumentTitle } from 'hooks/useDocumentTitle'
import { AppHeader } from './header'

interface AppLayoutProps {
	children: React.ReactNode
	title?: string
	docTitle?: boolean
	renderHeader?: boolean
	renderBanner?: boolean
}

export const AppLayout: React.FC<AppLayoutProps> = ({
	children,
	title,
	renderHeader = true
}) => {
	const docTitleText = title
		? `${title} | zonaris`
		: 'zonaris - Dashboard for managing storage and billing for the zonaris product'

	useDocumentTitle(docTitleText)

	const renderMainLayoutElements = () => {
		if (!renderHeader) return null
		return (
			<AppHeader />
		)
	}

	return (
		<div className="relative">
			{renderMainLayoutElements()}
			{children}
		</div>
	)
}
