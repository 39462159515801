import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { Fragment, useState } from 'react'

import StreamrLogo from 'assets/logos/svg/streamr'
import GnosisLogo from 'assets/logos/svg/gnosis'
import { SupportedProjects } from 'constants/index'
import EthLogo from 'assets/logos/svg/ethereum'

export const currentProjects = [
	{
		id: 'Gnosis',
		name: 'Gnosis (GNO)',
		logo: GnosisLogo,
		disabled: false
	},
	{
		id: 'Ethereum',
		name: 'Ethereum (ETH)',
		logo: EthLogo,
		disabled: false
	},
	{
		id: 'Streamr',
		name: 'Streamr (DATA)',
		logo: StreamrLogo,
		disabled: true
	},
] as const

export type SelectedProject = (typeof currentProjects)[number]['id']

interface ProjectsDropdownProps {
	onProjectSelected: (projectName: SelectedProject) => void
	defaultProject?: SupportedProjects
}

export const ProjectsDropdown = ({ defaultProject, onProjectSelected }: ProjectsDropdownProps) => {
	let defaultSelection = currentProjects.find(
		project => project.id.toLowerCase() === defaultProject
	)
	const [selected, setSelected] = useState(defaultSelection || currentProjects[0])

	const onProjectChange = (project: typeof selected) => {
		setSelected(project)
		onProjectSelected(project.id)
	}

	return (
		<Listbox value={selected} onChange={onProjectChange}>
			{({ open }) => (
				<>
					<div className="relative w-full rounded-md border">
						<Listbox.Button className="flex w-full cursor-pointer items-center justify-between rounded-md bg-white px-4 py-2 text-left text-sm text-gray-900 ring-gray-300 ring-transparent focus:outline-none focus:ring-2 sm:text-sm sm:leading-6">
							<span className="flex items-center">
								<selected.logo className="h-5 w-5 text-[#F65F0A]" />
								<span className="ml-2 block truncate">
									{selected.name}
									{(selected.name == 'Gnosis (GNO)' || selected.name == 'Ethereum (ETH)') && (
										<span className="ml-2 text-xs font-semibold text-[#6340DE]">NEW</span>
									)}
								</span>
							</span>
							<span className="ml-2 flex items-center pr-2">
								<ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
							</span>
						</Listbox.Button>

						<Transition
							show={open}
							as={Fragment}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
								{currentProjects.map(project => project.disabled ? null : (
									<Listbox.Option
										key={project.id}
										className={({ active }) =>
											clsx(
												active ? 'bg-gray-200' : '',
												'relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900'
											)
										}
										value={project}
									>
										{({ selected, active }) => (
											<>
												<div className="flex items-center">
													<project.logo className="h-5 w-5 text-[#F65F0A]" />
													<span
														className={clsx(
															selected ? 'font-semibold' : 'font-normal',
															'ml-3 block truncate'
														)}
													>
														{project.name}
													</span>
													{(project.name == 'Gnosis (GNO)' || project.name == 'Ethereum (ETH)') && (
														<span className="ml-2 text-xs font-semibold text-[#6340DE]">NEW</span>
													)}
												</div>

												{selected ? (
													<span
														className={clsx(
															active ? 'text-white' : 'text-indigo-600',
															'absolute inset-y-0 right-0 flex items-center pr-4'
														)}
													>
														<CheckIcon className="h-5 w-5" aria-hidden="true" />
													</span>
												) : null}
											</>
										)}
									</Listbox.Option>
								))}
							</Listbox.Options>
						</Transition>
					</div>
				</>
			)}
		</Listbox>
	)
}
