type BillingURLParams = {
	node_count: number
	wallets: string[]
	project: string
}

export const buildBillingURL = (params: BillingURLParams) => {
	let { node_count, wallets, project } = params
	let base = '/billing'

	base += `?node_count=${node_count}`
	base += `&wallets=${wallets.join()}`
	base += `&project=${project}`

	return base
}
