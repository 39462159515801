import React, { useEffect, useState } from 'react'

import { PricePredictionsDropdown } from 'components/dropdowns/price-prediction-selection'
import { CoinSelection } from 'components/inputs/coins-input'
import { AvailableCoins } from 'constants/streamr'
import thirdPartyService from 'services/third-party'
import { getNumLocaleString } from 'utils/string'
import { DEFAULT_CURRENCY } from 'utils/currency'


// standalone calculator that is used on the landing site
export const EmbeddedStakingCalculator = () => {
	const [numTokens, setNumTokens] = useState(0)
	const [pricePrediction, setPricePrediction] = useState(1)
	const [coin, setCoin] = useState(AvailableCoins[0])
	const [coinPrice, setCoinPrice] = useState(0)
	const [spotAPR, setSpotAPR] = useState(0)

	useEffect(() => {
		thirdPartyService.getCoinPrice(coin.namePrimary).then(data => {
			const price = data[coin.namePrimary.toLowerCase()][DEFAULT_CURRENCY]
			setCoinPrice(price)
		})
	}, [coin])

	useEffect(() => {
		thirdPartyService.getAPY().then(data => {
			const spotAPR = data['spot-APR']
			setSpotAPR(spotAPR)
		})
	}, [])

	useEffect(() => {
		let html = window.document.querySelector('head')
		let body = window.document.querySelector('body')

		if (!html || !body) return
		html.style.height = 'auto'
		body.style.height = 'auto'

		return () => {
			if (!html || !body) return
			html.style.height = '100%'
			body.style.height = '100%'
		}
	}, [])

	const handleNumTokensChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNumTokens(event.target.valueAsNumber || 0)
	}

	const actualSpotAPR = spotAPR / 100

	// Calculate monthly and annual data token earnings
	const dailyDataEarnings = (numTokens * actualSpotAPR) / 365
	const monthlyDataEarnings = (numTokens * actualSpotAPR) / 12
	const annualDataEarnings = numTokens * actualSpotAPR

	// Calculate monthly and annual USD earnings
	const dailyUSDEarnings = dailyDataEarnings * coinPrice * pricePrediction
	const monthlyUSDEarnings = monthlyDataEarnings * coinPrice * pricePrediction
	const annualUSDEarnings = annualDataEarnings * coinPrice * pricePrediction

	return (
		<div className="flex w-full flex-row items-start sm:justify-center">
			<div className="w-full px-6 md:w-2/3 md:px-0">
				<div className="sm:mx-auto sm:w-full sm:max-w-5xl">
					<div className="w-full rounded-lg bg-gray-100 shadow-md sm:py-10 sm:px-12">
						<div className="flex flex-col justify-between space-x-0 p-2 md:flex-row md:space-x-10  md:p-0">
							<div className="w-full md:w-3/5">
								<h2 className="mb-10 text-xl font-bold">How many {coin.id} do you have?</h2>
								<div className="space-y-8">
									<div className="max-w-lg space-y-1">
										<label htmlFor="coins" className="flex items-center justify-between">
											<span>Select and enter your coins</span>
											<span>${coinPrice}</span>
										</label>
										<CoinSelection
											onCoinInputChange={handleNumTokensChange}
											onCoinSelected={setCoin}
										/>
									</div>
									<div className="max-w-lg space-y-1">
										<label htmlFor="pricePrediction" className="flex items-center justify-between">
											<span>Price Prediction</span>
										</label>
										<PricePredictionsDropdown onPredictionSelected={setPricePrediction} />
									</div>
								</div>
								<div className="mt-4">
									<p className="text-gray-500">
										Streamr’s published APR{' '}
										<span className="text-lg font-semibold text-green-500">
											{getNumLocaleString(spotAPR, 2)}%
										</span>
									</p>
								</div>
							</div>
							<div className="mt-4 ml-auto flex space-x-4 space-y-0 rounded-lg bg-[#F9FAFB] sm:w-full sm:py-6 sm:px-8 md:mt-0 md:block md:w-1/3 md:space-x-0 md:space-y-6">
								<EarningCard
									title="Daily earnings"
									coin={coin.id}
									tokens={dailyDataEarnings}
									earnings={dailyUSDEarnings}
								/>
								<EarningCard
									title="Monthly earnings"
									coin={coin.id}
									tokens={monthlyDataEarnings}
									earnings={monthlyUSDEarnings}
								/>
								<EarningCard
									title="Yearly earnings"
									coin={coin.id}
									tokens={annualDataEarnings}
									earnings={annualUSDEarnings}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

type EarningCardProps = {
	title: string
	coin: string
	tokens: number
	earnings: number
}

const EarningCard = ({ title, coin, tokens, earnings }: EarningCardProps) => {
	return (
		<div className="space-y-.5">
			<p className="text-sm text-gray-500">{title}</p>
			<p className="text-sm font-medium md:text-lg md:font-semibold">{`${getNumLocaleString(
				tokens,
				2
			)} ${coin}`}</p>
			<p className="text-sm text-gray-500">{`$${Number(earnings.toFixed(2)).toLocaleString()}`}</p>
		</div>
	)
}
