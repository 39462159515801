import { NextButton } from '../next-button'

import { useState } from 'react'
import EthereumDeploymentFlow, { StepState } from 'components/ethereum-deployment-flow'
import { useNavigate } from 'react-router-dom'

function DeployValidator() {
	const navigate = useNavigate()
	const [onboardingState, setOnboardingState] = useState<StepState>('init')
	return (
		<div className="relative">
			<div className="onboarding-step max-w-5xl bg-white pb-20 lg:h-[612px] lg:rounded-xl lg:border ">
				<div className="px-4 pt-4 lg:px-12 lg:pt-12">
					<h3 className="mb-6 hidden text-2xl font-medium lg:block">Deploying Validator</h3>
					<EthereumDeploymentFlow onStateChange={setOnboardingState} />
				</div>
			</div>
			{onboardingState == 'done' && (
				<NextButton moveOn={() => navigate('/dashboard?from_onboarding=1')} />
			)}
		</div>
	)
}

export { DeployValidator }
