import { useSteps } from 'pages/onboarding'

function SkipStepButton({ text }: { text: string }) {
	const { moveOn, steps, index } = useSteps()

	return (
		<p className="flex justify-between border rounded-lg px-4 py-2 text-sm font-medium">
			{text}
			<button
				className="text-indigo-600 underline"
				type="button"
				onClick={() => moveOn()}
			>
				Skip step
			</button>
		</p>
	)
}

export { SkipStepButton }
