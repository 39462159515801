import { useSteps } from 'pages/onboarding'

import { Button } from 'components/app/button'
import MetamaskLogo from 'assets/logos/svg/metamask'

export function FirstSteps() {
	return (
		<section className="pb-6 font-light">
			<p className="mt-4 text-sm leading-6">
				Welcome to Ethereum staking by running validator nodes for which you will be rewarded with
				ETH. Your participation will be securing and decentralising the Ethereum chain network.{' '}
			</p>
			<p className="mt-2 text-sm leading-6">
				The process with zonaris is simple and completely{' '}
				<span className="font-medium">Non-Custodial.</span> You are fully in charge of your
				validator nodes and staking rewards are going directly to your MetaMask wallet. We only
				provide the simple process and infrastructure required to make it as easy as possible.
			</p>
			<p className="mt-2 text-sm leading-6">
				In order to get started, you’ll first need to make sure you have the proper setup to start
				staking on the Ethereum Chain. Please review the information and checklist below to ensure a
				smooth experience:
			</p>
			<ul className="ml-6 mt-6 list-disc space-y-4">
				<li>
					<h4 className="text-sm font-medium leading-6">Metamask wallet extension installed.</h4>
					<p className="text-sm leading-6">
						In case you don’t, here’s a quick guide to get you setup -{' '}
						<a
							href="https://support.metamask.io/hc/en-us/articles/360015489531"
							target="_blank"
							rel="noreferrer"
							className="text-[#6340DE] underline"
						>
							link
						</a>
					</p>
				</li>
				<li>
					<p className="text-sm leading-6">
						<span className="font-medium">At least 32 ETH in your wallet </span>
						(the minimum number of tokens required to run an Ethereum Chain validator is 32
						ETH.) If you’d like to stake more than 32 ETH/Run more than 1 validator, you’ll have to repeat
						this process for each one.
					</p>
				</li>
				<li>
					<p className="text-sm leading-6">
						<span className="font-medium">A few additional ETH in your Metamask wallet</span> on the
						Ethereum Chain network to process the transaction.
					</p>
				</li>
			</ul>
		</section>
	)
}

function GettingStarted() {
	const { moveOn } = useSteps()
	return (
		<div className="relative">
			<div className="onboarding-step max-w-5xl overflow-y-scroll bg-white pb-20 lg:h-[612px] lg:rounded-xl lg:border ">
				<div className="max-w-3xl px-4 pt-4 lg:px-12 lg:pt-12">
					<h3 className="mb-6 hidden text-2xl font-medium lg:block">Getting Started</h3>
					<FirstSteps />
				</div>
			</div>
			<div className="mt-3 flex justify-end">
				<Button onClick={() => moveOn()} type="button" className="max-w-max">
					Connect your wallet &nbsp; <MetamaskLogo />
				</Button>
			</div>
		</div>
	)
}

export { GettingStarted }
