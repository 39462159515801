import { Modal } from 'components/app/modal'
import { HazyGreen, HazyPurple } from 'components/hazy-color-blobs'
import chartImage from 'assets/images/circle-click.png'

type CongratsModalProps = {
	onClose: () => void
}

const CongratsModal = ({ onClose }: CongratsModalProps) => {
	return (
		<Modal onClose={onClose} title="">
			<HazyPurple modal />
			<div className="flex flex-col items-center justify-start text-center">
				<img
					src={chartImage}
					width="130"
					height="130"
					alt="pie chart with a cursor pointed at it"
				/>
				<h3 className="mt-2 text-[28px] font-medium">
					Congratulations on successfully setting up your nodes!
				</h3>
				<p className="mt-2 text-lg">
					Don't forget to join our community on{' '}
					<a
						href="https://discord.gg/tBQXAEYs6b"
						className="underline"
						target="_blank"
						rel="noreferrer"
					>
						Discord
					</a>{' '}
					and{' '}
					<a
						href="https://t.me/+kjik5PmPjYJmNGY0"
						className="underline"
						target="_blank"
						rel="noreferrer"
					>
						Telegram.
					</a>
				</p>
				<button
					type="button"
					onClick={onClose}
					className="mt-10 mb-4 rounded-lg bg-[#282828] px-4 py-3 font-semibold text-white"
				>
					Go to dashboard
				</button>
			</div>
			<HazyGreen modal />
		</Modal>
	)
}

export { CongratsModal }
