import { worker } from 'mocks/browser'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Toaster } from 'react-hot-toast'
import { Provider } from 'react-redux'
import { Routing } from 'routing'

import store from 'store'

import 'styles/helper.css'
import 'styles/main.css'

const root = ReactDOM.createRoot(document.getElementById('zonaris-root') as HTMLElement)

async function main() {
	if (process.env.NODE_ENV === 'development') {
		if (window.location.pathname === '/login') {
			return (window.location.pathname = '/login/')
		}

		if (window.location.pathname === '/register') {
			return (window.location.pathname = '/register/')
		}

		await worker.start({
			serviceWorker: {
				url: '/mockServiceWorker.js'
			}
		})
	}
}

// main()

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<Toaster
				position={'top-right'}
				toastOptions={{
					style: {
						margin: '15px',
						background: '#828282',
						color: '#fff',
						width: '340px'
					},
					className: 'text-base',
					duration: 3000
				}}
			/>
			<Routing />
		</Provider>
	</React.StrictMode>
)
