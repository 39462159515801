import clsx from 'clsx'
import { SupportedProjects } from 'constants/index'
import { useEffect, useState } from 'react'

import nodeService from 'services/node-service'
import { getNumLocaleString } from 'utils/string'

type RewardProps = {
	stats: ProjectStats[]
}

export const Rewards = ({ stats }: RewardProps) => {
	const textColor = (val: number) => {
		if (val < 0) return 'text-red-400'
		if (val > 0) return 'text-green-400'
		return 'text-gray-800'
	}

	return (
		<div className="max-w-4xl flex-1 rounded-lg border p-6">
			<h2 className="text-base font-medium lg:text-xl">Rewards Value</h2>
			<dl className="mt-4 grid w-full grid-cols-2 content-end justify-between gap-y-4 lg:flex lg:gap-y-4">
				<div className={clsx('flex flex-col justify-center space-y-2 pr-8')}>
					<dt className="leading-normal text-gray-800">Token</dt>
					{stats.map((s, i) => (
						<dd
							key={i}
							className={clsx(
								'text-sm font-[22px] uppercase tracking-tight text-gray-900 md:text-2xl lg:text-lg'
							)}
						>
							{s.token}
						</dd>
					))}
				</div>
				<div className={clsx('flex flex-col justify-center space-y-2 pr-8')}>
					<dt className="leading-normal text-gray-800">Net Worth</dt>
					{stats.map(s => (
						<dd
							key={s.project + '-networth'}
							className={clsx(
								'text-sm font-[22px] tracking-tight text-gray-900 md:text-2xl lg:text-lg',
								textColor(s.networth)
							)}
						>
							${getNumLocaleString(s.networth, 2)}
						</dd>
					))}
				</div>
				<div className={clsx('flex flex-col justify-center space-y-2 pr-8')}>
					<dt className="leading-normal text-gray-800">Daily</dt>
					{stats.map(s => (
						<dd
							key={s.project + '-daily'}
							className={clsx(
								'text-sm font-[22px] tracking-tight text-gray-900 md:text-2xl lg:text-lg',
								textColor(s.daily)
							)}
						>
							${getNumLocaleString(s.daily, 2)}
						</dd>
					))}
				</div>
				<div className={clsx('flex flex-col justify-center space-y-2 pr-8')}>
					<dt className="leading-normal text-gray-800">Weekly</dt>
					{stats.map(s => (
						<dd
							key={s.project + '-weekly'}
							className={clsx(
								'text-sm font-[22px] tracking-tight text-gray-900 md:text-2xl lg:text-lg',
								textColor(s.weekly)
							)}
						>
							${getNumLocaleString(s.weekly, 2)}
						</dd>
					))}
				</div>
				<div className={clsx('flex flex-col justify-center space-y-2 pr-8')}>
					<dt className="leading-normal text-gray-800">Monthly</dt>
					{stats.map(s => (
						<dd
							key={s.project + '-monthly'}
							className={clsx(
								'text-sm font-[22px] tracking-tight text-gray-900 md:text-2xl lg:text-lg',
								textColor(s.monthly)
							)}
						>
							${getNumLocaleString(s.monthly, 2)}
						</dd>
					))}
				</div>
			</dl>
		</div>
	)
}

type StatsProps = {
	projectName: string
}

type ProjectStats = {
	project: SupportedProjects
	balance: number // tokens
	token: 'GNO' | 'DATA' | 'ETH'
	networth: number
	daily: number
	weekly: number
	monthly: number
}

type StatsState = ProjectStats[]

export const Stats = ({ projectName }: StatsProps) => {
	const [stats, setStats] = useState<StatsState>([] as StatsState)

	useEffect(() => {
		Promise.all([
			nodeService.getBalancesPerProject('streamr'),
			nodeService.getBalancesPerProject('gnosis'),
			nodeService.getBalancesPerProject('ethereum')
		])
			.then(([streamrBalances, gnosisBalances, ethereumBalances]) => {
				let stats: ProjectStats[] = [
					{
						project: 'gnosis',
						token: 'GNO',
						balance: gnosisBalances.total_balance_quantity,
						networth: gnosisBalances.total_balance_usd,
						weekly: gnosisBalances.weekly_income_usd,
						monthly: gnosisBalances.monthly_income_usd,
						daily: gnosisBalances.daily_income_usd
					},
					{
						project: 'streamr',
						token: 'DATA',
						balance: streamrBalances.total_balance_quantity,
						networth: streamrBalances.total_balance_usd,
						weekly: streamrBalances.weekly_income_usd,
						monthly: streamrBalances.monthly_income_usd,
						daily: streamrBalances.daily_income_usd
					},
					{
						project: 'ethereum',
						token: 'ETH',
						balance: ethereumBalances.total_balance_quantity,
						networth: ethereumBalances.total_balance_usd,
						weekly: ethereumBalances.weekly_income_usd,
						monthly: ethereumBalances.monthly_income_usd,
						daily: ethereumBalances.daily_income_usd
					}
				]
				setStats(stats)
			})
			.catch(console.error)
	}, [projectName])

	return (
		<div className="flex flex-col justify-between space-y-6 lg:flex-row lg:space-y-0">
			<div className="min-w-[230px] rounded-lg border p-6 lg:mr-6">
				<h2 className="text-base font-medium lg:text-xl">Nodes</h2>
				<div className="mt-4">
					<div className={clsx('flex flex-col justify-center space-y-2 pr-8')}>
						<div className="leading-normal text-gray-800">Total Tokens</div>

						{stats.map(s => (
							<p key={s.token}>
								{getNumLocaleString(s.balance, 2)}&nbsp;<span className="uppercase">{s.token}</span>
							</p>
						))}
					</div>
				</div>
			</div>
			<Rewards stats={stats} />
		</div>
	)
}
