import { publicProvider } from '@wagmi/core/providers/public'
import { WagmiConfig, configureChains, createConfig } from 'wagmi'
import { holesky, gnosis, gnosisChiado, mainnet } from 'viem/chains'
import { getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit'

const { chains, publicClient, webSocketPublicClient } = configureChains(
	[mainnet, gnosis, gnosisChiado, holesky],
	[publicProvider()]
)

const { connectors } = getDefaultWallets({
	appName: 'zonaris',
	projectId: '8c67873b0f31bd62ff5b5ec5c5280990',
	chains
})

const configRainbow = createConfig({
	autoConnect: true,
	connectors,
	publicClient,
	webSocketPublicClient
})

export default function WagmiRainbowProvider({ children }: { children: React.ReactNode }) {
	return (
		<WagmiConfig config={configRainbow}>
			<RainbowKitProvider chains={chains}>{children}</RainbowKitProvider>
		</WagmiConfig>
	)
}
