import { useSteps } from 'pages/onboarding'

import { SkipStepButton } from '../skip-step-button'
import { NextButton } from '../next-button'

function CreateWallet() {
	let { moveOn } = useSteps()
	return (
		<div className="relative">
			<div className="onboarding-step lg:h-[612px] max-w-5xl lg:overflow-scroll lg:rounded-xl lg:border bg-white pb-20 ">
				<div className="max-w-xl px-4 pt-4 lg:pl-12 lg:pt-12 text-[#282828]">
					<h3 className="mb-6 text-2xl hidden lg:block">Create Web3 wallet</h3>
					<SkipStepButton text="Already have a wallet set up?" />
					<h4 className="mt-6 text-sm font-semibold leading-6">
						Adding DATA tokens to your wallet:
					</h4>
					<p className="mt-1 text-sm font-normal leading-6">
						If you are using MetaMask, to view your DATA tokens in each account you must add the
						contract address:{' '}
						<a
							className="underline"
							href="https://polygonscan.com/address/0x3a9a81d576d83ff21f26f325066054540720fc34"
							target="_blank"
							rel="noreferrer"
						>
							0x3a9A81d576d83FF21f26f325066054540720fC34
						</a>
						.
					</p>
					<h4 className="my-6 text-sm font-semibold leading-6">
						Creating separate wallets for multiple nodes:
					</h4>
					<ul className="list-disc space-y-1 pl-4 text-sm leading-6">
						<li>
							We recommend creating a new Web3 Wallet account for each node when setting up multiple
							nodes. Keep in mind that the maximum staking amount per node is 20,000 DATA tokens.
						</li>
						<li>
							For example, if you are setting up 10 nodes, you will need to create 10 wallet
							addresses/accounts.
						</li>
						<li>
							Each node needs one beneficiary address where rewards will be distributed monthly by
							Streamr.
						</li>
					</ul>
					<h4 className="my-6 text-sm font-semibold">
						Setting up a MetaMask account for each node:
					</h4>
					<ul className="list-disc space-y-1 pl-4 text-sm leading-6">
						<li>
							Create a new MetaMask account by selecting “Create Account” on the top right icon in
							MetaMask. Import the token using the same Polygon Contract for DATA:
							0x3a9A81d576d83FF21f26f325066054540720fC34.
						</li>
						<li>
							Rename each account to “zonaris Node 1”, “zonaris Node 2”, etc. to keep track of them
							or call them the node name provided by Streamr which is visible in the dashboard.
						</li>
					</ul>
				</div>
			</div>
			<NextButton moveOn={() => moveOn()} />
		</div>
	)
}

export { CreateWallet }
