import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Navigate } from 'react-router-dom'
import * as yup from 'yup'

import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { FormError } from 'components/app/form-error'
import { Input } from 'components/app/input'
import { AppLayout } from 'components/app/layout'
import { CustomLink } from 'components/app/link'
import { AuthSidePanel } from 'components/auth/side-panel'
import { useAppDispatch, useAppSelector } from 'hooks'
import { register as registerUser } from 'slices/auth'
import { clearMessage } from 'slices/message'

const schema = yup.object().shape({
	nickname: yup
		.string()
		.min(4, 'Name must be at least 4 characters')
		.max(32)
		.required('Name is required'),
	email: yup.string().email().required('Please use correct email'),
	password: yup
		.string()
		.min(12, 'Password must be at least 12 characters')
		.max(32, 'Password must be at most 32 characters')
		.required(),
	passwordConfirmation: yup
		.string()
		.oneOf([yup.ref('password'), null], 'Those passwords didn’t match.'),
	tos: yup.bool().required('You must agree with the terms of service and privacy policy')
})

export const Register = () => {
	const dispatch = useAppDispatch()

	const { message } = useAppSelector(state => state.message)

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: yupResolver(schema)
	})

	const [isLoading, setLoading] = useState(false)
	const [isRegistrationCompleted, setRegistrationCompleted] = useState(false)

	useEffect(() => {
		return () => {
			dispatch(clearMessage())
		}
	}, [])

	const handleFormSubmit = (data: any) => {
		setLoading(true)

		dispatch(registerUser({ user: data }))
			.unwrap()
			.then(() => {
				setRegistrationCompleted(true)
			})
			.catch(error => {
				console.log(error)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	if (isRegistrationCompleted) {
		return <Navigate to={'/register/verify-email'} />
	}

	return (
		<AppLayout title="Sign-Up">
			<div className="mt-8 flex w-full flex-row items-start sm:justify-center">
				<div className="w-full px-6 md:w-2/3 md:px-0">
					<div className="space-y-1 sm:mx-auto sm:w-full sm:max-w-md">
						<h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
						 Welcome to zonaris
						</h2>
					</div>

					<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
						<form className="space-y-6" onSubmit={handleSubmit(handleFormSubmit)}>
							<Input
								name="nickname"
								labelText="Name*"
								register={register}
								placeholder={'Enter your name'}
								errors={errors}
							/>
							<Input
								name="email"
								labelText="Email*"
								register={register}
								placeholder={'Enter your email'}
								errors={errors}
								type="email"
								autoComplete="email"
							/>
							<Input
								name="password"
								labelText="Password*"
								register={register}
								placeholder={'Create a password'}
								errors={errors}
								type="password"
								autoCapitalize="false"
							/>
							<Input
								name="passwordConfirmation"
								labelText="Repeat Password*"
								register={register}
								placeholder={'Repeat a password'}
								errors={errors}
								type="password"
								autoCapitalize="false"
							/>
							<div className="flex items-center justify-between">
								<div className="flex items-center">
									<input
										{...register('tos')}
										type="checkbox"
										className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
									/>
									<label htmlFor="tos" className="ml-2 block text-sm text-gray-900">
										I agree to the zonaris{' '}
										<a
											href="https://www.zonaris.io/terms-of-service"
											target={'_blank'}
											rel="noreferrer"
											className="font-bold text-indigo-600 hover:text-indigo-500 hover:underline"
										>
											Terms of Service
										</a>{' '}
										,{' '}
										<a
											href="https://www.zonaris.io/disclaimer"
											target={'_blank'}
											rel="noreferrer"
											className="font-bold text-indigo-600 hover:text-indigo-500 hover:underline"
										>
											Disclaimer
										</a>{' '}
										&{' '}
										<a
											href="https://www.zonaris.io/privacy-policy"
											target={'_blank'}
											rel="noreferrer"
											className="font-bold text-indigo-600 hover:text-indigo-500 hover:underline"
										>
											Privacy Policy
										</a>
									</label>
								</div>

								{errors['tos'] && (
									<p className="text-xs text-red-600">{errors['tos'].message as string}</p>
								)}
							</div>

							<div className="space-y-3">
								<Button type="submit">
									{isLoading ? (
										<>
											<Spinner className="h-5 w-5" />
											<span className={'mx-auto animate-pulse'}>Please wait...</span>
										</>
									) : (
										<span>Get Started</span>
									)}
								</Button>
							</div>
							{message && <FormError message={message} />}
						</form>

						<p className="mt-10 text-center">
							Already have an account? <CustomLink href="/login">Log in</CustomLink>
						</p>
					</div>
				</div>
				<div className="hidden w-1/2 md:block">
					<AuthSidePanel />
				</div>
			</div>
		</AppLayout>
	)
}
