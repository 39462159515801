import clsx from 'clsx'

interface ButtonProps
	extends React.DetailedHTMLProps<
		React.ButtonHTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement
	> {
	children: React.ReactNode
}

export const Button: React.FC<ButtonProps> = ({ children, className, ...props }) => {
	return (
		<button
			{...props}
			className={clsx(
				'flex w-full justify-center rounded-lg border border-transparent bg-[#6340DE] py-3 px-4 text-sm font-medium text-white shadow-sm hover:bg-[#5630de] focus:outline-none focus:ring-2 focus:ring-[#6d4de2] focus:ring-offset-2',
				className,
				{
					'pointer-events-none cursor-not-allowed': props.disabled
				}
			)}>
			{children}
		</button>
	)
}

export const ButtonOutline: React.FC<ButtonProps> = ({ children, className, ...props }) => {
	return (
		<button
			{...props}
			className={clsx(
				'flex w-full justify-center rounded-lg border border-gray-500 py-3 px-4 text-sm font-medium text-slate-900 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2',
				className,
				{
					'pointer-events-none cursor-not-allowed': props.disabled
				}
			)}>
			{children}
		</button>
	)
}
