import { Spinner } from 'components/animations/spinner'
import { AppHeader } from 'components/app/header'
import { AppLayout } from 'components/app/layout'
import { SidebarLayout } from 'components/app/sidebar-layout'
import { useState } from 'react'
import { Rewards, TimeUnit, getHistoricalRewards } from 'services/reporting-service'

const returnUnits = {
	'ethereum': 'ETH'
} as const

export const Reports = () => {
	const [loading, setLoading] = useState(false)
	const [rewards, setRewards] = useState<Rewards[]>([])
	const [from, setFrom] = useState('')
	const [to, setTo] = useState('')
	const [timeUnit, setTimeUnit] = useState<TimeUnit>('day')
	const returnUnit = returnUnits['ethereum']

	const getRewards = () => {
		if (!from || !to) return

		let dateFrom = Date.parse(from)
		let dateTo = Date.parse(to)
		if (isNaN(Date.parse(from)) || isNaN(Date.parse(to))) return

		setLoading(true)
		getHistoricalRewards({ from: dateFrom, to: dateTo, by: timeUnit })
			.then(({ returns }) => {
				for (let row of returns) {
					let start = new Date(row.timeStart).toDateString()
					let end = new Date(row.timeEnd).toDateString()
					row.timeStart = start
					row.timeEnd = end
				}
				setRewards(returns)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const downloadCSV = () => {
		let csvContent = 'data:text/csv;charset=utf-8,from,to,return,timeUnit\n'
		for (let row of rewards) {
			csvContent += `${row.timeStart},${row.timeEnd},${row.return},${row.timeAggregation}\n`
		}
		let encodedUri = encodeURI(csvContent)
		let link = document.createElement('a')
		link.setAttribute('href', encodedUri)
		link.setAttribute('download', `rewards.csv`)
		document.body.appendChild(link)
		link.click()
	}

	return (
		<AppLayout renderHeader={false} title="Nodes">
			<SidebarLayout>
				<div className="hidden border-b md:block">
					<AppHeader auth={true}>
						<h1 className="text-2xl font-semibold text-gray-900">Rewards</h1>
					</AppHeader>
				</div>
				<div className="p-8">
					<div className="mt-8 flow-root rounded-xl border md:mt-0">
						<div className="flex items-center justify-between px-4 py-3 sm:px-6">
							<div className="space-y-2">
								Show my rewards for{' '}
								<select
									id="protocol"
									name="protocol"
									className="inline-block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
								>
									<option>Ethereum</option>
								</select>{' '}
								from{' '}
								<input
									className="inline-block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
									onChange={e => setFrom(e.target.value)}
									type="date"
									name="from"
									id="from"
								/>{' '}
								to{' '}
								<input
									className="inline-block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
									onChange={e => setTo(e.target.value)}
									type="date"
									name="to"
									id="to"
								/>{' '}
								by{' '}
								<select
									onChange={e => setTimeUnit(e.target.value as TimeUnit)}
									id="timeUnit"
									name="timeUnit"
									className="inline-block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
								>
									<option>day</option>
									<option>week</option>
									<option>month</option>
								</select>
							</div>
							<div className="flex items-center justify-end gap-x-6">
								{!!rewards.length && (
									<button
										onClick={downloadCSV}
										type="button"
										className="text-sm font-semibold leading-6 text-gray-900"
									>
										Download CSV
									</button>
								)}
								<button
									onClick={getRewards}
									type="button"
									className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none"
								>
									{loading ? <Spinner className="h-5 w-5" /> : <>Show</>}
								</button>
							</div>
						</div>
						{!!rewards.length && (
							<table className="min-w-full divide-y divide-gray-300">
								<thead>
									<tr>
										<th
											scope="col"
											className="px-3 py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
										>
											Network
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
										>
											From
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											To
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											Return ({returnUnit})
										</th>
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-200 bg-white">
									{rewards.map(row => (
										<tr key={row.timeStart}>
											<td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-500">
												Ethereum
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												{row.timeStart}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												{row.timeEnd}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm font-medium  text-gray-500">
												{row.return}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						)}
					</div>
				</div>
			</SidebarLayout>
		</AppLayout>
	)
}
