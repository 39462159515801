import axios from 'services/axios'

const getBilling = () => {
	return axios.get('/billing').then(response => {
		return response.data
	})
}

const setup = () => {
	return axios.post('/billing/setup', {}).then(response => {
		return response.data
	})
}

const detach = (id: string) => {
	return axios.delete(`/billing/payment_method/${id}`).then(response => {
		return response.data
	})
}

const updateCustomer = (setup_intent: string) => {
	return axios.post('/billing/update_customer', { setup_intent }).then(response => {
		return response.data
	})
}

const billingService = {
	setup,
	updateCustomer,
	getBilling,
	detach
}

export default billingService
