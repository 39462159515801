import clsx from 'clsx'

interface ContainerProps {
	className?: string
	children: React.ReactNode
}

export const Container: React.FC<ContainerProps> = ({ className, ...props }) => {
	return (
		<div
			className={clsx(
				'relative z-50 mx-auto flex justify-between px-4 py-8 sm:px-6 lg:px-12',
				className
			)}
			{...props}
		/>
	)
}
