import axios from 'services/axios'

const deployNode = (projectName: string, walletAddresses: string[]) => {
	return axios
		.post('/deployments', {
			deployment: { project_name: projectName, wallet_addresses: walletAddresses }
		})
		.then(res => {
			return res.data
		})
}

const getDeployments = () => {
	return axios.get('/deployments').then(res => res.data)
}

const deleteDeployment = (id: string) => {
	return axios.delete(`/deployments/${id}`).then(res => res.data)
}

const getReward = (projectName: string) => {
	return axios.get(`/balances/rewards_today/${projectName}`).then(res => res.data)
}

const getNetwork = (projectName: string) => {
	return axios.get(`/balances/net_worth/${projectName}`).then(res => res.data)
}

/* from/to are in YYYY-MM-DD format */
const getHistorical = (projectName: string, from: string, to: string) => {
	let url = `/balances/historical/${projectName}?from=${from}&to=${to}`
	return axios.get(url).then(res => res.data)
}

const getLastMonth = (projectName: string) => {
	let url = `/balances/historical/${projectName}?type=30-days`
	return axios.get(url).then(res => res.data)
}

const getTotalStaked = (projectName: string) => {
	let url = `/balances/total_staked/${projectName}`
	return axios.get(url).then(res => res.data)
}

type ProjectBalances = {
	total_balance_usd: number
	total_balance_quantity: number
	daily_income_usd: number
	weekly_income_usd: number
	monthly_income_usd: number
}

const getBalancesPerProject = (project: string): Promise<ProjectBalances> =>
	axios.get(`/balances/${project}`).then(res => res.data)

type Project = {
	apy: number
	nodes_online: number
	price: number
	project_name: string
	tvl: number
}

type ProjectResponse = {
	data: Project[]
}

const getProjects = (): Promise<ProjectResponse> => {
	return axios.get('/projects').then(res => {
		return res.data
	})
}

const nodeService = {
	deployNode,
	getDeployments,
	getReward,
	getHistorical,
	getTotalStaked,
	getLastMonth,
	getNetwork,
	getProjects,
	deleteDeployment,
	getBalancesPerProject
}

export default nodeService
