import { Buffer } from 'buffer'

import {
	ContainerType,
	ValueOf,
	ByteVectorType,
	BooleanType,
	UintNumberType,
	UintBigintType,
	Type
} from '@chainsafe/ssz'

export const Boolean = new BooleanType()
export const Byte = new UintNumberType(1)
export const Bytes4 = new ByteVectorType(4)
export const Bytes8 = new ByteVectorType(8)
export const Bytes20 = new ByteVectorType(20)
export const Bytes32 = new ByteVectorType(32)
export const Bytes48 = new ByteVectorType(48)
export const Bytes96 = new ByteVectorType(96)
export const Uint8 = new UintNumberType(1)
export const Uint16 = new UintNumberType(2)
export const Uint32 = new UintNumberType(4)
export const UintNum64 = new UintNumberType(8)
export const UintNumInf64 = new UintNumberType(8, { clipInfinity: true })
export const UintBn64 = new UintBigintType(8)
export const UintBn128 = new UintBigintType(16)
export const UintBn256 = new UintBigintType(32)
export const BLSSignature = Bytes96

export const BLS_WITHDRAWAL_PREFIX = Uint8Array.from([0])
export const ETH1_ADDRESS_WITHDRAWAL_PREFIX = Uint8Array.from([1])
export const ZERO_HASH = Buffer.alloc(32, 0)
export const DomainType = Bytes4

export const BLSPubkey = Bytes48
export const Domain = Bytes32
export const Root = new ByteVectorType(32)

const DOMAIN_DEPOSIT = Buffer.from('03000000', 'hex')

export const GNOSIS_FORK_VERSION = Buffer.from('00000064', 'hex')
export const CHIADO_FORK_VERSION = Buffer.from('0000006F', 'hex')

const MAINNET_FORK_VERSION = Buffer.from('00000000', 'hex')
const HOLESKY_FORK_VERSSION = Buffer.from('01017000', 'hex')

export const FORKS = {
	gnosis: GNOSIS_FORK_VERSION,
	chiado: CHIADO_FORK_VERSION,
	ethereum: MAINNET_FORK_VERSION,
	holesky: HOLESKY_FORK_VERSSION
} as const

export const ENCRYPTION_PATH = 'm/12381/3600/0/0' as const

// need to get correct version
export const ForkVersion = Bytes4
export type Version = ValueOf<typeof ForkVersion>

export const ForkData = new ContainerType(
	{
		currentVersion: ForkVersion,
		genesisValidatorsRoot: Root
	},
	{ typeName: 'ForkData', jsonCase: 'eth2' }
)

export function computeDomain(forkVersion: Version): Uint8Array {
	const forkDataRoot = computeForkDataRoot(forkVersion)
	const domain = new Uint8Array(32)
	domain.set(DOMAIN_DEPOSIT, 0)
	domain.set(forkDataRoot.slice(0, 28), 4)
	return domain
}

function computeForkDataRoot(currentVersion: Version): Uint8Array {
	const forkData = {
		currentVersion,
		genesisValidatorsRoot: ZERO_HASH
	}

	return ForkData.hashTreeRoot(forkData)
}

// https://github.com/ChainSafe/lodestar/blob/8fa4b1fabfb2b1b0dfd15f004ffa8c21413ba9ec/packages/types/src/phase0/sszTypes.ts
export const SigningData = new ContainerType(
	{
		objectRoot: Root,
		domain: Domain
	},
	{ typeName: 'SigningData', jsonCase: 'eth2' }
)

// https://github.com/ChainSafe/lodestar/blob/8fa4b1fabfb2b1b0dfd15f004ffa8c21413ba9ec/packages/state-transition/src/util/signingRoot.ts#L7
export function computeDepositSigningRoot(
	sszObject: ValueOf<typeof DepositMessage>,
	domain: ValueOf<typeof Domain>
): Uint8Array {
	const domainWrappedObject: ValueOf<typeof SigningData> = {
		objectRoot: DepositMessage.hashTreeRoot(sszObject),
		domain
	}
	return SigningData.hashTreeRoot(domainWrappedObject)
}

export type DepositMessageType = ValueOf<typeof DepositMessage>

export const DepositMessage = new ContainerType(
	{
		pubkey: BLSPubkey,
		withdrawalCredentials: Bytes32,
		amount: UintNum64
	},
	{ typeName: 'DepositMessage', jsonCase: 'eth2' }
)

export type DepositDataType = ValueOf<typeof DepositData>

export const DepositData = new ContainerType(
	{
		pubkey: BLSPubkey,
		withdrawalCredentials: Bytes32,
		amount: UintNum64,
		signature: BLSSignature
	},
	{ typeName: 'DepositData', jsonCase: 'eth2' }
)

export function computeDepositDataRoot(depositData: ValueOf<typeof DepositData>) {
	return DepositData.hashTreeRoot(depositData)
}

export function computeDepositMessageRoot(depositData: ValueOf<typeof DepositMessage>) {
	return DepositMessage.hashTreeRoot(depositData)
}
