import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { CustomLink } from 'components/app/link'
import { Container } from 'components/container'
import { useAppDispatch, useAppSelector } from 'hooks'
import { logout } from 'slices/auth'
import { getWordsInitials, toTitleCase } from 'utils/string'

interface AppHeaderProps {
	children?: React.ReactNode
	auth?: boolean
}

export const AppHeader: React.FC<AppHeaderProps> = ({ children, auth = false }) => {
	const user = useAppSelector(state => state.auth.user)
	const dispatch = useAppDispatch()

	if (user) {
		return (
			<nav
				className={clsx('flex items-center justify-between p-2 lg:bg-white lg:px-8', {
					'py-0': auth && !children,
					'py-6': children
				})}
			>
				{auth ? (
					<>{children}</>
				) : (
					<div className="relative z-10 flex items-center gap-6">
						<Link to="/" className="hidden md:block" aria-label="Home">
							<img src="/logo-long-beta.png" className="h-12 w-auto" />
						</Link>
						<Link
							to={'/dashboard'}
							className="ml-6 rounded-lg border border-transparent bg-[#6340DE] py-3 px-4 text-sm font-medium text-white shadow-sm hover:bg-[#5630de] focus:outline-none focus:ring-2 focus:ring-[#6d4de2] focus:ring-offset-2"
						>
							Dashboard
						</Link>
					</div>
				)}
				<div className="flex items-center gap-4">
					<div className="flex items-center space-x-2">
						<Menu as="div" className="relative inline-block text-left">
							<Menu.Button className="flex w-full items-center space-x-4 text-slate-900">
								<img
									className="h-10 w-10 rounded-full border border-[#77818d]"
									src={`https://ui-avatars.com/api/?color=77818d&background=fff&name=${getWordsInitials(
										user.nickname
									)}&bold=true&length=2`}
									alt=""
								/>
								<span className="hidden items-center font-semibold lg:flex">
									{toTitleCase(user?.nickname)}
									<ChevronDownIcon
										className="ml-2 -mr-1 h-5 w-5 text-slate-500"
										aria-hidden="true"
									/>
								</span>
							</Menu.Button>
							<Transition
								as={Fragment}
								enter="transition ease-out duration-100"
								enterFrom="transform opacity-0 scale-95"
								enterTo="transform opacity-100 scale-100"
								leave="transition ease-in duration-75"
								leaveFrom="transform opacity-100 scale-100"
								leaveTo="transform opacity-0 scale-95"
							>
								<Menu.Items className="absolute w-[95vw] top-full z-20 right-5 mt-3 -mr-0.5 md:w-60 origin-top-right scale-100 transform divide-y divide-gray-100 rounded-lg bg-white text-sm font-normal text-slate-900 opacity-100 shadow-md ring-1 ring-slate-900/5 focus:outline-none sm:-mr-3.5">
									<p className="truncate py-3 px-3.5" role="none">
										<span className="block text-xs text-gray-500" role="none">
											Signed in as
										</span>
										<span className="mt-0.5 font-semibold" role="none">
											{user?.email}
										</span>
									</p>
									<div className="px-1 py-1.5 ">
										<Menu.Item>
											{({ active }) => (
												<button
													onClick={() => dispatch(logout())}
													className={`${
														active ? 'bg-gray-300 text-gray-800' : 'text-gray-900'
													} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
												>
													Sign out
												</button>
											)}
										</Menu.Item>
									</div>
								</Menu.Items>
							</Transition>
						</Menu>
					</div>
				</div>
			</nav>
		)
	}

	return (
		<header>
			<nav>
				<Container>
					<div className="relative z-10 flex items-center gap-16">
						<Link to="/" aria-label="Home">
							<img src="/logo-long-beta.png" className="h-12 w-auto" />
						</Link>
					</div>
					<div className="hidden items-center gap-6 md:flex">
						<CustomLink href="/signup" className="text-slate-900">
							Sign-Up
						</CustomLink>
						<CustomLink href="/login" className="text-slate-900">
							Login
						</CustomLink>
					</div>
				</Container>
			</nav>
		</header>
	)
}
