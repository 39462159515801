import { Navigate } from 'react-router-dom'

interface ProtectedRouteProps {
	user?: User
	redirectPath?: string
	children: JSX.Element
}

export const ProtectedRoute = ({ user, children, redirectPath = '/login' }: ProtectedRouteProps) => {
	if (!user?.jwt) {
		return <Navigate to={redirectPath} replace={true} />
	}

	return children
}
