import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import * as yup from 'yup'

import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { Input } from 'components/app/input'
import authService from 'services/auth-service'

interface EmailConfirmationFormProps {
	onFormSubmit?: (data: any) => void
}

const schema = yup.object().shape({
	email: yup.string().email().required('Please use correct email')
})

export const EmailConfirmationForm: React.FC<EmailConfirmationFormProps> = ({ onFormSubmit }) => {
	const [isLoading, setLoading] = useState(false)

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset
	} = useForm({
		resolver: yupResolver(schema)
	})

	const onSubmitResendConfirmationForm = (data: any) => {
		const { email } = data

		setLoading(true)

		authService
			.resendConfirmation(email)
			.then(data => {
				// show toast here
				toast.success('New confirmation link has been sent to your email!')
				reset()
			})
			.catch(error => {
				toast.error(error.response.data.message)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	return (
		<form
			className="mx-auto w-11/12 space-y-4"
			onSubmit={handleSubmit(onFormSubmit ?? onSubmitResendConfirmationForm)}>
			<Input
				name="email"
				register={register}
				errors={errors}
				placeholder={'Enter your email'}
				type="email"
				autoComplete="email"
			/>
			<div>
				<Button
					type="submit"
					className="my-4 bg-slate-900 px-6 font-bold hover:bg-slate-800 focus:ring-slate-700">
					{isLoading ? (
						<>
							<Spinner className="h-5 w-5" />
							<span className={'mx-auto animate-pulse'}>Please wait...</span>
						</>
					) : (
						<span>Send a new confirmation link</span>
					)}
				</Button>
			</div>
		</form>
	)
}
