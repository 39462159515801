import { SupportedProjects } from 'constants/index'
import { useNavigate } from 'react-router-dom'

export type Step = {
	heading: string
	subheading: string
	href: string
	status: 'current' | 'complete' | 'upcoming'
	hidden?: boolean
}

export type Steps = Step[]

const introSteps: Steps = [
	{
		heading: 'Choose Project',
		subheading: 'Please choose a project to continue',
		href: '/onboarding/choose-project',
		status: 'current'
	}
]

export const gnosisSteps: Steps = [
	...introSteps,
	{
		heading: 'Getting Started',
		subheading: 'Connect your Metamask',
		href: '/onboarding/gnosis/getting-started',
		status: 'upcoming'
	},
	{
		heading: 'Deploying Validator',
		subheading: 'Generate your validator key',
		href: '/onboarding/gnosis/deploy-validator',
		status: 'upcoming'
	},
	{
		heading: 'Add billing address',
		subheading: 'Confirm your address to activate your nodes',
		href: '/onboarding/gnosis/add-billing',
		status: 'upcoming'
	},
	{
		heading: 'Billing success',
		subheading: 'Confirm your address to activate your nodes',
		href: '/onboarding/gnosis/billing-success',
		status: 'upcoming',
		hidden: true
	}
]

export const ethereumSteps: Steps = [
	...introSteps,
	{
		heading: 'Getting Started',
		subheading: 'Connect your Metamask',
		href: '/onboarding/ethereum/getting-started',
		status: 'upcoming'
	},
	{
		heading: 'Deploying Validator',
		subheading: 'Generate your validator key',
		href: '/onboarding/ethereum/deploy-validator',
		status: 'upcoming'
	}
]


const streamrSteps: Steps = [
	...introSteps,
	{
		heading: 'Acquiring Tokens',
		subheading: 'Acquisition and calculating earnings',
		href: '/onboarding/streamr/get-tokens',
		status: 'upcoming'
	},
	{
		heading: 'Create Web3 wallet',
		subheading: 'Adding tokens to your wallet',
		href: '/onboarding/streamr/wallet',
		status: 'upcoming'
	},
	{
		heading: 'Start earning',
		subheading: 'Setting up nodes on zonaris',
		href: '/onboarding/streamr/start-earning',
		status: 'upcoming'
	},
	{
		heading: 'Add billing address',
		subheading: 'Confirm your address to activate your nodes',
		href: '/onboarding/streamr/add-billing',
		status: 'upcoming'
	},
	{
		heading: 'Billing success',
		subheading: 'Confirm your address to activate your nodes',
		href: '/onboarding/streamr/billing-success',
		status: 'upcoming',
		hidden: true
	}
]

type StepsMap = {
	[k in SupportedProjects]: Steps
}

const steps: StepsMap = {
	'gnosis': gnosisSteps,
	'streamr': streamrSteps,
	'ethereum': ethereumSteps
} as const

const getProjectSteps = (project: SupportedProjects) => {
	return steps[project]
}

export const useOnboardingFlow = () => {
	const navigate = useNavigate()

	const setCurrent = (steps: Steps, index: number) => {
		if (index < 0) return steps

		return steps.map((step: Step, i: number) => {
			if (i === index) {
				step.status = 'current'
				return step
			}
			if (i < index) {
				step.status = 'complete'
				return step
			}
			step.status = 'upcoming'
			return step
		})
	}

	const moveOn = (steps: Steps, index: number, query?: string) => {
		if (index >= steps.length) return

		let current = steps[index]
		let next = steps[index + 1]

		current.status = 'complete'
		next.status = 'current'

		if (query)
			navigate(`${next.href}?${query}`)
		else
			navigate(next.href)

		return steps
	}

	const moveBack = (steps: Steps, index: number) => {
		steps[index].status = 'current'
		navigate(steps[index].href)
		return steps
	}

	const reset = (steps: Steps) => {
		steps.forEach((step: Step) => (step.status = 'upcoming'))

		let first = steps[0]
		first.status = 'current'

		navigate(first.href)
		return steps
	}

	return {
		moveOn,
		moveBack,
		setCurrent,
		reset,
		getProjectSteps
	}
}
