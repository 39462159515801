import { ChangeEvent, useEffect, useState } from 'react'
import { useAccount } from 'wagmi'
import { writeContract, prepareWriteContract, readContract } from '@wagmi/core'
import { getValidators, sendBatchValidatorCredentials } from 'services/validator-service'

import {
	GeneratorState,
	generateValidatorKeys,
	ValidatorKeys,
	DepositResult,
	MAX_DEPOSIT_LIMIT,
	GNOSIS_CHAIN,
	DEPOSIT_AMOUNT_GNO_GWEI,
	computeDepositArgsGNO
} from 'utils/validators'
import CompletedStepGreen from 'assets/icons/svg/completed-step-green'
import clsx from 'clsx'
import { Spinner } from './animations/spinner'
import { NETWORKS } from 'utils/networks'
import ERC677ABI from 'utils/erc677'
import RainbowConnectBtn from './RainbowConnectBtn'

const FIRST_STEP = 'select_connector' as const

export type StepState =
	| 'select_connector'
	| 'quantity_select'
	| GeneratorState
	| 'depositing'
	| 'transaction_rejected'
	| 'balance_too_low'
	| 'unknown_error'

export type Step = {
	id: StepState
	content: JSX.Element
	action?: JSX.Element
}

type TimelineProps = {
	timeline: Step[]
	current: StepState
	restart: () => void
}

const errorStates = ['transaction_rejected', 'balance_too_low', 'unknown_error']

export function Timeline({ timeline, current, restart }: TimelineProps) {
	let currentIdx = timeline.findIndex(step => step.id == current)

	const renderErrorStates = (state: StepState) => {
		if (state == 'transaction_rejected')
			return (
				<div className="align-center flex flex-row justify-between">
					<p className="flex flex-col items-center justify-center text-lg text-red-800">
						Transaction Rejected (Are you using a hardware wallet with Metamask?)
					</p>
					<button onClick={restart} className="text-md font-semibold text-[#6340DE]">
						Restart
					</button>
				</div>
			)

		if (state == 'balance_too_low')
			return (
				<div className="align-center flex flex-row justify-between">
					<p className="flex flex-col items-center justify-center text-lg text-red-800">
						Seems like your GNO balance is too low. Please, verify that you have at least 1 GNO on
						Gnosis chain and try again
					</p>
					<button onClick={restart} className="text-md font-semibold text-[#6340DE]">
						Restart
					</button>
				</div>
			)

		if (state == 'unknown_error')
			return (
				<div className="align-center flex flex-row justify-between">
					<p className="flex flex-col items-center justify-center text-lg text-red-800">
						Our apologies, something went wrong. Please, try again later or contact us on{' '}
						<a
							href="https://discord.gg/tBQXAEYs6b"
							className="underline"
							target="_blank"
							rel="noreferrer"
						>
							our Discord
						</a>{' '}
					</p>
					<button onClick={restart} className="text-md font-semibold text-[#6340DE]">
						Restart
					</button>
				</div>
			)

		return null
	}

	return (
		<div className="w-full">
			{errorStates.includes(current) ? (
				renderErrorStates(current)
			) : (
				<ul role="list" className="space-y-4">
					{timeline
						.filter((_, i) => i <= currentIdx)
						.map((step, idx) => {
							let isComplete = idx < currentIdx || currentIdx == timeline.length - 1
							return (
								<li
									key={step.id}
									className={clsx(
										'flex items-center justify-between  px-4 py-2',
										isComplete && 'bg-[#F9FAFB]'
									)}
								>
									<div className="flex min-w-0 flex-1 justify-start">{step.content}</div>
									{isComplete ? (
										<CompletedStepGreen className="ml-3 h-6 w-6 text-white" aria-hidden="true" />
									) : (
										step.action || null
									)}
								</li>
							)
						})}
				</ul>
			)}
		</div>
	)
}

export function validatorStatusLink(publicKey: string) {
	let url = `https://beacon.gnosischain.com/validator/${publicKey}#deposits`
	return (
		<a className="font-medium underline" href={url} target="_blank" rel="noreferrer">
			Explorer Link
		</a>
	)
}

async function preflightAction(depositResult: DepositResult): Promise<boolean> {
	let validators = []
	for (let keySet of depositResult.values()) {
		validators.push({
			projectName: 'gnosis' as const,
			validatorCredentials: JSON.stringify(keySet.keystore),
			address: keySet.address
		})
	}

	try {
		await sendBatchValidatorCredentials({ validators })
		return true
	} catch (error) {
		console.error('fucked up sending data to the API', error)
		return false
	}
}

type GnosisDeploymentFlowProps = {
	onStateChange?: (state: StepState) => void
	withEndAction?: boolean
	onQuantityChangeCallback?: (n: number) => void
}

export default function GnosisDeploymentFlow({
	onStateChange,
	withEndAction,
	onQuantityChangeCallback
}: GnosisDeploymentFlowProps) {
	const { address = '', isConnected } = useAccount()
	const [quantity, setQuantity] = useState<number>(1)
	const [progressCount, setProgressCount] = useState<number>(0)
	const [inputError, setInputError] = useState<string | null>(null)
	const [depositPubkeys, setDepositPubkeys] = useState<string[]>([])

	let startingStep: StepState = isConnected ? 'quantity_select' : FIRST_STEP
	const [onboardingState, setOnboardingState] = useState<StepState>(startingStep)

	let chain = GNOSIS_CHAIN
	let network = NETWORKS[chain]
	useEffect(() => {
		if (!isConnected) {
			setOnboardingState(FIRST_STEP)
			return
		}
		if (isConnected && onboardingState == 'select_connector') {
			setState('quantity_select')
		}
	}, [isConnected, onboardingState])

	function startFlow() {
		if (isNaN(quantity) || quantity > MAX_DEPOSIT_LIMIT || quantity <= 0) {
			setInputError('quantity is off')
			return
		}
		setState('init')
	}

	const setState = (state: StepState) => {
		setOnboardingState(state)
		onStateChange?.(state)
	}

	function restart() {
		setState(FIRST_STEP)
		setProgressCount(0)
		setDepositPubkeys([])
	}

	function onQuantityChange(e: ChangeEvent<HTMLInputElement>) {
		setInputError(null)

		let value = Number(e.target.value)
		setQuantity(value)
		onQuantityChangeCallback?.(value)
	}

	async function onClick() {
		let keys: ValidatorKeys[] = []

		if (!address) {
			throw new Error('no available address')
		}

		for await (let { state, storeCount, data, error } of generateValidatorKeys(
			quantity,
			chain,
			address
		)) {
			if (storeCount) {
				setProgressCount(storeCount)
			}

			if (state != 'done') {
				setState(state)
				continue
			}

			if (!data || error) {
				setState('transaction_rejected')
				return
			}

			keys = data
		}

		setState('depositing')

		if (!keys.length) {
			throw new Error('no keys')
		}

		try {
			// check the session before we do anything
			// TODO: replace with an actual session check?
			await getValidators().then(_ => console.log('session ok'))
		} catch (error) {
			console.error('failed to get validators', error)
			return
		}

		try {
			let totalDepositAmount = DEPOSIT_AMOUNT_GNO_GWEI * BigInt(keys.length)
			let balance = await readContract({
				address:  network.addresses.token,
				abi: ERC677ABI,
				functionName: 'balanceOf',
				args: [address]
			}) as bigint

			if (!balance || balance < totalDepositAmount) {
				throw new Error('balance too low')
			}

			let { result, data } = await computeDepositArgsGNO(keys)

			let ok = await preflightAction(result)
			if (!ok) {
				throw new Error('preflight check failed')
			}

			await prepareWriteContract({
				address: network.addresses.token,
				abi: ERC677ABI,
				args: [network.addresses.deposit, totalDepositAmount.toString(), data],
				functionName: 'transferAndCall',
				gas: 1_000_000n
			})
				.then(({ request }) => writeContract(request))
				.then(({ hash }) => {
					setDepositPubkeys(Array.from(result.keys()))
					setState('done')
				})
		} catch (error: any) {
			console.error('error in the deposit', error)
			if (error?.message == 'balance too low') {
				return setState('balance_too_low')
			}
			if (error?.code == 'ACTION_REJECTED') {
				return setState('transaction_rejected')
			}
			setState('unknown_error')
		}
	}

	const AwaitingAction = () => (
		<p className="text-center text-xs font-semibold text-[#6340DE]">
			Awaiting <br />
			Metamask
		</p>
	)

	const CountProgressBar = ({ count: keystoreCount }: { count: number }) => {
		let circles = Array.from({ length: keystoreCount }, (_, i) => (
			<span className="block h-2.5 w-2.5 rounded-full bg-indigo-600 hover:bg-indigo-900" key={i} />
		))

		return (
			<div>
				<p>
					Key stores generated: <b className="font-medium">{keystoreCount}</b>
				</p>
				<div className="flex space-x-1">{circles}</div>
			</div>
		)
	}

	const steps: Step[] = [
		{
			id: 'select_connector',
			content: (
				<div className="flex flex-col">
					{isConnected ? <RainbowConnectBtn /> : <p>Please, connect your wallet</p>}
				</div>
			),
			action: !isConnected ? <RainbowConnectBtn /> : undefined
		},
		{
			id: 'quantity_select',
			content: (
				<div className="pr-5">
					<p>
						How many validators would you like to deploy?{' '}
						<input
							className="w-[80px] rounded-md border-gray-300 py-1 focus:border-indigo-500 focus:ring-indigo-500"
							min="0"
							max={MAX_DEPOSIT_LIMIT}
							disabled={onboardingState != 'quantity_select'}
							readOnly={onboardingState != 'quantity_select'}
							type="number"
							placeholder="1"
							value={quantity}
							onChange={onQuantityChange}
						/>
					</p>
					{onboardingState == 'quantity_select' && (
						<p className="text-xs">
							Up to a max of {MAX_DEPOSIT_LIMIT}. Make sure you have at least the same number of GNO
							available.{' '}
							<b className="font-semibold">
								Please, note that each key takes a couple of seconds to generate
							</b>
						</p>
					)}
					{inputError && (
						<p className="text-xs text-red-800">
							Please, enter a valid number below {MAX_DEPOSIT_LIMIT}
						</p>
					)}
				</div>
			),
			action: (
				<button onClick={startFlow} className="text-xs font-semibold text-[#6340DE]">
					Submit
				</button>
			)
		},
		{
			id: 'init',
			content: <p>Let's start generating your validator keys!</p>,
			action: (
				<button onClick={onClick} className="text-xs font-semibold text-[#6340DE]">
					Start
				</button>
			)
		},
		{
			id: 'switching_chain',
			content: <p>Switching to Gnosis Chain</p>,
			action: <AwaitingAction />
		},
		{
			id: 'encrypting',
			content: (
				<>
					<p>
						We will now generate your validator keys and encrypt them with your Metamask wallet.
						Please click the Provide button to proceed.
					</p>
				</>
			),
			action: <AwaitingAction />
		},
		{
			id: 'generating_keystore',
			content: (
				<div className="flex flex-col">
					<p>
						Generating key store for each validator. This might take up to a few minutes, depending
						of the deposit amount.
					</p>
					<CountProgressBar count={progressCount} />
				</div>
			),
			action: <Spinner className="h-5 w-5 text-[#6340DE]" />
		},
		{
			id: 'depositing',
			content: <p>Please, confirm the deposit transaction(s) in your Metamask wallet.</p>,
			action: <AwaitingAction />
		},
		{
			id: 'done',
			content: (
				<div>
					Transaction has been successfully submitted. Please, allow a couple of minutes for
					processing. After this, you will be able to see your validators here:
					<br />
					<ol className="ml-6 list-decimal">
						{depositPubkeys.map(pubkey => (
							<li key={pubkey}>{validatorStatusLink(pubkey)}</li>
						))}
					</ol>
				</div>
			),
			action: (
				<button onClick={restart} className="text-md font-semibold text-[#6340DE]">
					Restart
				</button>
			)
		}
	]

	return (
		<div>
			<Timeline restart={restart} timeline={steps} current={onboardingState} />
			{onboardingState == 'done' && withEndAction && (
				<button
					onClick={restart}
					className="text-md my-5 w-full text-center font-semibold text-[#6340DE]"
				>
					Restart
				</button>
			)}
		</div>
	)
}
