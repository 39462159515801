import * as React from 'react'

function NextStepIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_1166_6914)">
				<rect width="24" height="24" rx="12" fill="#F9FAFB" />
				<circle cx="12" cy="12" r="4" fill="#EAECF0" />
			</g>
			<defs>
				<clipPath id="clip0_1166_6914">
					<rect width="24" height="24" rx="12" fill="white" />
				</clipPath>
			</defs>
		</svg>
	)
}

export default NextStepIcon
