import axios from 'axios'

const getCoinPrice = (coin: string, currency = 'usd') => {
	return axios
		.get(`https://api.coingecko.com/api/v3/simple/price?ids=${coin}&vs_currencies=${currency}`)
		.then(res => {
			return res.data
		})
}

const getAPY = () => {
	return axios.get('https://brubeck1.streamr.network:3013/apy').then(res => {
		return res.data
	})
}

const thirdPartyService = {
	getCoinPrice,
	getAPY
}

export default thirdPartyService
