import React, { useEffect, useState } from 'react'

import { AppLayout } from 'components/app/layout'
import { PricePredictionsDropdown } from 'components/dropdowns/price-prediction-selection'
import { CoinSelection } from 'components/inputs/coins-input'
import { AvailableCoins } from 'constants/streamr'
import thirdPartyService from 'services/third-party'
import { getNumLocaleString } from 'utils/string'
import { DEFAULT_CURRENCY } from 'utils/currency'


export const StakingCalculator = () => {
	const [numTokens, setNumTokens] = useState(0)
	const [pricePrediction, setPricePrediction] = useState(1)
	const [coin, setCoin] = useState(AvailableCoins[0])
	const [coinPrice, setCoinPrice] = useState(0)
	const [spotAPR, setSpotAPR] = useState(0)

	useEffect(() => {
		thirdPartyService.getCoinPrice(coin.namePrimary).then(data => {
			const price = data[coin.namePrimary.toLowerCase()][DEFAULT_CURRENCY]
			setCoinPrice(price)
		})
	}, [coin])

	useEffect(() => {
		thirdPartyService.getAPY().then(data => {
			const spotAPR = data['spot-APR']
			setSpotAPR(spotAPR)
		})
	}, [])

	const handleNumTokensChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNumTokens(event.target.valueAsNumber || 0)
	}

	const actualSpotAPR = spotAPR / 100

	// Calculate monthly and annual data token earnings
	const dailyDataEarnings = (numTokens * actualSpotAPR) / 365
	const monthlyDataEarnings = (numTokens * actualSpotAPR) / 12
	const annualDataEarnings = numTokens * actualSpotAPR

	// Calculate monthly and annual USD earnings
	const dailyUSDEarnings = dailyDataEarnings * coinPrice * pricePrediction
	const monthlyUSDEarnings = monthlyDataEarnings * coinPrice * pricePrediction
	const annualUSDEarnings = annualDataEarnings * coinPrice * pricePrediction

	return (
		<AppLayout renderHeader={true}>
			<div className="mt-8 flex w-full flex-row items-start sm:justify-center">
				<div className="w-full px-6 md:w-2/3 md:px-0">
					<div className="space-y-1 text-center sm:mx-auto sm:w-full sm:max-w-4xl">
						<h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
							Earnings Calculator
						</h2>
						<p className="text-slate-400">
							Calculate your staking rewards and earnings with our simple calculator tool
						</p>
					</div>
					<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-5xl">
						<div className="w-full rounded-lg bg-gray-100 shadow-md sm:py-10 sm:px-12">
							<div className="flex justify-between space-x-10">
								<div className="w-3/5">
									<h2 className="mb-10 text-xl font-bold">How many {coin.id} do you have?</h2>
									<div className="space-y-8">
										<div className="max-w-lg space-y-1">
											<label htmlFor="coins" className="flex items-center justify-between">
												<span>Select and enter your coins</span>
												<span>${coinPrice}</span>
											</label>
											<CoinSelection
												onCoinInputChange={handleNumTokensChange}
												onCoinSelected={setCoin}
											/>
										</div>
										<div className="max-w-lg space-y-1">
											<label
												htmlFor="pricePrediction"
												className="flex items-center justify-between">
												<span>Price Prediction</span>
											</label>
											<PricePredictionsDropdown onPredictionSelected={setPricePrediction} />
										</div>
									</div>
									<div className="mt-4">
										<p className="text-gray-500">
											Streamr’s published APR{' '}
											<span className="text-lg font-semibold text-green-500">
												{getNumLocaleString(spotAPR, 2)}%
											</span>
										</p>
									</div>
								</div>
								<div className="ml-auto w-1/3 space-y-6 rounded-lg bg-[#F9FAFB] sm:py-6 sm:px-8">
									<EarningCard
										title="Daily earnings"
										coin={coin.id}
										tokens={dailyDataEarnings}
										earnings={dailyUSDEarnings}
									/>
									<EarningCard
										title="Monthly earnings"
										coin={coin.id}
										tokens={monthlyDataEarnings}
										earnings={monthlyUSDEarnings}
									/>
									<EarningCard
										title="Yearly earnings"
										coin={coin.id}
										tokens={annualDataEarnings}
										earnings={annualUSDEarnings}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

type EarningCardProps = {
	title: string
	coin: string
	tokens: number
	earnings: number
}

const EarningCard = ({ title, coin, tokens, earnings }: EarningCardProps) => {
	return (
		<div className="space-y-.5">
			<p className="text-sm text-gray-500">{title}</p>
			<p className="text-lg font-semibold">{`${getNumLocaleString(tokens, 2)} ${coin}`}</p>
			<p className="text-sm text-gray-500">{`$${Number(earnings.toFixed(2)).toLocaleString()}`}</p>
		</div>
	)
}
