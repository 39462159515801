import clsx from 'clsx'
import { useEffect, useState } from 'react'

import { PricePredictionsDropdown } from 'components/dropdowns/price-prediction-selection'
import { CoinSelection } from 'components/inputs/coins-input'
import { AvailableCoins } from 'constants/streamr'
import thirdPartyService from 'services/third-party'
import { getNumLocaleString } from 'utils/string'
import { DEFAULT_CURRENCY } from 'utils/currency'

export const EarningsCalculator = ({ light = false }: { light?: boolean }) => {
	const [numTokens, setNumTokens] = useState(0)
	const [pricePrediction, setPricePrediction] = useState(1)
	const [coin, setCoin] = useState(AvailableCoins[0])
	const [coinPrice, setCoinPrice] = useState(0)
	const [spotAPR, setSpotAPR] = useState(0)

	useEffect(() => {
		thirdPartyService.getCoinPrice(coin.namePrimary).then(data => {
			let price = data[coin.namePrimary.toLowerCase()][DEFAULT_CURRENCY]
			setCoinPrice(price)
		})
	}, [coin])

	useEffect(() => {
		thirdPartyService.getAPY().then(data => {
			const spotAPR = data['spot-APR']
			setSpotAPR(spotAPR)
		})
	}, [])

	const handleNumTokensChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNumTokens(event.target.valueAsNumber || 0)
	}

	const actualSpotAPR = spotAPR / 100

	// Calculate monthly and annual data token earnings
	const dailyDataEarnings = (numTokens * actualSpotAPR) / 365
	const monthlyDataEarnings = (numTokens * actualSpotAPR) / 12
	const annualDataEarnings = numTokens * actualSpotAPR

	// Calculate monthly and annual USD earnings
	const dailyUSDEarnings = dailyDataEarnings * coinPrice * pricePrediction
	const monthlyUSDEarnings = monthlyDataEarnings * coinPrice * pricePrediction
	const annualUSDEarnings = annualDataEarnings * coinPrice * pricePrediction

	if (light) {
		return (
			<div className="w-full rounded-lg border p-4">
				<div className="flex flex-col md:flex-row md:items-center md:space-x-4">
					<div className="md:w-1/2">
						<label
							htmlFor="coinSelection"
							className="mb-2 flex items-center justify-between text-sm"
						>
							<span className="text-light-gray">Enter your coins</span>
							<span>${coinPrice}</span>
						</label>
						<CoinSelection onCoinInputChange={handleNumTokensChange} onCoinSelected={setCoin} />
					</div>
					<div className="md:w-1/2">
						<label
							htmlFor="coinSelection"
							className="mb-2 flex items-end justify-start text-sm text-light-gray"
						>
							Price Prediction
						</label>
						<PricePredictionsDropdown
							onPredictionSelected={prediction => setPricePrediction(prediction)}
						/>
					</div>
				</div>
				<dl className="mt-6 divide-y">
					<div className="flex justify-between border-gray-900/10 py-3">
						<dt className="leading-normal text-gray-800">Streamr&apos;s published APR </dt>
						<dd className="text-xs lg:text-base font-medium tracking-tight text-green-500">
							{getNumLocaleString(spotAPR, 2)}%
						</dd>
					</div>
					<EarningsCardItem
						light
						title="Daily earnings"
						coin={coin.id}
						tokens={dailyDataEarnings}
						earnings={dailyUSDEarnings}
					/>
					<EarningsCardItem
						light
						title="Monthly earnings"
						coin={coin.id}
						tokens={monthlyDataEarnings}
						earnings={monthlyUSDEarnings}
					/>
					<EarningsCardItem
						light
						title="Yearly earnings"
						coin={coin.id}
						tokens={annualDataEarnings}
						earnings={annualUSDEarnings}
					/>
				</dl>
				<p className="mt-6 text-xs leading-5 text-light-gray">
					The effective or actual earnings or rewards of the user depend on many dynamic variables
					(directly or indirectly) used in the calculator. Any results presented are based on
					prediction formulas and we do not guarantee any kind of accuracy of the data, earnings or
					rewards so presented. Please read our{' '}
					<a
						href="https://www.zonaris.io/disclaimer"
						target={'_blank'}
						rel="noreferrer"
						className="text-blue-400 underline"
					>
						DISCLAIMER
					</a>{' '}
					for further information.
				</p>
			</div>
		)
	}

	return (
		<div className="rounded-lg border p-6 pt-8">
			<h3 className="font-medium text-base lg:text-xl">Earnings Calculator</h3>
			<div className="mt-5 flex flex-col justify-between lg:space-x-6 lg:flex-row">
				<div className="flex flex-col lg:w-1/2">
					<div className="flex flex-col space-y-4 xl:flex-row xl:space-x-4 xl:space-y-0">
						<fieldset className="w-full">
							<label
								htmlFor="coinSelection"
								className="mb-2 flex items-center justify-between text-sm"
							>
								<span className="text-light-gray">Enter your coins</span>
								<span>${coinPrice}</span>
							</label>
							<CoinSelection onCoinInputChange={handleNumTokensChange} onCoinSelected={setCoin} />
						</fieldset>
						<fieldset className="w-full">
							<label
								htmlFor="coinSelection"
								className="mb-2 flex items-end justify-start text-sm text-light-gray"
							>
								Price Prediction
							</label>
							<PricePredictionsDropdown
								onPredictionSelected={prediction => setPricePrediction(prediction)}
							/>
						</fieldset>
					</div>
					<p className="mt-4 text-xs leading-5 text-light-gray">
						The effective or actual earnings or rewards of the user depend on many dynamic variables
						(directly or indirectly) used in the calculator. Any results presented are based on
						prediction formulas and we do not guarantee any kind of accuracy of the data, earnings
						or rewards so presented. Please read our{' '}
						<a
							href="https://www.zonaris.io/disclaimer"
							target={'_blank'}
							rel="noreferrer"
							className="text-blue-400 underline"
						>
							DISCLAIMER
						</a>{' '}
						for further information.
					</p>
				</div>
				<dl className="mt-3 divide-y lg:w-1/2">
					<div className="flex justify-between border-gray-900/10 py-3">
						<dt className="leading-normal text-gray-800">Streamr&apos;s published APR </dt>
						<dd className="text-xs lg:text-base font-medium tracking-tight text-green-500">
							{getNumLocaleString(spotAPR, 2)}%
						</dd>
					</div>
					<EarningsCardItem
						light
						title="Daily earnings"
						coin={coin.id}
						tokens={dailyDataEarnings}
						earnings={dailyUSDEarnings}
					/>
					<EarningsCardItem
						light
						title="Monthly earnings"
						coin={coin.id}
						tokens={monthlyDataEarnings}
						earnings={monthlyUSDEarnings}
					/>
					<EarningsCardItem
						light
						title="Yearly earnings"
						coin={coin.id}
						tokens={annualDataEarnings}
						earnings={annualUSDEarnings}
					/>
				</dl>
			</div>
		</div>
	)
}

type EarningCardItemProps = {
	title: string
	coin: string
	tokens: number
	earnings: number
	light?: boolean
}

const EarningsCardItem = ({ title, coin, tokens, earnings, light }: EarningCardItemProps) => {
	if (light)
		return (
			<p className="flex flex-row justify-between border-gray-900/10 py-3 text-sm">
				<span className="w-1/3 leading-normal text-gray-800">{title}</span>
				<span className="font-medium tracking-tight text-gray-900">
					{getNumLocaleString(tokens, 2)} {coin}
				</span>
				<span className="flex items-center space-x-2 leading-normal text-gray-800">
					${getNumLocaleString(earnings, 2)}
				</span>
			</p>
		)

	return (
		<div className={clsx('flex flex-col justify-center gap-y-2 border-gray-900/10')}>
			<dt className="leading-normal text-gray-800">{title}</dt>
			<dd className="text-2xl font-medium tracking-tight text-gray-900">
				{getNumLocaleString(tokens, 2)} {coin}
			</dd>
			<dt className="flex items-center space-x-2 leading-normal text-gray-800">
				${getNumLocaleString(earnings, 2)}
			</dt>
		</div>
	)
}
