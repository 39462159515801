import { SupportedProjects } from 'constants/index'

export const streamr = [
	{ range: '1-4', price: '6.99' },
	{ range: '5-10', price: '5.99' },
	{ range: '10+', price: '4.99' },
	{ range: '20+', price: '2.99' },
	{ range: '100+', price: '2.50' }
] as const

export const gnosis = [
	{ range: '1-3', price: '1.00' },
	{ range: '5-10', price: '0.90' },
	{ range: '11-20', price: '0.80' },
	{ range: '21-30', price: '0.70' }
] as const

// TODO: revisit
export const ethereum = [
	{ range: '1-3', price: '1.00' },
	{ range: '5-10', price: '0.90' },
	{ range: '11-20', price: '0.80' },
	{ range: '21-30', price: '0.70' }
] as const

export const prices = {
	gnosis,
	streamr,
	ethereum
} as const

export const getIndexForCount = (project: SupportedProjects, count: number) => {
	if (project == 'streamr') {
		if (count > 100) return 4
		if (count > 20) return 3
		if (count > 10) return 2
		if (count >= 5) return 1
		return 0
	}
	if (project == 'gnosis') {
		if (count > 20) return 3
		if (count > 10) return 2
		if (count >= 5) return 1
		return 0
	}
	return 0
}
