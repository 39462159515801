import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type OnboardingState = {
	wallets: string[]
	project?: string
}

const initialState:  OnboardingState = { wallets: [] }

const onboardingSlice = createSlice({
	name: 'onboarding',
	initialState,
	reducers: {
		setWallets: (state, action: PayloadAction<string[]>) => {
			return {
				...state,
				wallets: action.payload
			}
		},
		clearWallets: (state) => {
			return { ...state, wallets: [] }
		}
	}
})

const { reducer, actions } = onboardingSlice

export const { setWallets, clearWallets } = actions

export default reducer
