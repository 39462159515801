import clsx from 'clsx'
import { Spinner } from 'components/animations/spinner'
import { useEffect, useState } from 'react'

import StreamrLogo from 'assets/logos/svg/streamr'
import GnosisLogo from 'assets/logos/svg/gnosis'
import EthLogo from 'assets/logos/svg/ethereum'
import nodeService from 'services/node-service'
import thirdPartyService from 'services/third-party'

import { DEFAULT_CURRENCY } from 'utils/currency'

interface SupportedNetworksCardProps {
	activeTab?: string
}

enum Tabs {
	USDT = 'USDT',
	BTC = 'BTC'
}

const logos = {
	Streamr: StreamrLogo
}

const TABLE_HEADERS = ['#', 'Project', 'Price', 'APY', 'TVL', 'Nodes Online']

async function addPriceFromCoinGecko(project: Project): Promise<Project> {
	let name = project.project_name
	let price = await thirdPartyService
		.getCoinPrice(name.toUpperCase())
		.then(data => data[name.toLowerCase()][DEFAULT_CURRENCY])

	project.price = price
	return project
}

export const renderLogo = (name: string) => {
	switch(name) {
		case 'streamr':
			return <StreamrLogo className="mr-2 h-5 w-5 text-[#F65F0A]" />
		case 'gnosis':
			return <GnosisLogo className="mr-2 h-5 w-5 text-[#F65F0A]" />
		case 'ethereum':
			return <EthLogo className="mr-2 h-5 w-5 text-[#F65F0A]" />
		default:
			return null
	}
}

export const SupportedNetworksCard = ({ activeTab }: SupportedNetworksCardProps) => {
	const [isLoading, setLoading] = useState(true)
	const [projects, setProjects] = useState<Project[]>([])

	const numberFormat = new Intl.NumberFormat("en-US")

	useEffect(() => {
		nodeService
			.getProjects()
			.then(({ data }) => {
				return Promise.all(data.map(addPriceFromCoinGecko))
			})
			.then(setProjects)
			.catch(error => {
				console.log(error)
			})
			.finally(() => {
				setLoading(false)
			})
	}, [])

	return (
		<div className="rounded-lg border p-6 pt-8">
			<h2 className="text-base lg:text-xl font-medium">Supported Networks</h2>
			<div className='mt-6 overflow-x-scroll'>
				{isLoading ? (
					<div className="mt-4 flex items-center justify-center">
						<Spinner className="h-8 w-8" />
					</div>
				) : (
					<table className="min-w-full divide-y divide-gray-300 text-right">
						<thead>
							<tr>
								{TABLE_HEADERS.map((thead, index) => (
									<th
										key={thead}
										scope="col"
										className={clsx(
											'whitespace-nowrap py-3.5 pl-4 pr-3 font-normal text-light-gray',
											'[&:nth-child(2)]:text-left'
										)}>
										{thead}
									</th>
								))}
							</tr>
						</thead>
						<tbody className="divide-y divide-gray-200 bg-white ">
							{projects.map((project, index) => (
								<tr key={'projects_' + index}>
									<td className="whitespace-nowrap py-3.5 pl-4 pr-3 text-gray-900 sm:pl-0">
										{index + 1}
									</td>
									<td className="whitespace-nowrap py-3.5 pl-4 pr-3 [&:nth-child(2)]:text-left font-medium text-gray-900">
										<div className="flex justify-center md:justify-start items-center">
											{renderLogo(project.project_name)}
											<p className="hidden md:block text-gray-900">{project.project_name}</p>
										</div>
									</td>
									<td className="whitespace-nowrap py-3.5 pl-4 pr-3 text-gray-900">
										${project.price}
									</td>
									<td className="whitespace-nowrap py-3.5 pl-4 pr-3 text-gray-500">
										{project.apy}%
									</td>
									<td className="whitespace-nowrap py-3.5 pl-4 pr-3 text-gray-500">
										{numberFormat.format(project.tvl)} {project.project_name == 'Streamr' ?	'DATA' : ''	}
									</td>
									<td className="whitespace-nowrap py-3.5 pl-4 pr-3 text-gray-500">
										{project.nodes_online}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				)}
			</div>
		</div>
	)
}

type TabProps = {
	active: boolean
	title?: string
	className?: string
	onClick?: () => void
}
const Tab = ({ active, title, onClick, className }: TabProps) => {
	return (
		<button
			type="button"
			onClick={onClick}
			className={clsx(
				'relative inline-flex items-center bg-white px-3 py-2 font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10',
				{
					'text-purple-700': active
				},
				className
			)}>
			{title}
		</button>
	)
}
