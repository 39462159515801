const tutorials = [
	{
		title: 'Buying Streamr DATA on Binance 💰',
		url: 'https://www.youtube.com/embed/dGxtzHTj-dY'
	},
	{
		title: 'Creating a Web3 Wallet for DATA 🤩',
		url: 'https://www.youtube.com/embed/LDgs_UZzYug'
	},
	{
		title: 'Deploying Nodes in One Click ⚡️',
		url: 'https://www.youtube.com/embed/4mvnRZVs2zY'
	},
	{
		title: 'Activate Your FREE Streamr Nodes! 😎',
		url: 'https://www.youtube.com/embed/GCXCTeneOHI'
	}
]

type VideoTutorialProps = {
	index: number
	className?: string
}

const VideoTutorial = ({ index, className = '' }: VideoTutorialProps) => {
	let tutorial = tutorials[index] || tutorials.at(-1)

	return (
		<iframe
			key={tutorial.title}
			className={'rounded-md aspect-video w-full ' + className}
			src={tutorial.url}
			title={tutorial.title}
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
			allowFullScreen
		></iframe>
	)
}

export { VideoTutorial }
