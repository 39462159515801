import { EthLikeProject } from 'constants/index'
import axios from 'services/axios'

export type Validator = {
	activation_eligibility_epoch: number
	activation_epoch: number
	apy: number
	balance: number
	chain: 'gnosis'
	daily_income: number
	id: string
	index: number
	monthly_income: number | null
	project_name: EthLikeProject
	public_key: string
	slashed: boolean
	status: 'pending' | 'active_online'
	wallet_address: string
	weekly_income: number
}

type ValidatorCredentialsPayload = {
	projectName: EthLikeProject
	validatorCredentials: string
	address: string
}

type ValidatorAPI = {
	project_name: EthLikeProject
	validator_credentials: string
	address: string
}

export const sendValidatorCredentials = ({
	projectName,
	validatorCredentials,
	address
}: ValidatorCredentialsPayload) => {
	let payload: { validator: ValidatorAPI } = {
		validator: {
			project_name: projectName,
			validator_credentials: validatorCredentials,
			address
		}
	}
	return axios.post('/validators', payload).then(res => res.data)
}

type BatchValidatorPayload = {
	validators: ValidatorCredentialsPayload[]
}

export const sendBatchValidatorCredentials = ({ validators }: BatchValidatorPayload) => {
	let payload: { validators: ValidatorAPI[] } = {
		validators: validators.map(v => ({
			project_name: v.projectName,
			validator_credentials: v.validatorCredentials,
			address: v.address
		}))
	}

	return axios.post('/validators_batch', payload).then(res => res.data)
}

export const getValidators = () => {
	return axios.get('/validators').then(res => {
		return res.data
	})
}

export default {
	sendValidatorCredentials,
	getValidators
}
