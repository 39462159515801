import { PlusIcon } from '@heroicons/react/20/solid'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { Link, useSearchParams } from 'react-router-dom'

import { Button, ButtonOutline } from 'components/app/button'
import { AppHeader } from 'components/app/header'
import { AppLayout } from 'components/app/layout'
import { SidebarLayout } from 'components/app/sidebar-layout'
import { EarningsCalculator } from 'components/dashboard/earnings-calculator-card'
import { Stats } from 'components/dashboard/rewards-value-card'
import { SupportedNetworksCard } from 'components/dashboard/supported-networks-card'
import { useState } from 'react'
import { CongratsModal } from 'components/onboarding/congrats-modal'

export const Dashboard = () => {
	const [projectName, _setProjectName] = useState('streamr')
	const [query] = useSearchParams()

	let fromOnboarding = Boolean(query.get('from_onboarding'))
	const [showModal, setShowModal] = useState(fromOnboarding)

	const closeOnboardingModal = () => {
		// flag set in onboading/billing-success
		window.localStorage.removeItem('onboarding_node_deployment')
		setShowModal(false)
	}

	return (
		<AppLayout renderBanner={false} renderHeader={false}>
			<SidebarLayout>
				<div className="hidden md:block">
					<AppHeader auth={true}>
						<div className="flex items-center gap-6">
							<Link to={'/nodes/create'}>
								<Button className="inline-flex w-40 items-center">
									<PlusIcon className="mr-2 h-6 w-6" />
									Create node
								</Button>
							</Link>
							<Link to={'/faqs'}>
								<ButtonOutline className="flex w-40 items-center">
									<ExclamationCircleIcon className="mr-2 h-6 w-6" />
									How to use?
								</ButtonOutline>
							</Link>
							{/*<Link
								to={'/onboarding/choose-project'}
								className="ml-6 rounded-lg border border-transparent bg-[#6340DE] py-3 px-4 text-sm font-medium text-white shadow-sm hover:bg-[#5630de] focus:outline-none focus:ring-2 focus:ring-[#6d4de2] focus:ring-offset-2"
							>
								To Onboarding
							</Link>
							*/}
						</div>
					</AppHeader>
				</div>
				<div className="py-6">
					<div className="px-4 sm:px-6 md:px-8">
						<div className="space-y-6 lg:space-y-10 py-4">
							<Stats projectName={projectName} />
							<EarningsCalculator />
							<SupportedNetworksCard activeTab={'USDT'} />
						</div>
					</div>
				</div>
			</SidebarLayout>
			{showModal && (
				<CongratsModal onClose={closeOnboardingModal} />
			)}
		</AppLayout>
	)
}
