import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

import { AppLayout } from 'components/app/layout'

const faqs = [
	{
		question: 'What are the minimum and maximum staking requirements for running a Streamr node?',
		answer:
			'The minimum stake required for running a Streamr node is 0 DATA tokens per node, while the maximum stake is 20,000 DATA tokens per node.'
	},
	{
		question: 'Where can I acquire DATA tokens?',
		answer:
			'DATA tokens can be obtained from various centralized and decentralized exchanges. Binance is the most popular exchange to purchase DATA tokens, and it has the highest daily liquidity.'
	},
	{
		question: 'How do I know how much DATA I will earn?',
		answer: 'You can use the zonaris community Staking Calculator to estimate your earnings.'
	},
	{
		question: 'Which chain is staking available on at the moment?',
		answer:
			'Staking is currently available on the Polygon Chain only. Users must transfer DATA between Polygon wallets/accounts and store them in a Polygon wallet.'
	},
	{
		question: 'How do I add the DATA token to my wallet?',
		answer:
			'To add the DATA token to your wallet, you need to paste the following address for the DATA token: 0x3a9A81d576d83FF21f26f325066054540720fC34 (Polygonscan link). Once you have added the DATA token to MetaMask or another Web3 wallet, make sure you transfer only on the Polygon chain.'
	},
	{
		question: 'Do I need to create separate wallets for multiple nodes?',
		answer:
			' It is recommended to create a separate MetaMask account for each node if you are setting up multiple nodes. For example, if you want to set up 10 nodes, you need 10 wallet addresses/accounts. Each node needs one beneficiary address, where rewards will be distributed monthly.'
	},
	{
		question: 'How do I set up a MetaMask account for each node?',
		answer:
			'You can create a new MetaMask account in one-click by selecting “Create Account” on the top right icon in MetaMask. Import the token using the same Polygon Contract for DATA, which is: 0x3a9A81d576d83FF21f26f325066054540720fC34. It is recommended to rename each account to “zonaris Node 1”, “zonaris Node 2”, etc., to keep track of them or call them the node name provided by Streamr, which is visible in the dashboard.'
	},
	{
		question: 'How can I set up nodes on zonaris?',
		answer: 'To set up nodes on zonaris, you need to follow these steps:',
		steps: [
			'Step 1: Create the correct number of accounts in one MetaMask wallet and label each account by number (e.g. zonaris 1, zonaris 2, etc.).',
			'Step 2: Set up an account with zonaris and create nodes in just one click. You can deploy up to 20 wallet addresses at a time by pasting the public wallet address into the designated boxes and start earning rewards within minutes.'
		]
	},
	{
		question: 'What should I do after deploying a new validator node?',
		answer:
			'After deploying a new validator node, it is not required but recommended that you copy and paste or write down your API key, node name, and node URL, and store it in a safe place. It is recommended to rename your MetaMask accounts to the 3-word name that has been generated to keep track of which node is for which MetaMask account.'
	},
	{
		question: 'Can I add new nodes?',
		answer:
			' Yes, you can add new nodes anytime. Most users deploy new nodes every month with their monthly earnings.'
	}
]

export const FAQs = () => {
	return (
		<AppLayout title="FAQs">
			<div className="bg-white border-t mt-4">
				<div className="mx-auto max-w-7xl px-6 py-20 sm:py-28 lg:py-36 lg:px-8">
					<div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
						<h2 className="text-2xl font-semibold leading-10 tracking-tight text-gray-900">
							Frequently asked questions
						</h2>
						<dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
							{faqs.map((faq, outerIndex) => (
								<Disclosure
									as="div"
									key={faq.question}
									className="pt-6"
									defaultOpen={outerIndex === 0}>
									{({ open }) => (
										<>
											<dt>
												<Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
													<span className="text-base font-medium leading-7">{faq.question}</span>
													<span className="ml-6 flex h-7 items-center">
														{open ? (
															<MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
														) : (
															<PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
														)}
													</span>
												</Disclosure.Button>
											</dt>
											<Disclosure.Panel as="dd" className="mt-2 pr-12">
												<p className="text-base leading-7 text-gray-600">{faq.answer}</p>
												{(faq.steps ?? []).map((step, innerIndex) => (
													<p
														className="text-base leading-7 text-gray-600"
														key={outerIndex + '-' + innerIndex}>
														{step}
													</p>
												))}
											</Disclosure.Panel>
										</>
									)}
								</Disclosure>
							))}
						</dl>
					</div>
				</div>
			</div>
		</AppLayout>
	)
}
