import clsx from 'clsx'
import { SupportedProjects } from 'constants/index'
import { useState } from 'react'
import { getIndexForCount, prices } from 'utils/prices'

type NodePricesProps = {
	count: number
	className?: string
	project?: SupportedProjects
}

export const NodePrices = ({ project, count, className }: NodePricesProps) => {
	const [expanded, setExpanded] = useState(false)
	let nodePrices = prices.streamr

	let highlightedIndex = getIndexForCount(project || 'streamr', count)
	let costPerNode = Number(nodePrices[highlightedIndex].price)
	className = clsx(className, 'p-4 bg-white border rounded-xl')

	const renderNodePrices = () => {
		if (project == 'gnosis') {
			return (
				<tr
					key="gnosis-node"
					className={clsx('relative bg-gray-50 font-medium text-black text-black')}
				>
					<td className={'whitespace-nowrap py-3 px-4 text-sm'}>1 validator</td>
					<td className="whitespace-nowrap py-3 px-4 text-right text-sm font-medium">{'€' + 1}</td>
				</tr>
			)
		}

		if (!expanded) {
			let np = nodePrices[highlightedIndex]
			return (
				<tr
					key={np.range}
					className={clsx('relative bg-gray-50 font-medium text-black text-black')}
				>
					<td className={'whitespace-nowrap py-3 px-4 text-sm'}>{np.range}</td>
					<td className="whitespace-nowrap py-3 px-4 text-right text-sm font-medium">
						{'€' + np.price}
					</td>
				</tr>
			)
		}

		return nodePrices.map((np, index) => (
			<tr
				key={np.range}
				className={clsx(
					'relative font-medium',
					{
						'bg-gray-50 text-black': index === highlightedIndex
					},
					index == highlightedIndex ? 'text-black' : 'text-light-gray'
				)}
			>
				<td className={'whitespace-nowrap py-3 px-4 text-sm'}>{np.range}</td>
				<td
					className={clsx(
						'whitespace-nowrap py-3 px-4 text-right text-sm',
						index == highlightedIndex ? 'font-medium' : 'font-normal'
					)}
				>
					{'€' + np.price}
				</td>
			</tr>
		))
	}

	return (
		<div className={className}>
			<div className="mx-4 flex items-center justify-between"></div>
			<div className="-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
					<table className="min-w-full divide-y divide-gray-300">
						<thead>
							<tr>
								<th scope="col" className="py-3 px-4 text-left text-sm font-medium">
									<h3 className="font-medium">Node Prices</h3>
								</th>
								<th scope="col" className="py-3 px-4 text-right text-sm font-normal text-gray-500">
									{project != 'gnosis' && (
										<button
											onClick={() => setExpanded(!expanded)}
											type="button"
											className="text-purple-main"
										>
											All prices
										</button>
									)}
								</th>
							</tr>
						</thead>
						<tbody className="divide-y divide-gray-200">{renderNodePrices()}</tbody>
						{project != 'gnosis' && (
							<tfoot>
								<tr>
									<td className="whitespace-nowrap py-3 px-4 text-sm font-medium text-gray-900">
										Total nodes: {count}
									</td>
									<td className="whitespace-nowrap py-3 px-4 text-right text-sm font-medium text-gray-900">
										{'€' + (count * costPerNode).toFixed(2)}
									</td>
								</tr>
							</tfoot>
						)}
					</table>
				</div>
			</div>
		</div>
	)
}
