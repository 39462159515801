import { Dialog, Transition } from '@headlessui/react'
import {
	ExclamationCircleIcon,
	PlusIcon,
	XMarkIcon,
	DocumentChartBarIcon
} from '@heroicons/react/24/outline'

import clsx from 'clsx'
import { Fragment, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'

import { AppHeader } from './header'

import SvgBillingIcon from 'assets/icons/svg/billing'
import SvgDashboardIcon from 'assets/icons/svg/dashboard'
import SvgNodesIcon from 'assets/icons/svg/nodes'
import DiscordLogo from 'assets/logos/png/discord.png'
import { Button, ButtonOutline } from './button'

interface SidebarLayoutProps {
	children: React.ReactNode
	docTitle?: boolean
	title?: string
}

type NavItem = {
	name: string
	href: string
	icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
	disabled?: boolean
}
const JoinDiscordLink = () => (
	<a
		className="flex flex-row items-center justify-start rounded-lg border border-white px-4 py-3"
		href="https://discord.gg/tBQXAEYs6b"
		target={'_blank'}
		rel="noreferrer"
	>
		<img src={DiscordLogo} className="mr-4 h-8 w-8" />
		<p className="text-sm font-medium">Join Discord</p>
	</a>
)

export const SidebarLayout: React.FC<SidebarLayoutProps> = ({ children }) => {
	const navigation: NavItem[] = [
		{ name: 'Dashboard', href: '/dashboard', icon: SvgDashboardIcon },
		{ name: 'Nodes', href: '/nodes', icon: SvgNodesIcon },
		{ name: 'Billing', href: '/billing', icon: SvgBillingIcon },
		{ name: 'Rewards', href: '/reports', icon: DocumentChartBarIcon }
	]

	const [sidebarOpen, setSidebarOpen] = useState(false)

	return (
		<div>
			<Transition.Root show={sidebarOpen} as={Fragment}>
				<Dialog as="div" className="relative z-40 md:hidden" onClose={() => setSidebarOpen(false)}>
					<Transition.Child
						as={Fragment}
						enter="transition-opacity ease-linear duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="transition-opacity ease-linear duration-300"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
					</Transition.Child>

					<div className="fixed inset-0 z-40 flex">
						<Transition.Child
							as={Fragment}
							enter="transition ease-in-out duration-300 transform"
							enterFrom="-translate-y-full"
							enterTo="translate-y-0"
							leave="transition ease-in-out duration-300 transform"
							leaveFrom="translate-y-0"
							leaveTo="-translate-y-full"
						>
							<Dialog.Panel className="relative flex w-full flex-1 flex-col bg-white">
								<Transition.Child
									as={Fragment}
									enter="ease-in-out duration-300"
									enterFrom="opacity-0"
									enterTo="opacity-100"
									leave="ease-in-out duration-300"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<div className="absolute top-3 left-0">
										<button
											type="button"
											className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
											onClick={() => setSidebarOpen(false)}
										>
											<span className="sr-only">Close sidebar</span>
											<XMarkIcon className="h-6 w-6" aria-hidden="true" />
										</button>
									</div>
								</Transition.Child>
								<div className="flex-1 overflow-y-auto">
									<div className="flex items-center justify-center py-5">
										<Link to={'/dashboard'}>
											<img className="h-6 w-auto" src="/logo-long-beta.png" alt="zonaris" />
										</Link>
									</div>
									<nav className="space-y-4 border-t px-8 pt-5">
										{navigation.map(item => (
											<NavLink
												key={item.name}
												to={item.href}
												className={({ isActive }) =>
													clsx(
														isActive ? 'bg-gray-100' : ' hover:bg-gray-100',
														'group flex items-center rounded-md px-2 py-2',
														item.disabled ? 'pointer-events-none text-slate-500' : 'text-slate-900'
													)
												}
											>
												{({ isActive }) => (
													<>
														<item.icon
															className={clsx(
																isActive ? '' : 'text-slate-900',
																'mr-4 h-8 w-8 flex-shrink-0',
																item.disabled
																	? 'pointer-events-none text-slate-500'
																	: 'text-slate-900'
															)}
															aria-hidden="true"
														/>
														{item.name}
													</>
												)}
											</NavLink>
										))}
									</nav>
								</div>
								<div>
									<nav className="mt-5 flex flex-col space-y-4 px-2 pb-6">
										<Link to="/nodes/create">
											<Button className="inline-flex w-full items-center">
												<PlusIcon className="mr-2 h-6 w-6" />
												Create node
											</Button>
										</Link>
										<Link to={'/faqs'}>
											<ButtonOutline className="flex w-full items-center">
												<ExclamationCircleIcon className="mr-2 h-6 w-6" />
												How to use?
											</ButtonOutline>
										</Link>
									</nav>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>

			{/* Static sidebar for desktop */}
			<div
				style={{
					background:
						'linear-gradient(183.23deg, #FFFFFF 33.05%, #EDFAFF 71.49%, #F0E9FF 86.7%, #C7D5FA 100%), #FFFFFF'
				}}
				className="relative hidden border border-r bg-white p-2 md:fixed md:inset-y-0 md:flex md:w-72 md:flex-col "
			>
				{/* Sidebar component, swap this element with another sidebar if you like */}
				<div className="flex min-h-0 flex-1 flex-col ">
					<div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
						<div className="flex flex-shrink-0 items-center px-4">
							<Link to={'/dashboard'}>
								<img className="h-10 w-auto" src="/logo-long-beta.png" alt="zonaris" />
							</Link>
						</div>
						<nav className="mt-5 flex-1 space-y-3 px-2">
							{navigation.map(item => (
								<NavLink
									key={item.name}
									to={item.href}
									className={({ isActive }) =>
										clsx(
											isActive ? 'bg-gray-100' : 'hover:bg-gray-100',
											'group flex items-center rounded-md px-2 py-2',
											item.disabled ? 'pointer-events-none text-slate-500' : 'text-slate-900'
										)
									}
								>
									{({ isActive }) => (
										<>
											<item.icon
												className={clsx(
													isActive ? '' : 'text-slate-900',
													'mr-4 h-8 w-8 flex-shrink-0',
													item.disabled ? 'pointer-events-none text-slate-500' : 'text-slate-900'
												)}
												aria-hidden="true"
											/>
											{item.name}
										</>
									)}
								</NavLink>
							))}
						</nav>
					</div>
					<div>
						<nav className="mt-5 flex-1 space-y-3 px-2 pb-6">
							<JoinDiscordLink />
						</nav>
					</div>
				</div>
			</div>
			<div className="flex flex-1 flex-col md:pl-72">
				<div className="sticky top-0 z-10 flex items-center justify-between border-b bg-white py-4 pl-1 sm:pl-3 sm:pt-3 md:hidden">
					<button
						type="button"
						className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
						onClick={() => setSidebarOpen(true)}
					>
						<span className="sr-only">Open sidebar</span>
						<img className="h-6" src="/menu_hamburger.svg" aria-hidden="true" />
					</button>
					<img className="h-6" src="/logo-long-beta.png" alt="zonaris" />
					<AppHeader auth={true} />
				</div>
				<main>{children}</main>
			</div>
		</div>
	)
}
