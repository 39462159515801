import { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { useAppDispatch } from 'hooks'
import { toast } from 'react-hot-toast'

import { Spinner } from 'components/animations/spinner'
import { Alert } from 'components/app/alert'
import BillingService from 'services/billing-service'
import NodeService from 'services/node-service'
import { refreshUser } from 'slices/auth'
import { parseErrorResponse } from 'services/axios'

export const BillingSuccessful = () => {
	const dispatch = useAppDispatch()
	const [isLoading, setIsLoading] = useState(true)
	const [successMessage, setSuccessMessage] = useState('Your billing information was saved.')

	let [searchParams] = useSearchParams()
	const setupIntent = searchParams.get('setup_intent')
	const wallets = searchParams.get('wallets')
	const project = searchParams.get('project')

	let withNodeDeployment = wallets && project
	let returnTo = withNodeDeployment ? '/nodes' : '/dashboard'

	useEffect(() => {
		async function updateCustomer() {
			if (!setupIntent) return
		  await BillingService.updateCustomer(setupIntent as string)
		  await dispatch(refreshUser()).unwrap()
		}

		async function deployNodes() {
			let triedBefore = window.localStorage.getItem('node_deployment')
			if (!wallets || !project || triedBefore) return
			let addresses = wallets.split(',')

			// prevents duplicate node deployments
			window.localStorage.setItem('node_deployment', 'triggered')

			NodeService
				.deployNode(project, addresses)
				.then(() => {
					setSuccessMessage('Your billing information was saved and your nodes are being deployed.')
				})
				.catch(error => {
					let errorMessage = parseErrorResponse(error)
					toast.error(errorMessage)
				})
		}

		updateCustomer()
		.then(deployNodes)
		.finally(() => setIsLoading(false))

		return () => {
			window.localStorage.removeItem('node_deployment')
		}
	}, [])

	return (
		<div className="mt-16 flex justify-center">
			<div className="text-center">
				<div className="mt-6">
					{isLoading ? (
						<Spinner className="h-8 w-8" />
					) : (
						<>
							<Alert message={successMessage} />
							<Link
								className="mt-8 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
								to={returnTo}>
								Return to previours page
							</Link>
						</>
					)}
				</div>
			</div>
		</div>
	)
}
